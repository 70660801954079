import { useState } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { notifyError } from "../../Utils/ReusableFunctions";

export const NewInfoBoardModal = ({ setInfoBoardList }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [addInfoboard, setAddInfoboard] = useState({
    name: "",
    settingsJson: '{"backgroundColor":"black","isRoundedComponents":true}',
  });

  const handleNameChange = (event) => {
    setAddInfoboard({ ...addInfoboard, name: event.target.value });
  };

  const handleSaveChanges = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(addInfoboard),
    };
    fetch(ApiUrls.infobard, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add InfoBoard");
        }
        console.log(response)
        fetchData(
          "https://infoboard.decentra.net/odata/board/",
          sessionToken
        ).then((data) => {
          setInfoBoardList(data.value);
          setAddInfoboard({ ...addInfoboard, name: "" });
        });
      })
      .catch((error) => {
        notifyError("Error adding InfoBoard:", error);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (addInfoboard.name.length >= 3) {
        const closeButton = document.querySelector("#newInfoBoard #closeThis");
        closeButton.click();
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="newInfoBoard"
      tabIndex="-1"
      aria-labelledby="newInfoBoard"
      aria-hidden="true"
      data-bs-backdrop="static"
  
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 " style={{color:"#858585"}}  id="newInfoBoard">
              New InfoBoard
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <input
              name="infoboardname"
              type="text"
              className="form-control"
              placeholder="InfoBoard Name"
              value={addInfoboard.name}
              onChange={handleNameChange}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              id="closeThis"
              type="button"
              className="btn btn-primary rounded-0"
              data-bs-dismiss="modal"
              onClick={handleSaveChanges}
              disabled={addInfoboard.name.length < 3}
            >
              Add new infoboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

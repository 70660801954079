import { useState, useRef, useEffect } from "react";
import { fetchData, notifyError } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import custom from "../../Assets/Capture2.PNG";

export const CustomTemplateModal = ({ setInfoBoardList }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { setSelectedComponents } = useSettingsInfoBoard();
  const [addInfoboard, setAddInfoboard] = useState({
    name: "",
    settingsJson: '{"backgroundColor":"#1F1E3C","isRoundedComponents":false}',
  });

  const [components, setComponents] = useState([
    {
      name: "Clock",
      settingsJson: JSON.stringify({
        type: "ClockComponent",
        color: "#151432",
        xPosition: 0,
        yPosition: 0,
        width: 3,
        height: 3,
        opacity: 100,
        textColor: "#C9E1ED",
        clockContainerBackgroundColor: "#151432",
        clockBackgroundColor: "#151432",
        clockTextColor: "#C9E1ED",
        clockRadius: 10,
        timeZones: [
          {
            value: "Europe/Brussels",
            label: "Brussels, Copenhagen, Madrid, Paris",
          },
        ],
        cityNameText: 12,
        cityTimeText: 15,
        zIndex: 0
      }),
    },
    {
      name: "Date",
      settingsJson: JSON.stringify({
        type: "DateComponent",
        color: "#151432",
        xPosition: 21,
        yPosition: 0,
        width: 3,
        height: 3,
        opacity: 100,
        textColor: "#C9E1ED",
        textSize: 15,
        dateFormat: "dddd, MMMM Do YYYY",
        zIndex: 0
      }),
    },
    {
      name: "WebBrowser",
      settingsJson: JSON.stringify({
        type: "WebBrowserComponent",
        xPosition: 0,
        yPosition: 3,
        width: 6,
        height: 18,
        urlList: [],
        zIndex: 0
      }),
    },
    {
      name: "Topbar",
      settingsJson: JSON.stringify({
        type: "TopbarComponent",
        color: "#151432",
        xPosition: 3,
        yPosition: 0,
        width: 18,
        height: 3,
        opacity: 100,
        textColor: "#C9E1ED",
        textSize: 30,
        info: "",
        zIndex: 0
      }),
    },
    {
      name: "Infobar",
      settingsJson: JSON.stringify({
        type: "InfobarComponent",
        color: "#151432",
        xPosition: 0,
        yPosition: 21,
        width: 24,
        height: 3,
        opacity: 100,
        textColor: "#C9E1ED",
        textSize: 20,
        infoList: [
        ],
        messageDelay: 5000,
        zIndex: 0
      }),
    },
  ]);

  const handleNameChange = (event) => {
    setAddInfoboard({ ...addInfoboard, name: event.target.value });
  };

  const handleSaveChanges = () => {
    if (addInfoboard.name.trim().length < 3) {
      notifyError("InfoBoard name must be at least 3 characters long");
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(addInfoboard),
    };

    fetch(ApiUrls.infobard, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add InfoBoard");
        }
        return response.json();
      })
      .then((data) => {
        const infoBoardId = data.ID;
        saveComponents(infoBoardId);
        return fetchData(ApiUrls.infobard, sessionToken);
      })
      .then((data) => {
        setInfoBoardList(data.value);
        setAddInfoboard({ ...addInfoboard, name: "" });
      })
      .catch((error) => {
        notifyError("Error adding InfoBoard:", error.message);
      });
  };

  const saveComponents = (infoBoardId) => {
    const requestOptions = (component) => ({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({ ...component, boardID: infoBoardId }),
    });

    const componentPromises = components.map((component) => {
       return fetch(ApiUrls.component, requestOptions(component))
      }
    );

    Promise.all(componentPromises)
      .then((responses) => {
        responses.forEach((response) => {
          if (!response.ok) {
            throw new Error("Failed to add one or more components");
          }
        });
        return fetchData(ApiUrls.component, sessionToken);
      })
      .then((data) => {
        const filteredComponents = data.value.filter(
          (component) => String(component.BoardID) === String(infoBoardId)
        );
        setSelectedComponents(
          filteredComponents.map((component) => ({
            id: component.ID,
            type: JSON.parse(component.SettingsJson).type,
            settings: component.SettingsJson,
          }))
        );
        setComponents(
          components.map((component) => ({
            ...component,
            name: "",
            boardID: "",
            settingsJson: "",
          }))
        );
      })
      .catch((error) => {
        notifyError("Error adding components:", error.message);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector("#CustomTemplate #newCustom");
      closeButton.click();
    }
  };

  const inputRef = useRef(null);
  
  useEffect(() => {
    const modalElement = document.getElementById("CustomTemplate");
    const handleModalShown = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };
    modalElement.addEventListener("shown.bs.modal", handleModalShown);
    return () => {
      modalElement.removeEventListener("shown.bs.modal", handleModalShown);
    };
  }, []);

  return (
    <div
      className="modal fade"
      id="CustomTemplate"
      tabIndex="-1"
      aria-labelledby="CustomTemplate"
      aria-hidden="true"
      data-bs-backdrop="static"
      onKeyDown={handleKeyDown}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title fs-5" style={{color:"#858585"}} id="CustomTemplate">
              Custom Template
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <input
               ref={inputRef}
              name="infoboardname"
              type="text"
              className="form-control"
              placeholder="InfoBoard Name"
              value={addInfoboard.name}
              onChange={handleNameChange}
            />
            <div className="mt-3 align-content-start">
              <h5 className="text-start">Components in Infoboard</h5>
              <div className="d-flex flex-wrap">
                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Date
                </div>

                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Clock
                </div>

                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Web Browser
                </div>

                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Information bar
                </div>

                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Information list bar
                </div>
              </div>
              <h5 className="text-start mt-2">Preview of Infoboard</h5>
              <img src={custom} alt="customTemplateImage" height={375} />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger rounded-0"
              data-bs-dismiss="modal"
            >
              CLOSE
            </button>
            <button
              type="button"
              id="newCustom"
              className="btn btn-primary rounded-0"
              data-bs-dismiss="modal"
              onClick={handleSaveChanges}
              disabled={addInfoboard.name.length < 3}
            >
              Add custom template
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import Draggable from "react-draggable";
import { SketchPicker } from "react-color";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";

export const CalendarModal = ({
  id,
  deleteData,
  componentInfo,
  patchSettingsToBackend2,
  calendarData,
  componentName,
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventName, setEventName] = useState("");
  const [eventSubject, setEventSubject] = useState("");
  const [eventTime, setEventTime] = useState("");
  const [events, setEvents] = useState([]);
  const [isDraggable, setIsDraggable] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const nodeRef = useRef(null);
  const [settings, setSettings] = useState({});
  const [selectedEventType, setSelectedEventType] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedEventIndex, setEditedEventIndex] = useState(null);
  const [editedEventSubject, setEditedEventSubject] = useState("");

  const [timeouts, setTimeouts] = useState({
    slidingText: null,
    textSize: null,
    height: null,
    width: null,
    xPosition: null,
    yPosition: null,
    opacity: null,
    info: null,
    zIndex: null,
    title: null,
    eventTitleTextSize: null,
  });

  useEffect(() => {
    if (componentInfo) {
      setSettings({
        color: componentInfo.color,
        textColor: componentInfo.textColor,
        opacity: componentInfo.opacity,
        width: componentInfo.width,
        height: componentInfo.height,
        textSize: componentInfo.textSize,
        xPosition: componentInfo.xPosition,
        yPosition: componentInfo.yPosition,
        zIndex: componentInfo.zIndex,
        title: componentInfo.title,
        dateFormat:componentInfo.dateFormat,
        eventTitleTextSize: componentInfo.eventTitleTextSize,
      });
    }
  }, [componentInfo]);

  const updateTimeout = (key, value) => {
    setTimeouts((prevTimeouts) => ({
      ...prevTimeouts,
      [key]: value,
    }));
  };

  useEffect(() => {
    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [timeouts]);

  const handleOnBackgroundChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, color: newColor });
    patchSettingsToBackend2(
      {
        color: newColor,
      },
      calendarData.SettingsJson,
      id
    );
  };

  const handleOnTextColorChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, textColor: newColor });
    patchSettingsToBackend2(
      {
        textColor: newColor,
      },
      calendarData.SettingsJson,
      id
    );
  };

  const Date_Click_Fun = (date) => {
    setSelectedDate(date);
  };

  const Event_Data_Update = (event) => {
    setEventName(event.target.value);
  };

  const Event_Subject_Update = (event) => {
    setEventSubject(event.target.value);
  };

  const Delete_Event_Fun = (index) => {
    const updatedList = [...componentInfo.events];
    updatedList.splice(index, 1);
    setEvents(updatedList);
    patchSettingsToBackend2(
      {
        events: updatedList,
      },
      calendarData.SettingsJson,
      id
    );
  };

  const handleSettingChange = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    clearTimeout(timeouts[key]);
    updateTimeout(
      key,
      setTimeout(() => {
        patchSettingsToBackend2(
          { [key]: value },
          calendarData.SettingsJson,
          id
        );
      }, 500)
    );
  };

  const handleDateFormatChange = (e) => {
    const newFormat = e.target.value;
    handleSettingChange("dateFormat", newFormat);
  };


  const handleInputChange = (key, value, maxLimit) => {
    let parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      parsedValue = 2;
    } else if (parsedValue > maxLimit) {
      parsedValue = maxLimit;
    }
    handleSettingChange(key, parsedValue);
  };

  const Create_Event_Fun = () => {
    if (selectedDate && eventName) {
      const newEvent = [
        ...(componentInfo.events || []),
        {
          date: selectedDate,
          time: eventTime,
          title: eventName,
          subject: eventSubject,
          color: selectedEventType,
        },
      ];
      patchSettingsToBackend2(
        {
          events: newEvent,
        },
        calendarData.SettingsJson,
        id
      );
      setEvents([...events, newEvent]);
      setSelectedDate(null);
      setEventName("");
      setEventTime("");
      setSelectedEventType("");
      setEventSubject("");
    }
  };

  const handleSelectChange = (eventColor) => {
    setSelectedEventType(eventColor);
  };

  const Event_Time_Update = (event) => {
    setEventTime(event.target.value);
  };

  const startEditing = (index, subject) => {
    setIsEditing(true);
    setEditedEventIndex(index);
    setEditedEventSubject(subject);
  };

  const handleEditSubjectChange = (e) => {
    setEditedEventSubject(e.target.value);
  };

  const saveEditedSubject = () => {
    const updatedEvents = [...componentInfo.events];
    updatedEvents[editedEventIndex].subject = editedEventSubject;

    patchSettingsToBackend2(
      {
        events: updatedEvents,
      },
      calendarData.SettingsJson,
      id
    );

    setEvents(updatedEvents);
    setIsEditing(false);
    setEditedEventIndex(null);
    setEditedEventSubject("");
  };

  return (
    <Draggable nodeRef={nodeRef} disabled={!isDraggable}>
      <div
        className="modal"
        id={`calendarModal${id}`}
        tabIndex="-1"
        aria-labelledby={`calendarModal${id}`}
        aria-hidden="true"
        data-bs-backdrop="static"
        ref={nodeRef}
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h4 className="modal-title fs-5" id={`calendarModal${id}`}>
                Properties for {componentName}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              onMouseEnter={() => setIsDraggable(false)}
              onMouseLeave={() => setIsDraggable(true)}
            >
              <ol className="list-group list-group-flush text-start">
                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className=" me-auto">Opacity</div>
                  <span className="me-2">{settings.opacity}%</span>
                  <span>
                    <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="100"
                      step="25"
                      id="dateRange3"
                      value={settings.opacity || 0}
                      onChange={(e) =>
                        handleSettingChange("opacity", parseInt(e.target.value))
                      }
                    />
                  </span>
                </li>

                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="row w-100">
                    <div className="col input-group">
                      <span
                        className="input-group-text border-end-0"
                        id="widthDate"
                      >
                        width
                      </span>
                      <input
                        name="width"
                        type="number"
                        max={24 - settings.xPosition || 0}
                        className="form-control"
                        aria-label="width"
                        aria-describedby="width"
                        onChange={(e) =>
                          handleInputChange(
                            "width",
                            e.target.value,
                            24 - settings.xPosition
                          )
                        }
                        value={settings.width || ""}
                      />
                    </div>
                    <div className="col input-group">
                      <span className="input-group-text" id="heightDate">
                        height
                      </span>
                      <input
                        name="height"
                        type="number"
                        min={2}
                        className="form-control"
                        max={24 - settings.yPosition || 0}
                        aria-label="height"
                        aria-describedby="height"
                        onChange={(e) =>
                          handleInputChange(
                            "height",
                            e.target.value,
                            24 - settings.yPosition
                          )
                        }
                        value={settings.height || ""}
                      />
                    </div>
                  </div>
                </li>

                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="row w-100">
                    <div className="col input-group">
                      <span className="input-group-text" id="X">
                        X
                      </span>
                      <input
                        name="X"
                        min={0}
                        max={24 - settings.width || 0}
                        type="number"
                        className="form-control"
                        aria-label="X"
                        aria-describedby="X"
                        onChange={(e) =>
                          handleInputChange(
                            "xPosition",
                            e.target.value,
                            24 - settings.width
                          )
                        }
                        value={settings.xPosition || 0}
                      />
                    </div>
                    <div className="col input-group">
                      <span className="input-group-text border-end-0" id="Y">
                        Y
                      </span>
                      <input
                        name="Y"
                        type="number"
                        className="form-control"
                        aria-label="Y"
                        min={0}
                        max={24 - settings.height || 0}
                        aria-describedby="Y"
                        onChange={(e) =>
                          handleInputChange(
                            "yPosition",
                            e.target.value,
                            24 - settings.height
                          )
                        }
                        value={settings.yPosition || 0}
                      />
                    </div>
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className=" pt-1 pe-1 me-auto">Title:</div>
                  <input
                    name="title"
                    type="text"
                    className="form-control w-100 me-4"
                    value={settings.title || ""}
                    onChange={(e) =>
                      handleSettingChange("title", e.target.value)
                    }
                  />
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className=" pt-1 me-auto">Event title text size</div>
                  <input
                    name="textsizedate"
                    type="number"
                    className="form-control w-25 me-4"
                    value={settings.eventTitleTextSize || ""}
                    onChange={(e) =>
                      handleSettingChange(
                        "eventTitleTextSize",
                        parseInt(e.target.value)
                      )
                    }
                  />
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className=" pt-1 me-auto">Text size</div>
                  <input
                    name="textsizedate"
                    type="number"
                    className="form-control w-25 me-4"
                    value={settings.textSize || ""}
                    onChange={(e) =>
                      handleSettingChange("textSize", parseInt(e.target.value))
                    }
                  />
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className=" pt-1 me-auto">zIndex</div>
                  <input
                    name="textsizedate"
                    type="number"
                    className="form-control w-25 me-4"
                    value={settings.zIndex || 0}
                    onChange={(e) =>
                      handleSettingChange("zIndex", parseInt(e.target.value))
                    }
                  />
                </li>
                <li className="list-group-item">
                    <div className="me-auto">Date Format</div>
                    <select
                      id={`dateselector${id}`}
                      className="form-select"
                      value={settings.dateFormat}
                      onChange={handleDateFormatChange}
                    >
                      <option value="dddd, MMMM Do YYYY">
                        dddd, MMMM Do YYYY
                      </option>
                      <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                      <option value="MM-DD-YYYY">MM-DD-YYYY</option>
                      <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                      <option value="Do MMMM YYYY">Do MMMM YYYY</option>
                      <option value="MMMM Do, YYYY">MMMM Do, YYYY</option>
                    </select>
                  </li>
              </ol>

              <div className="accordion accordion-flush" id="accordionCalendar">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseCalendar"
                      aria-expanded="true"
                      aria-controls="collapseCalendar"
                    >
                      Background Color
                    </button>
                  </h2>
                  <div
                    id="collapseCalendar"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionCalendar"
                  >
                    <div className="accordion-body">
                      <SketchPicker
                        color={settings.color}
                        onChangeComplete={handleOnBackgroundChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourCalendar"
                      aria-expanded="true"
                      aria-controls="collapseFourCalendar"
                    >
                      Text Color
                    </button>
                  </h2>
                  <div
                    id="collapseFourCalendar"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionCalendar"
                  >
                    <div className="accordion-body">
                      <SketchPicker
                        color={settings.textColor}
                        onChangeComplete={handleOnTextColorChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneCalendar"
                      aria-expanded="true"
                      aria-controls="collapseOneCalendar"
                    >
                      Calendar
                    </button>
                  </h2>
                  <div
                    id="collapseOneCalendar"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionCalendar"
                  >
                    <div className="accordion-body">
                      <h5 className="text-start">
                        Select date and create event
                      </h5>
                      <div className="d-flex justify-content-center">
                        <Calendar
                          minDate={new Date()}
                          value={selectedDate}
                          onClickDay={Date_Click_Fun}
                          tileClassName={({ date }) =>
                            selectedDate &&
                            date.toDateString() === selectedDate.toDateString()
                              ? "selected"
                              : componentInfo.events?.some(
                                  (event) =>
                                    new Date(event.date).toDateString() ===
                                    date.toDateString()
                                )
                              ? "event-marked"
                              : ""
                          }
                        />
                      </div>
                      <div className="event-container">
                        {selectedDate && (
                          <div>
                            <div className="text-start mt-1 ">
                              Selected Date: {selectedDate?.toDateString()}
                            </div>
                            <div>
                              <div
                                className="mt-1 text-start"
                                htmlFor="eventTime"
                              >
                                Event Time
                              </div>
                              <input
                                id="eventTime"
                                type="time"
                                className="form-control w-100 mt-1 mb-1"
                                value={eventTime || ""}
                                onChange={Event_Time_Update}
                              />
                            </div>
                            <div className="input-group mb-1">
                              <input
                                name="newMessage"
                                type="text"
                                className="form-control w-100"
                                placeholder="Event title"
                                value={eventName}
                                onChange={Event_Data_Update}
                                aria-label="Recipient's username"
                              />
                            </div>
                            <div className="input-group">
                              <textarea
                                style={{
                                  height: "55px",
                                }}
                                className="form-control"
                                placeholder="Event subject"
                                id="floatingTextarea"
                                value={eventSubject}
                                onChange={Event_Subject_Update}
                              ></textarea>
                            </div>
                            <div className="input-group d-flex mt-2 mb-1 justify-content-center align-items-center">
                              <span className="me-4">Event color:</span>
                              <input
                                type="radio"
                                className="btn-check"
                                name="optionsColor"
                                id="option1Color"
                                autoComplete="off"
                                value="PdfViewer"
                                onClick={() => handleSelectChange("red")}
                              />
                              <label
                                style={{
                                  width: "20%",

                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                className={`btn m-1 rounded-0 ${
                                  selectedEventType === "red"
                                    ? "border border-dark"
                                    : ""
                                }`}
                                htmlFor="option1Color"
                              >
                                Red
                              </label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="optionsColor"
                                id="optionColor2"
                                autoComplete="off"
                                value="VideoViewer"
                                onClick={() => handleSelectChange("green")}
                              />
                              <label
                                style={{
                                  width: "20%",
                                  backgroundColor: "green",
                                  color: "white",
                                }}
                                className={`btn m-1 rounded-0 ${
                                  selectedEventType === "green"
                                    ? "border border-dark"
                                    : ""
                                }`}
                                htmlFor="optionColor2"
                              >
                                green
                              </label>
                              <input
                                type="radio"
                                className="btn-check"
                                name="optionsColor"
                                id="option3Color"
                                autoComplete="off"
                                value="Weather"
                                onClick={() => handleSelectChange("yellow")}
                              />
                              <label
                                style={{
                                  width: "25%",
                                  backgroundColor: "yellow",
                                  color: "black",
                                }}
                                className={`btn text-center m-1 rounded-0 ${
                                  selectedEventType === "yellow"
                                    ? "border border-dark"
                                    : ""
                                }`}
                                htmlFor="option3Color"
                              >
                                Yellow
                              </label>
                            </div>
                            <button
                              className="input-group-text btn btn-success bg-success btn-sm rounded-0 w-100 mt-1"
                              disabled={
                                eventName.length <= 3 ||
                                eventSubject.length <= 5 ||
                                !selectedEventType
                              }
                              onClick={Create_Event_Fun}
                            >
                              ADD
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiveCalendar"
                      aria-expanded="true"
                      aria-controls="collapseFiveCalendar"
                    >
                      All calendar events
                    </button>
                  </h2>
                  <div
                    id="collapseFiveCalendar"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionCalendar"
                  >
                    <div className="accordion-body">
                      <ol className="list-group list-group-flush text-start">
                        {componentInfo.events?.map((event, index) => (
                          <li key={index} className="list-group-item">
                            <div className="d-flex w-100 justify-content-between">
                              <small>
                                {new Date(event.date).toDateString()}
                              </small>
                              <span>
                                <span
                                  className="badge rounded-0 btn-sm text-white btn btn-danger me-1"
                                  onClick={() => Delete_Event_Fun(index)}
                                >
                                  <i className="bi bi-trash3 "></i>
                                </span>

                                <span
                                  className="badge rounded-0 btn-sm btn-secondary text-black btn ms-1"
                                  onClick={() =>
                                    startEditing(index, event.subject)
                                  }
                                >
                                  <i className="bi bi-pen "></i>
                                </span>
                              </span>
                            </div>
                            <div className="fw-bolder">{event.title}</div>
                            {isEditing && editedEventIndex === index ? (
                              <>
                                <input
                                  type="text"
                                  value={editedEventSubject}
                                  onChange={handleEditSubjectChange}
                                  className="form-control w-100"
                                />
                                <button
                                  className="btn btn-success btn-sm mt-1 w-50 rounded-0"
                                  onClick={saveEditedSubject}
                                >
                                  Save
                                </button>
                                <button
                                  className="btn btn-danger btn-sm mt-1 w-50 rounded-0"
                                  onClick={() => setIsEditing(false)}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <small>{event.subject}</small>
                              </>
                            )}
                          </li>
                        ))}
                        {componentInfo.events?.length < 1 && (
                          <div>NO EVENTS</div>
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              {deleteConfirmation ? (
                <>
                  <div>Are you sure ?</div>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-0 "
                    onClick={deleteData}
                    data-bs-dismiss="modal"
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm rounded-0"
                    onClick={() => setDeleteConfirmation(false)}
                  >
                    NO
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-0 "
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    {" "}
                    REMOVE COMPONENT{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm rounded-0"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

import "react-toastify/dist/ReactToastify.css";
import { DashboardsList } from "./DashboardsList";
import { DeviceList } from "../Devices/DeviceList";
import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { notifyError } from "../../Utils/ReusableFunctions";
import { MediaList } from "../Media/MediaList";
import { useNavigate } from "react-router-dom";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { CustomTemplateModalTwo } from "../CustomTemplates/CustomTemplateModalTwo";
import { AddCustomTemplates } from "../CustomTemplates/AddCustomTemplates";
import "./ComponentContainer.css";
import logo from "../../Assets/logotrans.png";
import { MediaLogModal } from "../Media/MediaLogModal";
import { jwtDecode } from "jwt-decode";
//import { LanguageContext } from "../../Context/LanguageContext";

export const ComponentContainer = () => {
  const sessionToken = localStorage.getItem("bearerToken");

  const [isLoading, setIsLoading] = useState(true);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();
  const { customTemplate, setJwtToken,  infoBoardList,setInfoBoardList, } = useSettingsInfoBoard();
  //const { language,languageData, setLanguage } = useContext(LanguageContext);
  
  //const handleToggleLanguage = () => {
   // const newLanguage = language === 'en' ? 'dk' : 'en';
    //setLanguage(newLanguage);
  //};

  //<h1>{languageData.title}</h1>
  //<p>{languageData.text}</p>
  //<button onClick={handleToggleLanguage}>{language}</button>

  useEffect(() => {
    setIsLoading(true);
    fetchData(ApiUrls.infobard, sessionToken)
      .then((data) => {
        setInfoBoardList(data.value);
      })
      .catch((error) => {
        notifyError("Error fetching infoboard list:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [sessionToken, setInfoBoardList]);

  useEffect(() => {
    if (sessionToken) {
      const decodedToken = jwtDecode(sessionToken);
      setJwtToken(decodedToken);
    }
  }, [sessionToken, setJwtToken]);

  const LogOut = () => {
    localStorage.clear();
    navigate("/");
  };

  

  return (
    <>
      <div className="menuContainer">
        <div className="overlay"></div>
        <div className="pt-4 container ">
          <div className="d-flex justify-content-between align-items-center mb-3 w-100">
            <img className="ms-5" src={logo} alt="logo" height={75} />
            <h3 className="fw-bolder mx-auto text-white p-4">Dashboard Menu</h3>
            <div
              style={{ width: "225px" }}
              className="d-flex justify-content-end"
            >
              <button
                className="btn shadow-0 border rounded-0 fw-bolder hover btn-light me-5"
                onClick={LogOut}
              >
                LOG OUT
              </button>
  
            </div>
          </div>
          <div className="row">
            <div className="col">
              <DashboardsList
                infoBoardList={infoBoardList}
                setInfoBoardList={setInfoBoardList}
                isLoading={isLoading}
              />
            </div>
            <div className="col">
              <DeviceList />
            </div>
            <div className="col">
              <h5 className="text-start text-white fw-bold">
                Custom Templates
              </h5>
              <ol className="list-group list-group-numbered rounded-0 w-100 bg-transparent">
                <AddCustomTemplates setInfoBoardList={setInfoBoardList} />
                {customTemplate &&
                  customTemplate?.componentSettings?.map((component, index) => (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-start list-group-item-action  text-black"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#CustomTemplate2"
                      key={index}
                      onClick={() => setSelectedTemplate(component)}
                    >
                      <div className="ms-2 me-auto fw-bolder">
                        {component.templateName}
                      </div>
                    </li>
                  ))}
              </ol>
            </div>

            <div className="col-4">
              <div className="d-flex justify-content-between">
              <h5 className="text-start fw-bold text-white">Media List</h5>
              <small>
                <button className="btn text-white bg-black p-1  rounded-0" data-bs-toggle="modal" data-bs-target="#mediaLogModal" >
                Media log list
                </button>
              </small>
              </div>
              <MediaList componentType="" />
            </div>
          </div>
          <div>
            <img className="mt-5" src={logo} alt="logo" height={300} />
          </div>
        </div>
      </div>
      
      <CustomTemplateModalTwo
        selectedTemplate={selectedTemplate}
        setInfoBoardList={setInfoBoardList}
      />
      <MediaLogModal/>
    </>
  );
};

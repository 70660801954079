import { useEffect, useState } from "react";
import { ApiUrls } from "../../Utils/API";
import { fetchData } from "../../Utils/ReusableFunctions";
import { DeleteDeviceModal } from "../ModalComponents/DeleteDeviceModal";
import { notifyError } from "../../Utils/ReusableFunctions";
import { DeviceInfoModal } from "./DeviceInfoModal";

export const DeviceList = ({ infoBoardList }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [isLoading, setIsLoading] = useState(true);
  const [deviceList, setDeviceList] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  useEffect(() => {
    setIsLoading(true);
    fetchData(ApiUrls.deviceList, sessionToken)
      .then((data) => {
        setDeviceList(data.value);
      })
      .catch((error) => {
        //console.error("Error fetching infoboard list:", error);
        notifyError("Error fetching infoboard list:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [sessionToken]);

  const openDeleteModal = (device) => {
    setSelectedDevice(device);
    setDeleteConfirmation("");
  };

  const handleDeleteInfoBoard = () => {
    if (!selectedDevice) return;
    fetch(`${ApiUrls.deviceList}/${selectedDevice.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete device");
        }
        fetchData(ApiUrls.deviceList, sessionToken).then((data) => {
          setDeviceList(data.value);
        });
      })
      .catch((error) => {
        notifyError("Failed to delete device", error);
      });
  };

  return (
    <>
      {isLoading ? (
        <>Loading...</>
      ) : deviceList.length === 0 ? (
        <div className="text-center">
          <p className="lead">NO DEVICES</p>
        </div>
      ) : (
        <div className="bg-transparent">
          <h5 className="text-start fw-bold text-white"> Devices </h5>

          <ol className="list-group list-group-numbered rounded-0 w-100 bg-transparent">
            {deviceList.map((item, index) => (
              <li
                className="list-group-item d-flex justify-content-between align-items-start list-group-item-action text-black "
                key={index}
              >
                <div
                  className="ms-2 text-start w-100"
                  style={{ cursor: "pointer" }}
                  data-bs-toggle="modal"
                  data-bs-target="#deviceInfo"
                  onClick={() => setSelectedDevice(item)}
                >
                  <div
                    className="fw-bold text-truncate me-1"
                    style={{ width: "95%" }}
                  >
                    {item.Name}
                  </div>
                </div>

                <span
                  className="badge bg-danger rounded-0"
                  data-bs-toggle="modal"
                  data-bs-target="#removeDevice"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openDeleteModal({ id: item.ID, name: item.Name })
                  }
                >
                  <i className="bi bi-trash3" style={{ fontSize: "14px" }}></i>
                </span>
              </li>
            ))}
          </ol>
          <DeleteDeviceModal
            selectedDevice={selectedDevice}
            deleteConfirmation={deleteConfirmation}
            setDeleteConfirmation={setDeleteConfirmation}
            handleDeleteInfoBoard={handleDeleteInfoBoard}
          />
          <DeviceInfoModal
            selectedDevice={selectedDevice}
            infoBoardList={infoBoardList}
            setSelectedDevice={setSelectedDevice}
            setDeviceList={setDeviceList}
          />
        </div>
      )}
    </>
  );
};

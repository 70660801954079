import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ComponentContainer } from "./ComponentContainer";
import { AdminLogin } from "../Admin/AdminLogin";
import { Dashboard } from "../Dashboard";
import PrivateRoute from "./PrivateRoute";


export const RouteComponent = () => {

  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Routes>
            <Route path="/" element={<AdminLogin />} />
            <Route element={<PrivateRoute />}>
              <Route path="/dashboards/*" element={<ComponentContainer />} />
            </Route>
            <Route
                path="/dashboard/:id/:selectedComponentId?/:guid?"
                element={<Dashboard />}
              />
          </Routes>
        </Router>
      </header>
    </div>
  );
};

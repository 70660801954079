import { useEffect, useState, useRef } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { DragAndDrop } from "../DragAndDrop";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { notifyError } from "../../Utils/ReusableFunctions";
import "./MediaList.css";

export const MediaList = ({ id, setList, list, componentType, isTrue }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { mediaList, setMediaList, componentMedia, setComponentMedia } =
    useSettingsInfoBoard();
  const [errorItems, setErrorItems] = useState({});
  const [deleteErrorItems, setDeleteErrorItems] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editedMediaIndex, setEditedEventIndex] = useState(null);
  const [editedMediaSubject, setEditedEventSubject] = useState("");
  const inputRef = useRef(null);
  


  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    if (sessionToken) {
      fetchData(`${ApiUrls.media}`, sessionToken)
        .then((data) => {
          setMediaList(data.value);
        })
        .catch((error) => {
          notifyError("Failed to fetch data", error);
        });
    }
  }, [sessionToken, setMediaList]);

  useEffect(() => {
    if (sessionToken) {
      fetchData(`${ApiUrls.componentMedia}`, sessionToken)
        .then((data) => {
          setComponentMedia(data.value);
        })
        .catch((error) => {
          notifyError("Failed to fetch data", error);
        });
    }
  }, [sessionToken, setComponentMedia]);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDropFiles = async (acceptedFiles) => {
    const newMediaItems = await Promise.all(
      acceptedFiles.map(async (file) => ({
        Name: file.name,
        Type: file.type,
        Base64: await fileToBase64(file),
      }))
    );
    handleAddMedia(newMediaItems);
  };

  const handleAddMedia = (newMediaItems) => {
    newMediaItems.forEach((newMediaItem) => {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(newMediaItem),
      };

      fetch(ApiUrls.media, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to add media: ${response.statusText}`);
          }
          return response.json();
        })
        .then((data) => {
          return fetchData(ApiUrls.media, sessionToken);
        })
        .then((data) => {
          setMediaList(data.value);
        })
        .catch((error) => {
          notifyError("Error adding media to media list:", error);
        });
    });
  };

  const handleDeleteMedia = (mediaIdToDelete) => {
    if (list?.some((item) => item.MediaID === mediaIdToDelete)) {
      setDeleteErrorItems((prevErrorItems) => ({
        ...prevErrorItems,
        [mediaIdToDelete]: true,
      }));
      return;
    }
    if (componentMedia?.some((item) => item.MediaID === mediaIdToDelete)) {
      setDeleteErrorItems((prevErrorItems) => ({
        ...prevErrorItems,
        [mediaIdToDelete]: true,
      }));
      return;
    }

    fetch(`${ApiUrls.media}/${mediaIdToDelete}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete media");
        }
        return fetchData(ApiUrls.media, sessionToken);
      })
      .then((data) => {
        setMediaList(data.value);
      })
      .catch((error) => {
        notifyError("Error deleting media", error);
      });
  };

  const getNextSortOrder = () => {
    const existingImages = list || [];
    const nextSortOrder = existingImages.length + 1;
    return nextSortOrder;
  };

  const addMediaToComponent = (newMediaID) => {

    if (isTrue && list.length > 0) {
      notifyError("Only one item can be added to the component.");
      return;
    }
    if (list?.some((item) => item.MediaID === newMediaID)) {
      setErrorItems((prevErrorItems) => ({
        ...prevErrorItems,
        [newMediaID]: true,
      }));
      return;
    }
    const mediaItem = mediaList.find((item) => item.ID === newMediaID);
    if (
      componentType === "application/pdf" &&
      mediaItem.Type !== "application/pdf"
    ) {
      return;
    }
    if (componentType === "video/mp4" && !mediaItem.Type.startsWith("video/")) {
      return;
    }

    const nextSortOrder = getNextSortOrder();
    const addMedia = {
      ComponentID: id,
      mediaID: newMediaID,
      SortOrder: nextSortOrder,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(addMedia),
    };

    fetch(ApiUrls.componentMedia, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add Media to component");
        }
        return fetchData(
          `${ApiUrls.component}(${id})?$expand=media`,
          sessionToken
        );
      })
      .then((data) => {
        const mediaList = data.Media || data.media;
        const currentDate = new Date();
        const filteredMediaList = mediaList.filter((mediaItem) => {
          if (mediaItem.Expiry) {
            const expiryDate = new Date(mediaItem.Expiry);
            return expiryDate > currentDate;
          }

          return true;
        });
        setList(filteredMediaList);
        fetchData(`${ApiUrls.componentMedia}`, sessionToken)
          .then((data) => {
            setComponentMedia(data.value);
          })
          .catch((error) => {
            notifyError("Failed to fetch data", error);
          });
      })
      .catch((error) => {
        notifyError("Error adding media to component:", error);
      });
  };

  const accept = {
    "image/png": [".png"],
    "image/jpeg": [".jpeg", ".jpg"],
    "text/html": [".html", ".htm"],
    "application/pdf": [".pdf", ".PDF"],
    "video/mp4": [".mp4", ".MP4"],
  };

  const startEditing = (index, subject) => {
    setIsEditing(true);
    setEditedEventIndex(index);
    setEditedEventSubject(subject);
  };

  const handleEditSubjectChange = (e) => {
    setEditedEventSubject(e.target.value);
  };

  const handleSaveChanges = (id) => {
    const patchUrl = `${ApiUrls.media}(${id})`;
    const patchData = {
      Name: editedMediaSubject,
    };
    fetch(patchUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(patchData),
    })
      .then((response) => {
        if (response.ok) {
          fetchData(ApiUrls.media, sessionToken).then((data) => {
            setMediaList(data.value);
          });
          setIsEditing(false);
          setEditedEventIndex(null);
          setEditedEventSubject("");
        } else {
          notifyError("Error updating media list");
        }
      })
      .catch((error) => {
        notifyError("Error updating media list", error);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector(
        "#mediaContainer #changeNameFile"
      );
      closeButton.click();
    }
  };


  return (
    <div className="text-start" id="mediaContainer" onKeyDown={handleKeyDown}>
      <ol
        className="list-group list-group-numbered rounded-0 scroll"
        style={{
          overflowY: mediaList.length >= 9 ? "scroll" : "",
          height: mediaList.length >= 9 ? "450px" : "",
        }}
      >
        {mediaList &&
          mediaList
            .filter(
              (item) =>
                componentType === "" ||
                item.Type.toLowerCase().startsWith(componentType)
            )
            .map((item, index) => (
              <li
                key={index}
                className="list-group-item d-flex justify-content-between align-items-start"
              >
                <div
                  className="ms-2 me-auto fw-bold"
                  style={{ maxWidth: isEditing ? "100%" : "70%" }}
                >
                  {isEditing && editedMediaIndex === index ? (
                    <div className="w-100">
                      <input
                           ref={inputRef} 
                        id="changingMedia"
                        type="text"
                        value={editedMediaSubject}
                        onChange={handleEditSubjectChange}
                        style={{ width: "100%" }}
                   
                      />
                      <button
                        className="btn btn-success btn-sm mt-1 w-50 rounded-0"
                        id="changeNameFile"
                        onClick={() => handleSaveChanges(item.ID)}
                      >
                        Save changes
                      </button>
                      <button
                        className="btn btn-danger btn-sm mt-1 w-50 rounded-0"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="text-truncate fw-light" >
                     
                          <span className="fw-light">
                            {item.Type === "application/pdf" && (
                              <>
                                <i
                                  className="bi bi-filetype-pdf"
                                  style={{ fontSize: "20px" }}
                                ></i>{" "}
                              </>
                            )}
                            {item.Type === "image/png" && (
                              <>
                                <i
                                  className="bi bi-filetype-png"
                                  style={{ fontSize: "20px" }}
                                ></i>{" "}
                              </>
                            )}
                            {item.Type === "image/jpeg" && (
                              <>
                                <i
                                  className="bi bi-filetype-jpg"
                                  style={{ fontSize: "20px" }}
                                ></i>{" "}
                              </>
                            )}
                            {item.Type === "video/mp4" && (
                              <>
                                <i
                                  className="bi bi-filetype-mp4"
                                  style={{ fontSize: "20px" }}
                                ></i>{" "}
                              </>
                            )}
                          </span>
                   
                        {item.Name}
                      </div>
                    </>
                  )}

                  {errorItems[item.ID] && (
                    <div>
                      <small className="text-danger fw-light">
                        Media already exists in the list -{" "}
                        <span
                          className="badge text-bg-danger p-1 rounded-0 text-white "
                          style={{ padding: 2, cursor: "pointer" }}
                          onClick={() => setErrorItems({})}
                        >
                          X
                        </span>
                      </small>
                    </div>
                  )}
                  {deleteErrorItems[item.ID] && (
                    <div>
                      <small className="text-danger fw-light">
                        Cannot delete media, it's referenced by an item in the
                        list -{" "}
                        <span
                          className="badge text-bg-danger p-1 rounded-0 text-white "
                          style={{ padding: 2, cursor: "pointer" }}
                          onClick={() => setDeleteErrorItems({})}
                        >
                          X
                        </span>
                      </small>
                    </div>
                  )}
                </div>
                {!isEditing && (
                  <>
                    <span
                      className="badge btn btn-danger p-1 rounded-0 text-white mt-2"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteMedia(item.ID)}
                    >
                      <i
                        className="bi bi-trash3"
                        style={{ fontSize: "14px" }}
                      ></i>
                    </span>
                    {!componentType && (
                      <span
                        className="badge rounded-0 btn-sm btn-secondary text-black p-1 mt-2 btn ms-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => startEditing(index, item.Name)}
                      >
                        <i
                          className="bi bi-pen"
                          style={{ fontSize: "14px" }}
                        ></i>
                      </span>
                    )}
                  </>
                )}

                {componentType && (
                  <span
                    className="badge text-bg-info p-1 rounded-0 text-white mt-2 ms-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => addMediaToComponent(item.ID)}
                  >
                    <i className="bi bi-plus" style={{ fontSize: "14px" }}></i>
                  </span>
                )}
                <div></div>
              </li>
            ))}
        {!mediaList && <div>No media available</div>}
      </ol>
      <div className="mt-2">
        <DragAndDrop onDropFiles={handleDropFiles} accept={accept} />
      </div>
    </div>
  );
};

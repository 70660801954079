 const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];


  export const formatDate = (date, format) => {
  
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    const dddd = days[date.getDay()];
    const MMMM = months[m];
    const DD = d < 10 ? "0" + d : d;
    const MM = m < 9 ? "0" + (m + 1) : m + 1;
  
    switch (format) {
      case "dddd, MMMM Do YYYY":
        return `${dddd}, ${MMMM} ${DD} ${y}`;
      case "MM/DD/YYYY":
        return `${MM}/${DD}/${y}`;
        case "MM-DD-YYYY":
          return `${MM}-${DD}-${y}`;
      case "YYYY-MM-DD":
        return `${y}-${MM}-${DD}`;
      case "Do MMMM YYYY":
        return `${DD} ${MMMM} ${y}`;
      case "MMMM Do, YYYY":
        return `${MMMM} ${DD}, ${y}`;
      default:
        return `${dddd}, ${MMMM} ${DD} ${y}`;
    }
  };




  export const menuFormatDate = (date, format) => {
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    const dddd = days[date.getDay()];
    const MMMM = months[m];
    const DD = d < 10 ? "0" + d : d;
    const MM = m < 9 ? "0" + (m + 1) : m + 1;

    switch (format) {
      case "dddd, MMMM Do YYYY":
        return `${dddd}, ${MMMM} ${DD} ${y}`;
      case "MM/DD/YYYY":
        return `${MM}/${DD}/${y}`;
        case "MM-DD-YYYY":
          return `${MM}-${DD}-${y}`;
      case "YYYY-MM-DD":
        return `${y}-${MM}-${DD}`;
      case "Do MMMM YYYY":
        return `${DD} ${MMMM} ${y}`;
      case "MMMM Do, YYYY":
        return `${MMMM} ${DD}, ${y}`;
        case "menuItem":
          return `${DD}-${MM}`;
      default:
        return ` ${dddd.slice(0,3)} ${DD} ${MMMM} ${y}`;
    }
  }


export  const isTodayOrFuture = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const eventDate = new Date(date);
    eventDate.setHours(0, 0, 0, 0);
    return eventDate >= today;
  };

 export const isToday = (date) => {
    const today = new Date();
    const givenDate = new Date(date);
    return (
      today.getFullYear() === givenDate.getFullYear() &&
      today.getMonth() === givenDate.getMonth() &&
      today.getDate() === givenDate.getDate()
    );
  };
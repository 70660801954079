import { useState, useRef, useEffect } from "react";
import { fetchData, notifyError } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import custom from "../../Assets/capture6.PNG";

export const CustomTemplateModalThree = ({ setInfoBoardList }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { setSelectedComponents } = useSettingsInfoBoard();
  const [addInfoboard, setAddInfoboard] = useState({
    name: "",
    settingsJson: '{"backgroundColor":"#2D404A","isRoundedComponents":false}',
  });

  const [components, setComponents] = useState([
    {
      name: "Clock",
      settingsJson: JSON.stringify({
        type: "ClockComponent",
        color: "#223843",
        xPosition: 20,
        yPosition: 3,
        width: 4,
        height: 3,
        opacity: 100,
        clockBackgroundColor: "#223843",
        clockTextColor: "#B75B48",
        clockRadius: 10,
        timeZones: [
          {
            value: "Europe/Brussels",
            label: "Brussels, Copenhagen, Madrid, Paris",
          },
        ],
        cityNameText: 0,
        cityTimeText: 30,
        zIndex: 0,
      }),
    },
    {
      name: "Date",
      settingsJson: JSON.stringify({
        type: "DateComponent",
        color: "#223843",
        xPosition: 19,
        yPosition: 0,
        width: 5,
        height: 3,
        opacity: 100,
        textColor: "#B75B48",
        textSize: 25,
        dateFormat: "dddd, MMMM Do YYYY",
        zIndex: 0,
      }),
    },
    {
      name: "Topbar",
      settingsJson: JSON.stringify({
        type: "TopbarComponent",
        color: "#223843",
        xPosition: 0,
        yPosition: 0,
        width: 19,
        height: 3,
        opacity: 100,
        textColor: "#B75B48",
        textSize: 30,
        info: "",
        zIndex: 0,
      }),
    },
    {
      name: "Weather",
      settingsJson: JSON.stringify({
        type: "WeatherComponent",
        color: "#223843",
        xPosition: 20,
        yPosition: 6,
        width: 4,
        height: 8,
        opacity: 100,
        weatherTextSize: 12,
        weatherTextColor: "#B75B48",
        cityTextSize: 10,
        dataTextSize: 5,
        dataSize: 7,
        weatherCity: "Copenhagen",
        zIndex: 0,
      }),
    },
    {
      name: "Calendar",
      settingsJson: JSON.stringify({
        type: "CalendarComponent",
        color: "#223843",
        xPosition: 20,
        yPosition: 14,
        width: 4,
        height: 7,
        opacity: 100,
        textColor: "#B75B48",
        textSize: 5,
        events: [],
        zIndex: 0,
        title: "Event Calendar",
        eventTitleTextSize: 7,
      }),
    },
    {
      name: "WebBrowser",
      settingsJson: JSON.stringify({
        type: "WebBrowserComponent",
        color: "#223843",
        xPosition: 8,
        yPosition: 3,
        width: 12,
        height: 18,
        imageSpeed: 5000,
        urlList: [],
        zIndex: 0,
      }),
    },
    {
      name: "SlidingInfo",
      settingsJson: JSON.stringify({
        type: "SlidingInfoComponent",
        color: "#223843",
        xPosition: 0,
        yPosition: 21,
        width: 24,
        height: 3,
        opacity: 100,
        textColor: "#B75B48",
        textSize: 30,
        animationDuration: 100,
        slidingText: "",
        zIndex: 0,
      }),
    },
    {
      name: "PdfViewer",
      settingsJson: JSON.stringify({
        type: "PdfViewerComponent",
        xPosition: 0,
        yPosition: 3,
        width: 8,
        height: 18,
        opacity: 100,
        textColor: "#B75B48",
        backgroundColor: "#223843",
        pdfPageSpeed: 5000,
        errorText: 5,
        showList: false,
        currentPdfIndex: 0,
        pdfListText: 5,
        positionRight: true,
        zIndex: 0,
      }),
    },
  ]);

  const handleNameChange = (event) => {
    setAddInfoboard({ ...addInfoboard, name: event.target.value });
  };

  const handleSaveChanges = () => {
    if (addInfoboard.name.trim().length < 3) {
      notifyError("InfoBoard name must be at least 3 characters long");
      return;
    }
    console.log(addInfoboard);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(addInfoboard),
    };

    fetch(ApiUrls.infobard, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add InfoBoard");
        }
        return response.json();
      })
      .then((data) => {
        const infoBoardId = data.ID;
        saveComponents(infoBoardId);
        return fetchData(ApiUrls.infobard, sessionToken);
      })
      .then((data) => {
        setInfoBoardList(data.value);
        setAddInfoboard({ ...addInfoboard, name: "" });
      })
      .catch((error) => {
        notifyError("Error adding InfoBoard:", error.message);
      });
  };

  const saveComponents = (infoBoardId) => {
    const requestOptions = (component) => ({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({ ...component, boardID: infoBoardId }),
    });

    const componentPromises = components.map((component) => {
      return fetch(ApiUrls.component, requestOptions(component));
    });

    Promise.all(componentPromises)
      .then((responses) => {
        responses.forEach((response) => {
          if (!response.ok) {
            throw new Error("Failed to add one or more components");
          }
        });
        return fetchData(ApiUrls.component, sessionToken);
      })
      .then((data) => {
        const filteredComponents = data.value.filter(
          (component) => String(component.BoardID) === String(infoBoardId)
        );
        setSelectedComponents(
          filteredComponents.map((component) => ({
            id: component.ID,
            type: JSON.parse(component.SettingsJson).type,
            settings: component.SettingsJson,
          }))
        );
        setComponents(
          components.map((component) => ({
            ...component,
            name: "",
            boardID: "",
            settingsJson: "",
          }))
        );
      })
      .catch((error) => {
        notifyError("Error adding components:", error.message);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector("#CustomTemplate3 #newCustom");
      closeButton.click();
    }
  };

  const inputRef = useRef(null);
  
  useEffect(() => {
    const modalElement = document.getElementById("CustomTemplate3");
    const handleModalShown = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };
    modalElement.addEventListener("shown.bs.modal", handleModalShown);
    return () => {
      modalElement.removeEventListener("shown.bs.modal", handleModalShown);
    };
  }, []);

  return (
    <div
      className="modal fade"
      id="CustomTemplate3"
      tabIndex="-1"
      aria-labelledby="CustomTemplate3"
      aria-hidden="true"
      data-bs-backdrop="static"
      onKeyDown={handleKeyDown}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4
              className="modal-title fs-5"
              style={{ color: "#858585" }}
              id="CustomTemplate3"
            >
              Custom Template
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <input
              name="infoboardname"
              type="text"
              ref={inputRef}
              className="form-control"
              placeholder="InfoBoard Name"
              value={addInfoboard.name}
              onChange={handleNameChange}
            />
            <div className="mt-3 align-content-start">
              <h5 className="text-start">Components in Infoboard</h5>
              <div className="d-flex flex-wrap">
                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Date
                </div>

                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Clock
                </div>

                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Pdf viewer
                </div>

                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Information bar
                </div>

                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Information sliding bar
                </div>
                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Web Browser
                </div>
                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Weather
                </div>
                <div
                  className={`btn p-4 m-1 ${"btn-info"}`}
                  style={{ pointerEvents: "none" }}
                >
                  Event calendar
                </div>
              </div>
              <h5 className="text-start mt-2">Preview of Infoboard</h5>
              <img src={custom} alt="customTemplateImage" height={375} />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger rounded-0"
              data-bs-dismiss="modal"
            >
              CLOSE
            </button>
            <button
              type="button"
              id="newCustom"
              className="btn btn-primary rounded-0"
              data-bs-dismiss="modal"
              onClick={handleSaveChanges}
              disabled={addInfoboard.name.length < 3}
            >
              Add custom template
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

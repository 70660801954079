import { MediaList } from "../Media/MediaList";

export const MediaComponentModal = () => {
  return (
    <div>
      <div
        className="modal fade"
        data-bs-backdrop="static"
        id="mediaModal"
        tabIndex="-1"
        aria-labelledby="mediaModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="mediaModalLabel">
                Media List
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <MediaList componentType="" />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

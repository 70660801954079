import React, { useState, useRef, useEffect, useMemo } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { Cross } from "hamburger-react";
import {
  fetchData,
  notifyError,
  patchData,
  isColorLight,
} from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { MenuItemWithImagesModal } from "./MenuWithImagesModal";

export const MenuWithImages = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const nodeRef = useRef(null);
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [menuItemData, setMenuItemData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isZindex, setIsZindex] = useState(false);
  const [imageMediaList, setImageMediaList] = useState([]);
  const [blobList, setBlobList] = useState([]);

  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}/${id}?$expand=media`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        const mediaList = data.Media || data.media;

        const currentDate = new Date();
        const filteredMediaList = mediaList?.filter((mediaItem) => {
          if (mediaItem.Expiry) {
            const expiryDate = new Date(mediaItem.Expiry);
            return expiryDate > currentDate;
          }
          return true;
        });
        setComponentInfo(settingsArray);
        setMenuItemData(data);
        setImageMediaList(filteredMediaList);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      });
  }, [id, sessionToken, selectedComponentId, guid]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });

      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setMenuItemData(data);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data:", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  useEffect(() => {
    if (imageMediaList && imageMediaList.length > 0) {
      const fetchMedia = async () => {
        const mediaArray = [];
        for (const media of imageMediaList) {
          let API;
          if (sessionToken) {
            API = `${ApiUrls.getMedia}(id=${media.MediaID || media.mediaID})`;
          } else {
            API = `${ApiUrls.getMediaExt}(id=${media.mediaID},Guid=${guid})`;
          }
          try {
            const imageUrlResponse = await fetch(API, {
              headers: {
                Authorization: `Bearer ${sessionToken}`,
              },
            });
            if (!imageUrlResponse.ok) {
              throw new Error(
                `Failed to fetch media for ID ${media.ID}: ${imageUrlResponse.statusText}`
              );
            }
            const blob = await imageUrlResponse.blob();
            const imageUrl = URL.createObjectURL(blob);
            const id = media.MediaID;
            mediaArray.push({ id, imageUrl });
          } catch (error) {
            notifyError(`Error fetching media for ID ${media.ID}:`, error);
          }
        }
        setBlobList(mediaArray);
      };
      fetchMedia();
    }
  }, [imageMediaList, sessionToken, guid]);

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    titleTextSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.titleTextSize
          : (componentInfo.titleTextSize * initialSize.height) /
            window.innerHeight,
      [componentInfo.titleTextSize, initialSize.height, selectedComponentId]
    ),
    descriptionTextSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.descriptionTextSize
          : (componentInfo.descriptionTextSize * initialSize.height) /
            window.innerHeight,
      [
        componentInfo.descriptionTextSize,
        initialSize.height,
        selectedComponentId,
      ]
    ),
    priceTextSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.priceTextSize
          : (componentInfo.priceTextSize * initialSize.height) /
            window.innerHeight,
      [componentInfo.priceTextSize, initialSize.height, selectedComponentId]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );
  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    if (!selectedComponentId) {
      const newWidth = Math.round((size.width / window.innerWidth) * 24);
      const newHeight = Math.round((size.height / window.innerHeight) * 24);
      patchSettingsToBackend2(
        {
          width: newWidth,
          height: newHeight,
        },
        menuItemData.SettingsJson,
        id
      );
    }
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        menuItemData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(data.SettingsJson);
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.color);

  const styles = {
    hamburger: {
      position: "absolute",
      top: 10,
      right: 10,
      zIndex: 1,
      color: result ? "black" : "white",
    },
  };

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              className={`${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              } d-flex justify-content-center align-items-center opacity-${
                componentInfo.opacity
              }`}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                backgroundColor: `${componentInfo.color}`,
                borderRadius: isRounded ? "10px" : "",
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
                fontSize: `${initialFontSize.textSize}vh`,
              }}
              ref={nodeRef}
            >
              {!selectedComponentId && (
                <div
                  style={styles.hamburger}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#menuItemModal${id}`}
                >
                  <Cross size={25} toggled={false} />
                </div>
              )}

              {componentInfo.withoutImage ? (
                <div className="d-flex w-100">
                  {/* Left Column: Image */}
                  <div className="col-6 d-flex justify-content-center align-items-center">
                    <img
                      src={
                        blobList
                          ? blobList[0]?.imageUrl
                          : "https://madklub.dk/files/23/media/3215-hvor-stammer-pizza-oprindeligt-fra.jpg"
                      }
                      alt="Margherita Pizza"
                      style={{ width: "80%" }}
                    />
                  </div>

                  {/* Right Column: Content */}
                  <div className="col-6 d-flex flex-column justify-content-center align-items-start">
                    <div
                      className="fw-bolder row"
                      style={{
                        color: componentInfo.titleTextColor,
                        fontSize: `${initialFontSize.titleTextSize}vh`,
                        marginTop: "-30px",
                      }}
                    >
                      {componentInfo.menuTitle || "#1 PIZZA MARGARITA"}
                    </div>

                    <small
                      className="text-start row pe-4"
                      style={{
                        color: componentInfo.descriptionTextColor,
                        fontSize: `${initialFontSize.descriptionTextSize}vh`,
                      }}
                    >
                      {componentInfo.description ||
                        "Classic pizza topped with fresh mozzarella, ripe tomatoes, and fragrant basil leaves, finished with a drizzle of olive oil."}
                    </small>

                    <div
                      className="row"
                      style={{
                        color: componentInfo.priceTextColor,
                        fontSize: `${initialFontSize.priceTextSize}vh`,
                      }}
                    >
                      {componentInfo.price || "$12.50"}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="ms-2 d-flex flex-column justify-content-center align-items-start w-100">
                  <div
                    className="fw-bolder"
                    style={{
                      color: componentInfo.titleTextColor,
                      fontSize: `${initialFontSize.titleTextSize}vh`,
                    }}
                  >
                    {componentInfo.menuTitle || "#1 PIZZA MARGARITA"}
                  </div>
                  <div
                    className="my-1 text-start pe-3"
                    style={{
                      color: componentInfo.descriptionTextColor,
                      fontSize: `${initialFontSize.descriptionTextSize}vh`,
                    }}
                  >
                    {componentInfo.description ||
                      "Classic pizza topped with fresh mozzarella, ripe tomatoes, and fragrant basil leaves, finished with a drizzle of olive oil."}
                  </div>
                  <div
                    className="fw-bolder"
                    style={{
                      color: componentInfo.priceTextColor,
                      fontSize: `${initialFontSize.priceTextSize}vh`,
                    }}
                  >
                    {componentInfo.price || "$12.50"}
                  </div>
                </div>
              )}
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
      {!selectedComponentId && (
        <MenuItemWithImagesModal
          id={id}
          patchSettingsToBackend2={patchSettingsToBackend2}
          menuItemData={menuItemData}
          componentInfo={componentInfo}
          positions={positions}
          deleteData={deleteData}
          componentName={componentName}
          imageMediaList={imageMediaList}
          setImageMediaList={setImageMediaList}
        />
      )}
    </>
  );
};

import html2canvas from "html2canvas";
import { notifyError, notifySuccess } from "../../Utils/ReusableFunctions";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { useState } from "react";

export const SaveCustomTemplate = ({ bgColor }) => {
  const { setCustomTemplate, selectedComponents } = useSettingsInfoBoard();
  const [templateName, setTemplateName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const handleSaveCustomTemplate = async () => {
    if (templateName.trim() === "") {
      notifySuccess("Please provide a template name.");
      return;
    }

    setIsSaving(true);

    try {
      const canvas = await html2canvas(
        document.querySelector("#componentToCapture")
      );
      canvas.toBlob((blob) => {
        if (blob) {
          const blobUrl = URL.createObjectURL(blob);

          setCustomTemplate((prevTemplate) => ({
            ...prevTemplate,
            componentSettings: [
              ...(Array.isArray(prevTemplate.componentSettings)
                ? prevTemplate.componentSettings
                : []),
              {
                templateName: templateName,
                backgroundColor: bgColor,
                isRoundedComponents: false,
                imageBlobUrl: blobUrl,
                settingsJson: selectedComponents.map((component) => ({
                  name: component.type,
                  settingsJson: JSON.parse(component.settings),
                })),
              },
            ],
          }));
          notifySuccess("Custom template saved!");
        }
      }, "image/png");
    } catch (error) {
      notifyError("Failed to save custom template. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      document.querySelector("#newCustom").click();
    }
  };

  return (
    <div
      className="modal"
      id="saveTemplate"
      tabIndex="-1"
      aria-labelledby="saveTemplateLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      onKeyDown={handleKeyDown}
    >
      <div className="modal-dialog">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title fs-5" id="saveTemplateLabel">
              Save custom template
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <input
              name="infoboardname"
              type="text"
              className="form-control"
              placeholder="InfoBoard Name"
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              disabled={isSaving}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger rounded-0"
              data-bs-dismiss="modal"
              disabled={isSaving}
            >
              CLOSE
            </button>
            <button
              type="button"
              id="newCustom"
              className="btn btn-primary rounded-0"
              data-bs-dismiss="modal"
              onClick={handleSaveCustomTemplate}
              disabled={isSaving}
            >
              {isSaving ? "Saving..." : "Add custom template"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import { ApiUrls } from "../../Utils/API";
import { fetchData, notifyError } from "../../Utils/ReusableFunctions";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";

export const NewComponentModal = ({ id }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [component, setComponent] = useState({
    name: "",
    boardID: id,
    settingsJson: "",
  });
  const [selectedComponentType, setSelectedComponentType] = useState("");
  const { setSelectedComponents } = useSettingsInfoBoard();

  const handleNameChange = (event) => {
    setComponent({ ...component, name: event.target.value });
  };

  const handleSaveChanges = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(component),
    };

    fetch(ApiUrls.component, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add component");
        }
        fetchData(ApiUrls.component, sessionToken)
          .then((data) => {
            const filteredComponents = data.value.filter((component) => {
              return String(component.BoardID) === String(id);
            });
            setSelectedComponents(
              filteredComponents.map((component) => ({
                id: component.ID,
                type: JSON.parse(component.SettingsJson).type,
                settings: component.SettingsJson,
                name: component.Name,
              }))
            );
          })
          .catch((error) => {
            notifyError("Error fetching component list:", error);
          });
        setComponent({ ...component, name: "", boardID: id, settingsJson: "" });
      })
      .catch((error) => {
        notifyError("Error adding component:", error);
      });

    setSelectedComponentType("");
  };

  const handleSelectChange = (componentType) => {
    setSelectedComponentType(componentType);
    let settings = {};

    switch (componentType) {
      case "PdfViewer":
        settings = {
          type: "PdfViewerComponent",
          xPosition: 0,
          yPosition: 0,
          width: 10,
          height: 15,
          opacity: 100,
          textColor: "black",
          backgroundColor: "#808080",
          pdfPageSpeed: 3000,
          errorText: 5,
          showList: false,
          currentPdfIndex: 0,
          pdfListText: 5,
          positionRight: true,
          zIndex: 0,
        };
        break;
      case "VideoViewer":
        settings = {
          type: "VideoViewerComponent",
          color: "gray",
          xPosition: 0,
          yPosition: 0,
          width: 10,
          height: 10,
          opacity: 100,
          textColor: "black",
          backgroundColor: "#808080",
          videoList: [],
          errorText: 10,
          showList: false,
          zIndex: 0,
        };
        break;
      case "Weather":
        settings = {
          type: "WeatherComponent",
          color: "#808080",
          xPosition: 0,
          yPosition: 0,
          width: 7,
          height: 15,
          opacity: 100,
          weatherTextSize: 8,
          weatherTextColor: "black",
          cityTextSize: 7,
          dataTextSize: 5,
          dataSize: 5,
          weatherCity: "Copenhagen",
          zIndex: 0,
        };
        break;
      case "Clock":
        settings = {
          type: "ClockComponent",
          xPosition: 0,
          yPosition: 0,
          width: 4,
          height: 4,
          opacity: 100,
          clockBackgroundColor: "#808080",
          clockTextColor: "black",
          clockRadius: 10,
          timeZones: [],
          cityNameText: 5,
          cityTimeText: 5,
          zIndex: 0,
        };
        break;
      case "Topbar":
        settings = {
          type: "TopbarComponent",
          color: "#808080",
          xPosition: 0,
          yPosition: 0,
          width: 24,
          height: 3,
          opacity: 100,
          textColor: "black",
          textSize: 10,
          info: "",
          zIndex: 0,
          boldText: false,
        };
        break;
      case "Infobar":
        settings = {
          type: "InfobarComponent",
          color: "#808080",
          xPosition: 0,
          yPosition: 0,
          width: 24,
          height: 3,
          opacity: 100,
          textColor: "black",
          textSize: 10,
          infoList: [],
          messageDelay: 5000,
          zIndex: 0,
          boldText: false,
        };
        break;
      case "SlidingInfo":
        settings = {
          type: "SlidingInfoComponent",
          color: "#808080",
          xPosition: 0,
          yPosition: 0,
          width: 24,
          height: 3,
          opacity: 100,
          textColor: "black",
          textSize: 10,
          animationDuration: 100,
          slidingText: "",
          zIndex: 0,
          boldText: false,
        };
        break;
      case "Date":
        settings = {
          type: "DateComponent",
          color: "#808080",
          xPosition: 0,
          yPosition: 0,
          width: 4,
          height: 3,
          opacity: 100,
          textColor: "black",
          textSize: 10,
          dateFormat: "dddd, MMMM Do YYYY",
          zIndex: 0,
        };
        break;
      case "WebBrowser":
        settings = {
          type: "WebBrowserComponent",
          color: "#808080",
          xPosition: 0,
          yPosition: 0,
          width: 5,
          height: 5,
          urlList: [],
          zIndex: 0,
          imageSpeed: 5000,
        };
        break;
      case "pdfList":
        settings = {
          type: "pdfList",
          backgroundColor: "transparent",
          textColor: "white",
          xPosition: 0,
          yPosition: 0,
          width: 21,
          height: 5,
          zIndex: 0,
        };
        break;
      case "Calendar":
        settings = {
          type: "CalendarComponent",
          color: "#808080",
          xPosition: 0,
          yPosition: 0,
          width: 5,
          height: 24,
          opacity: 100,
          textColor: "black",
          textSize: 5,
          events: [],
          zIndex: 0,
          title: "",
          eventTitleTextSize: 5,
          dateFormat: "dddd, MMMM Do YYYY",
        };
        break;
      case "Images":
        settings = {
          type: "ImageComponent",
          color: "#808080",
          xPosition: 0,
          yPosition: 0,
          width: 8,
          height: 8,
          opacity: 100,
          textColor: "black",
          textSize: 5,
          imageSpeed: 5000,
          zIndex: 0,
        };
        break;
      case "MenuItem":
        settings = {
          type: "MenuItemWithImages",
          color: "black",
          xPosition: 0,
          yPosition: 0,
          width: 7,
          height: 2,
          opacity: 100,
          titleTextColor: "#FF9922",
          descriptionTextColor: "#B4B4B7",
          priceTextColor: "#1ED848",
          titleTextSize: 1,
          descriptionTextSize: 1,
          priceTextSize: 1,
          zIndex: 0,
          withoutImage: false,
          menuTitle: "",
          description: "",
          price: "",
        };
        break;
      case "MenuOrder":
        settings = {
          type: "MenuOrderComponent",
          color: "#FFFFFF",
          xPosition: 0,
          yPosition: 0,
          width: 11,
          height: 10,
          opacity: 100,
          textColor: "black",
          textSize: 8,
          menu: [
          ],
          orders: [],
          zIndex: 0,
          weekdaysTextSize: 5,
        };
        break;
        case "ChangeInfoboard":
          settings = {
            type: "ChangeInfoboardComponent",
            color: "#FFFFFF",
            xPosition: 0,
            yPosition: 0,
            width: 2,
            height: 2,
            opacity: 100,
            textColor: "black",
            textSize: 8,
            zIndex: 0,
            idForInfoboard: null
          };
          break;

      default:
        settings = {};
    }
    setComponent({ ...component, settingsJson: JSON.stringify(settings) });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (component.name.length >= 3 || !selectedComponentType) {
        const closeButton = document.querySelector(
          "#newComponent #newCompClose"
        );
        closeButton.click();
      }
    }
  };

  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      id="newComponent"
      tabIndex="-1"
      aria-labelledby="newComponentLabel"
      aria-hidden="true"
      onKeyDown={handleKeyDown}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title fs-5" id="newComponent">
              New Component
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-start">
            <input
              name="ComponentName"
              type="text"
              className="form-control form-control-sm"
              placeholder="Component Name"
              value={component.name}
              onChange={handleNameChange}
            />
            <div className="mt-2">Choose a component</div>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              value="PdfViewer"
              onClick={() => handleSelectChange("PdfViewer")}
            />
            <label
              style={{ width: "31.5%" }}
              className={`btn p-4 m-1 ${
                selectedComponentType === "PdfViewer"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              htmlFor="option1"
            >
              Pdf Viewer
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option2"
              autoComplete="off"
              value="VideoViewer"
              onClick={() => handleSelectChange("VideoViewer")}
            />
            <label
              style={{ width: "31.5%" }}
              className={`btn p-4 m-1 ${
                selectedComponentType === "VideoViewer"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              htmlFor="option2"
            >
              Video Viewer
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option3"
              autoComplete="off"
              value="Weather"
              onClick={() => handleSelectChange("Weather")}
            />
            <label
              style={{ width: "31.5%" }}
              className={`btn p-4 m-1 ${
                selectedComponentType === "Weather" ? "btn-warning" : "btn-info"
              }`}
              htmlFor="option3"
            >
              Weather
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option9"
              autoComplete="off"
              value="WebBrowser"
              onClick={() => handleSelectChange("WebBrowser")}
            />
            <label
              style={{ width: "32%" }}
              className={`btn p-4  m-1 ${
                selectedComponentType === "WebBrowser"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              htmlFor="option9"
            >
              Web Browser
            </label>

            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option6"
              autoComplete="off"
              value="SlidingInfo"
              onClick={() => handleSelectChange("SlidingInfo")}
            />
            <label
              style={{ width: "32.5%" }}
              className={`btn p-4 m-1 ${
                selectedComponentType === "SlidingInfo"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              htmlFor="option6"
            >
              Sliding Text
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option8"
              autoComplete="off"
              value="Date"
              onClick={() => handleSelectChange("Date")}
            />
            <label
              style={{ width: "30%" }}
              className={`btn p-4  m-1 ${
                selectedComponentType === "Date" ? "btn-warning" : "btn-info"
              }`}
              htmlFor="option8"
            >
              Date
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option5"
              autoComplete="off"
              value="Clock"
              onClick={() => handleSelectChange("Clock")}
            />
            <label
              style={{ width: "28%" }}
              className={`btn p-4 m-1 ${
                selectedComponentType === "Clock" ? "btn-warning" : "btn-info"
              }`}
              htmlFor="option5"
            >
              Clock
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option4"
              autoComplete="off"
              value="Topbar"
              onClick={() => handleSelectChange("Topbar")}
            />
            <label
              className={`btn p-4 m-1 ${
                selectedComponentType === "Topbar" ? "btn-warning" : "btn-info"
              }`}
              htmlFor="option4"
            >
              Information Text
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option7"
              autoComplete="off"
              value="Infobar"
              onClick={() => handleSelectChange("Infobar")}
            />
            <label
              className={`btn p-4 m-1 ${
                selectedComponentType === "Infobar" ? "btn-warning" : "btn-info"
              }`}
              htmlFor="option7"
            >
              Information List
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="calendar"
              autoComplete="off"
              value="Calendar"
              onClick={() => handleSelectChange("Calendar")}
            />
            <label
              className={`btn p-4 m-1 ${
                selectedComponentType === "Calendar"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              htmlFor="calendar"
            >
              Calendar
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="images"
              autoComplete="off"
              value="Images"
              onClick={() => handleSelectChange("Images")}
            />
            <label
              className={`btn p-4 m-1 ${
                selectedComponentType === "Images" ? "btn-warning" : "btn-info"
              }`}
              htmlFor="images"
            >
              Image viewer
            </label>
       {/*      <input
              type="radio"
              className="btn-check"
              name="options"
              id="menuItem"
              autoComplete="off"
              value="MenuItem"
              onClick={() => handleSelectChange("MenuItem")}
            />

            <label
              className={`btn p-4 m-1 ${
                selectedComponentType === "MenuItem"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              htmlFor="menuItem"
            >
              Menu Item
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="menuOrder"
              autoComplete="off"
              value="MenuOrder"
              onClick={() => handleSelectChange("MenuOrder")}
            />
            <label
              className={`btn p-4 m-1 ${
                selectedComponentType === "MenuOrder"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              htmlFor="menuOrder"
            >
              Menu Order
            </label>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="changeInfo"
              autoComplete="off"
              value="ChangeInfoboard"
              onClick={() => handleSelectChange("ChangeInfoboard")}
            />
            <label
              className={`btn p-4 m-1 ${
                selectedComponentType === "ChangeInfoboard"
                  ? "btn-warning"
                  : "btn-info"
              }`}
              htmlFor="changeInfo"
            >
              Change Infoboard
            </label>
            */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              id="newCompClose"
              type="button"
              className="btn btn-primary"
              onClick={handleSaveChanges}
              data-bs-dismiss="modal"
              disabled={component.name.length < 3 || !selectedComponentType}
            >
              Add component
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { Cross } from "hamburger-react";
import { PdfViewerModal } from "./PdfViewerModal";
import { ApiUrls } from "../../Utils/API";
import {
  fetchData,
  notifyError,
  patchData,
  isColorLight,
} from "../../Utils/ReusableFunctions";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { useSwipeable } from "react-swipeable";




export const PdfViewer = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [readTime, setReadTime] = useState(false);
  const nodeRef = useRef(null);
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [pdfData, setPdfData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [blobList, setBlobList] = useState([]);
  const [intervalTime, setIntervalTime] = useState(null);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [pdfMediaList, setPdfMediaList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [isZindex, setIsZindex] = useState(false);

  const fetchName = useCallback(async () => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}(${id})?$expand=media($expand=media)`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const newList = sessionToken
          ? data.Media.map((file) => file.Media)
          : data.media.map((file) => file.media);
        setMediaList(newList);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      }); // eslint-disable-next-line
  }, [pdfMediaList]);

  useEffect(() => {
    fetchName();
  }, [fetchName]);




  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}(${id})?$expand=media($expand=media)`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }

    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        const mediaList = data.Media || data.media;
        const currentDate = new Date();
        const filteredMediaList = mediaList.filter((mediaItem) => {
          if (mediaItem.Expiry) {
            const expiryDate = new Date(mediaItem.Expiry);
            return expiryDate > currentDate;
          }
          return true;
        });
        setComponentInfo(settingsArray);
        setPdfData(data);
        setPdfMediaList(filteredMediaList);
        setIntervalTime(settingsArray.pdfPageSpeed || 5000);
      })
      .catch((error) => {
        //  console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      }); // eslint-disable-next-line
  }, [id, sessionToken, selectedComponentId, guid]);

  useEffect(() => {
    if (pdfMediaList && pdfMediaList.length > 0) {
    const fetchMedia = async () => {
      if (!Array.isArray(pdfMediaList)) {
        console.error("pdfMediaList is not an array:", pdfMediaList);
        return;
      }
      const mediaArray = [];
      for (const media of pdfMediaList) {
        //   const expiryDate = new Date(media.Expiry || media.expiry);
        //  if (expiryDate < new Date()) {

        //    continue;
        //  }

        let API;
        if (sessionToken) {
          API = `${ApiUrls.getMedia}(id=${media.MediaID || media.mediaID})`;
        } else {
          API = `${ApiUrls.getMediaExt}(id=${media.mediaID},Guid=${guid})`;
        }
        try {
          const imageUrlResponse = await fetch(API, {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          });

          if (!imageUrlResponse.ok) {
            throw new Error(
              `Failed to fetch media for ID ${media.MediaID}: ${imageUrlResponse.statusText}`
            );
          }
          const blob = await imageUrlResponse.blob();
          const imageUrl = URL.createObjectURL(blob);
          const id = media.MediaID || media.mediaID;
          mediaArray.push({
            id,
            imageUrl,
            expiry: media.Expiry || media.expiry,
            sortOrder: media.SortOrder || media.sortOrder,
          });
        } catch (error) {
          notifyError(`Error fetching media for ID ${media.MediaID}:`, error);
        }
      }
      mediaArray.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
      setBlobList(mediaArray);
    };

    fetchMedia();
  }
  }, [pdfMediaList, sessionToken, guid]);



  function onDocumentLoadSuccess({ numPages }) {
    if (numPages > 0) {
      setNumPages(numPages);
      setPageNumber(1);
    }
  }

  useEffect(() => {
    setNumPages(null);
    setPageNumber(1);
  }, [currentPdfIndex]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });
      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setPdfData(data);
          setCurrentPdfIndex(settingsArray.currentPdfIndex);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data:", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const fetchAdditionalData = async () => {
    let API;
    API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    fetchData(API, sessionToken).then((data) => {
      setPdfMediaList(data.Media || data.media);
      const settingsArray = JSON.parse(data.settingsJson || data.SettingsJson);
      setIntervalTime(settingsArray.pdfPageSpeed);
    });
  };

  useEffect(() => {
    if (blobList && blobList.length > 0) {
      const interval = setInterval(
        () => {
          setPageNumber((prevPageNumber) => {
            if (prevPageNumber === numPages) {
              setCurrentPdfIndex((prevIndex) => {
                const newIndex =
                  prevIndex === blobList.length - 1 ? 0 : prevIndex + 1;

                if (selectedComponentId) {
                  if (newIndex === 0) {
                    fetchAdditionalData();
                  }
                }

                return newIndex;
              });
              return 1;
            } else {
              return prevPageNumber + 1;
            }
          });
        },
        readTime
          ? 120000
          : selectedComponentId
          ? intervalTime
          : componentInfo.pdfPageSpeed
      );

      if (readTime) {
        setTimeout(() => setReadTime(false), 120000);
      }

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [
    blobList,
    numPages,
    intervalTime,
    setCurrentPdfIndex,
    setPageNumber,
    id,
    pdfData.SettingsJson,
    readTime,
    componentInfo.pdfPageSpeed,
  ]);

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo.height * window.innerHeight) / 24,
        width: (componentInfo.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    errorText: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.errorText
          : (componentInfo.errorText * initialSize.height) / window.innerHeight,
      [componentInfo.errorText, initialSize.height, selectedComponentId]
    ),
    pdfListText: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.pdfListText
          : (componentInfo.pdfListText * initialSize.height) /
            window.innerHeight,
      [componentInfo.pdfListText, initialSize.height, selectedComponentId]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );

  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    if (!selectedComponentId) {
      const newWidth = Math.round((size.width / window.innerWidth) * 24);
      const newHeight = Math.round((size.height / window.innerHeight) * 24);
      patchSettingsToBackend2(
        { width: newWidth, height: newHeight },
        pdfData.SettingsJson,
        id
      );
    }
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        pdfData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  const findMediaName = (mediaId) => {
    let media;
    if (sessionToken) {
      media = mediaList?.find((media) => media.ID === mediaId);
    } else {
      media = mediaList?.find((media) => media.id === mediaId);
    }
    return media ? media.Name || media.name : "Unknown";
  };

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(
          data.SettingsJson || data.settingsJson
        );
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.backgroundColor);

  const styles = {
    body: {
      overflow: "hidden",
    },
    hamburger: {
      position: "absolute",
      top: 10,
      right: 10,
      zIndex: 999,
      color: result ? "black" : "white",
    },
  };

  const handlers = useSwipeable({
    onSwipedUp: () => {
      setPageNumber((prevPageNumber) => {
        if (prevPageNumber === 1) {
          setCurrentPdfIndex((prevIndex) => {
            const newIndex =
              prevIndex === 0 ? blobList.length - 1 : prevIndex - 1;
            return newIndex;
          });
          return numPages;
        } else {
          return prevPageNumber - 1;
        }
      });
      setReadTime(false);
    },

    onSwipedDown: () => {
      setPageNumber((prevPageNumber) => {
        if (prevPageNumber === numPages) {
          setCurrentPdfIndex((prevIndex) => {
            const newIndex =
              prevIndex === blobList.length - 1 ? 0 : prevIndex + 1;
            return newIndex;
          });
          return 1;
        } else {
          return prevPageNumber + 1;
        }
      });
      setReadTime(false);
    },
    onTap: () => setReadTime(true),
  });

  const opt = useMemo(() => {
    return {
      cMapUrl: "/bcmaps/",
      cMapPacked: true,
      verbosity: pdfjs.VerbosityLevel.ERRORS,
    };
  }, []);

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              ref={nodeRef}
              className={` pdfContainer ${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              } ${isRounded ? "" : "rounded-0"} opacity-${
                componentInfo.opacity
              } d-flex   
              
              `}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                overflow: "hidden",
                borderRadius: isRounded ? "10px" : "",
                backgroundColor: componentInfo.backgroundColor,
                color: componentInfo.textColor,
                fontSize: `${initialFontSize.errorText}vh`,
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
              }}
            >
              <div style={{ display: "flex", height: "100%", width: "100%" }}>
                {componentInfo.showList && (
                  <>
                    {componentInfo.positionRight && (
                      <div
                        style={{
                          width: "30%",
                          borderRight: "1px solid #ccc",
                          padding: "10px",
                          overflow: "hidden",
                          backgroundColor: componentInfo.backgroundColor,
                          color: componentInfo.textColor,
                        }}
                      >
                        {pdfMediaList.map((pdf, index) => {
                          if (pdf.Expiry && new Date(pdf.Expiry) < new Date()) {
                            return null;
                          }

                          return (
                            <div
                              key={index}
                              className="text-start mt-2"
                              style={{
                                cursor: "pointer",

                                fontSize: initialFontSize.pdfListText + "vh",
                              }}
                              onClick={() => setCurrentPdfIndex(index)}
                            >
                              {findMediaName(pdf.mediaID || pdf.MediaID)}
                              <hr></hr>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
                <div style={{ flex: 1 }}>
                  {!selectedComponentId && (
                    <div
                      style={styles.hamburger}
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target={`#pdfViewerModal${id}`}
                    >
                      <Cross size={25} toggled={false} />
                    </div>
                  )}
                  <div
                    {...handlers}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Document
                      file={blobList && blobList[currentPdfIndex]?.imageUrl}
                      onLoadSuccess={onDocumentLoadSuccess}
                      noData="No files assigned"
                      loading=""
                      options={opt}
                    >
                      {blobList && blobList[currentPdfIndex] && (
                        <>
                          <Page
                            height={
                              selectedComponentId
                                ? document.getElementById("root").clientHeight
                                : initialSize.height
                            }
                            
                            loading=""
                            style={styles.body}
                            pageNumber={pageNumber}
                            renderTextLayer={false}
                            renderAnnotationLayer={false}
                            canvasRef={(canvas) => {
                              if (canvas) {
                                canvas.willReadFrequently = true;
                              }
                            }}
                          />
                        </>
                      )}
                    </Document>
                  </div>
                </div>
                {componentInfo.showList && (
                  <>
                    {!componentInfo.positionRight && (
                      <div
                        style={{
                          width: "30%",
                          borderLeft: "1px solid #ccc",
                          padding: "10px",
                          overflow: "hidden",
                          backgroundColor: componentInfo.backgroundColor,
                          color: componentInfo.textColor,
                        }}
                      >
                        {pdfMediaList.map((pdf, index) => (
                          <div
                            key={index}
                            className="text-start"
                            style={{
                              cursor: "pointer",
                              padding: "5px",
                              fontSize: initialFontSize.pdfListText + "vh",
                            }}
                            onClick={() => setCurrentPdfIndex(index)}
                          >
                            {findMediaName(pdf.mediaID || pdf.MediaID)}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
      {!selectedComponentId && (
        <PdfViewerModal
          id={id}
          patchSettingsToBackend2={patchSettingsToBackend2}
          pdfData={pdfData}
          componentInfo={componentInfo}
          positions={positions}
          deleteData={deleteData}
          pdfMediaList={pdfMediaList}
          setPdfMeidaList={setPdfMediaList}
          setCurrentPdfIndex={setCurrentPdfIndex}
          currentPdfIndex={currentPdfIndex}
          blobList={blobList}
          mediaList={mediaList}
          componentName={componentName}
        />
      )}
    </>
  );
};

import React, { useState, useRef, useEffect, useMemo } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { Cross } from "hamburger-react";
import {
  fetchData,
  notifyError,
  patchData,
  isColorLight,
  notifySuccess,
} from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import "./OrderMenu.css";
import { OrderMenuModal } from "./OrderMenuModal";
import { menuFormatDate, isTodayOrFuture } from "../Date/DateInfo";

export const OrderMenu = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const nodeRef = useRef(null);
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [orderMenuData, setOrderMenuData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isZindex, setIsZindex] = useState(false);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}/${id}`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        setComponentInfo(settingsArray);
        setOrderMenuData(data);
        setMenu(settingsArray.menu);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      });
  }, [id, sessionToken, selectedComponentId, guid]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });

      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setOrderMenuData(data);
          setMenu(settingsArray.menu);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data:", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    textSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.textSize
          : (componentInfo.textSize * initialSize.height) / window.innerHeight,
      [componentInfo.textSize, initialSize.height, selectedComponentId]
    ),
    weekdaysTextSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.weekdaysTextSize
          : (componentInfo.weekdaysTextSize * initialSize.height) /
            window.innerHeight,
      [componentInfo.weekdaysTextSize, initialSize.height, selectedComponentId]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );
  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    if (!selectedComponentId) {
      const newWidth = Math.round((size.width / window.innerWidth) * 24);
      const newHeight = Math.round((size.height / window.innerHeight) * 24);
      patchSettingsToBackend2(
        {
          width: newWidth,
          height: newHeight,
        },
        orderMenuData.SettingsJson,
        id
      );
    }
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        orderMenuData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(data.SettingsJson);
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.color);

  const styles = {
    hamburger: {
      position: "absolute",
      top: 1,
      right: 10,
      zIndex: 1,
      color: result ? "black" : "white",
    },
  };

  {
    /* 
  useEffect(() => {
    if (selectedComponentId) {
      const interval = setInterval(() => {
        let API;
        API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
        fetchData(API, sessionToken).then((data) => {
          const settingsArray = JSON.parse(
            data.settingsJson || data.SettingsJson
          );
          setCalendarEvents(settingsArray.events);
          setCalendarTitle(settingsArray.title);
          setCalendarTimeFormat(settingsArray.dateFormat);
        });
      }, 60000);
      return () => clearInterval(interval);
    }
  });

*/
  }

  const days = menu.map((m) => m.date);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState("");

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setSelectedItem(null);
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const handleEmployeeChange = (e) => {
    setSelectedEmployee(e.target.value);
  };

  const handleSubmit = () => {
    //console.log(`Order for ${selectedDay}:`, selectedItem);
    //console.log("Assigned to:", selectedEmployee);
    setSelectedDay(null);
    setSelectedItem(null);
    setSelectedEmployee(null);
    notifySuccess("Order succesfull");
  };

  const selectedMenu = menu.find((m) => m.date === selectedDay)?.items || [];

  const employees = [
    { id: "1", name: "Alice Johnson" },
    { id: "2", name: "Bob Smith" },
    { id: "3", name: "Charlie Brown" },
    { id: "4", name: "Diana Prince" },
    { id: "5", name: "Edward Stark" },
    { id: "6", name: "Edward Stark4" },
    { id: "7", name: "Edward Stark3" },
    { id: "8", name: "Edward Stark2" },
  ];

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              className={`${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              }   opacity-${componentInfo.opacity}`}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                backgroundColor: `${componentInfo.color}`,
                borderRadius: isRounded ? "10px" : "",
                fontSize: `${initialFontSize.textSize}vh`,
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
                overflow: "hidden",
              }}
              ref={nodeRef}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
            >
              {!selectedComponentId && (
                <div
                  style={styles.hamburger}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#orderMenuModal${id}`}
                >
                  <Cross size={25} toggled={false} />
                </div>
              )}

              <div
                className="row"
                style={{
                  height: selectedComponentId ? "100%" : initialSize.height,
                }}
              >
                <div
                  className={`col-${selectedDay ? (selectedItem ? 3 : 6) : 12}`}
                  style={{
                    transition: "width 0.5s",
                    height: selectedComponentId ? "100%" : initialSize.height,

                    overflow: "hidden",
                  }}
                >
                  <div className="list-group list-group-flush">
                    {days
                      .filter((day) => isTodayOrFuture(day))
                      .sort((a, b) => new Date(a) - new Date(b))
                      .slice(0, 5)
                      .map((day) => (
                        <div
                          style={{
                            backgroundColor: `${componentInfo.color}`,
                            cursor: "pointer",
                          }}
                          key={day}
                          className={`text-truncate list-group-item  ${
                            selectedDay === day ? "active" : ""
                          }`}
                          onClick={() => handleDayClick(day)}
                          onTouchStart={() => handleDayClick(day)}
                        >
                          {menuFormatDate(new Date(day))}
                        </div>
                      ))}
                  </div>
                </div>

                {selectedDay && (
                  <div
                    className={`col-${selectedItem ? 3 : 6}`}
                    style={{
                      transition: "width 0.5s",
                      height: selectedComponentId ? "100%" : initialSize.height,
                      overflow: "hidden",
                    }}
                  >
                    <div className="list-group list-group-flush">
                      {selectedMenu.length > 0 ? (
                        selectedMenu.map((item, index) => (
                          <div
                            style={{
                              backgroundColor: `${componentInfo.color}`,
                              cursor: "pointer",
                            }}
                            key={index}
                            className={`text-truncate list-group-item  ${
                              selectedItem === item ? "active" : ""
                            }`}
                            onClick={() => handleItemClick(item)}
                            onTouchStart={() => handleItemClick(item)}
                          >
                            {item.title}
                          </div>
                        ))
                      ) : (
                        <div className="list-group-item">
                          No items available
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedItem && (
                  <div
                    className="col-6 text-start "
                    style={{ transition: "width 0.5s" }}
                  >
                    <div className="list-group list-group-flush">
                      <div
                        className="list-group-item "
                        style={{ backgroundColor: `${componentInfo.color}` }}
                        aria-current="true"
                      >
                        <div className="d-flex w-100 justify-content-between">
                          <div>{selectedItem.title}</div>
                          <small>
                            {" "}
                            {menuFormatDate(new Date(selectedDay), "menuItem")}
                          </small>
                        </div>

                        <small>{selectedItem.description}</small>
                      </div>

                      <div className="mb-1 w-100 mt-2 ps-3 pe-3">
                        <select
                          id="employeeDropdown"
                          className="form-select me-"
                          value={selectedEmployee}
                          onChange={handleEmployeeChange}
                        >
                          <option value="">Choose an employee</option>
                          {employees.map((employee) => (
                            <option key={employee.id} value={employee.id}>
                              {employee.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="ps-3 pe-3">
                        <button
                          className="btn btn-primary   btn-sm w-100"
                          onClick={handleSubmit}
                          disabled={!selectedEmployee}
                          onTouchStart={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
      {!selectedComponentId && (
        <OrderMenuModal
          id={id}
          patchSettingsToBackend2={patchSettingsToBackend2}
          orderMenuData={orderMenuData}
          componentInfo={componentInfo}
          positions={positions}
          deleteData={deleteData}
          componentName={componentName}
          menu={menu}
          setMenu={setMenu}
          days={days}
          selectedDay={selectedDay}
        />
      )}
    </>
  );
};

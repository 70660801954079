import { SketchPicker } from "react-color";
import { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import { MediaList } from "../Media/MediaList";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { handleDeleteFile } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { fetchData, notifyError } from "../../Utils/ReusableFunctions";

export const MenuItemWithImagesModal = ({
  id,
  deleteData,
  componentInfo,
  patchSettingsToBackend2,
  menuItemData,
  componentName,
  imageMediaList,
  setImageMediaList,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { mediaList, setComponentMedia } = useSettingsInfoBoard();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [settings, setSettings] = useState({});
  const [isDraggable, setIsDraggable] = useState(true);
  const [expiryDate, setExpiryDate] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedMediaIndex, setEditedEventIndex] = useState(null);

  const [timeouts, setTimeouts] = useState({
    textSize: null,
    height: null,
    width: null,
    xPosition: null,
    yPosition: null,
    opacity: null,
    zIndex: null,
    menuTitle: null,
  });
  const nodeRef = useRef(null);
  useEffect(() => {
    if (componentInfo) {
      setSettings({
        backgroundColor: componentInfo.color,
        titleTextColor: componentInfo.titleTextColor,
        descriptionTextColor: componentInfo.descriptionTextColor,
        priceTextColor: componentInfo.priceTextColor,
        opacity: componentInfo.opacity,
        width: componentInfo.width,
        height: componentInfo.height,
        titleTextSize: componentInfo.titleTextSize,
        descriptionTextSize: componentInfo.descriptionTextSize,
        priceTextSize: componentInfo.priceTextSize,
        xPosition: componentInfo.xPosition,
        yPosition: componentInfo.yPosition,
        zIndex: componentInfo.zIndex,
        withoutImage: componentInfo.withoutImage,
        menuTitle: componentInfo.menuTitle,
        description: componentInfo.description,
        price: componentInfo.price,
      });
    }
  }, [componentInfo]);

  const findMediaName = (mediaId) => {
    const media = mediaList.find((media) => media.ID === mediaId);
    return media ? media.Name : "Unknown";
  };

  const updateTimeout = (key, value) => {
    setTimeouts((prevTimeouts) => ({
      ...prevTimeouts,
      [key]: value,
    }));
  };

  useEffect(() => {
    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [timeouts]);

  const handleOnBackgroundChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, backgroundColor: newColor });
    patchSettingsToBackend2(
      {
        color: newColor,
      },
      menuItemData.SettingsJson,
      id
    );
  };

  const handleOnTitleTextColorChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, textColor: newColor });
    patchSettingsToBackend2(
      {
        titleTextColor: newColor,
      },
      menuItemData.SettingsJson,
      id
    );
  };

  const handleOnDescriptionTextColorChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, textColor: newColor });
    patchSettingsToBackend2(
      {
        descriptionTextColor: newColor,
      },
      menuItemData.SettingsJson,
      id
    );
  };

  const handleOnPriceTextColorChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, textColor: newColor });
    patchSettingsToBackend2(
      {
        priceTextColor: newColor,
      },
      menuItemData.SettingsJson,
      id
    );
  };

  const handleSettingChange = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    clearTimeout(timeouts[key]);
    updateTimeout(
      key,
      setTimeout(() => {
        patchSettingsToBackend2(
          { [key]: value },
          menuItemData.SettingsJson,
          id
        );
      }, 500)
    );
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector(
        `#infobarModal${id} #basic-addon2`
      );
      closeButton.click();
    }
  };

  const handleInputChange = (key, value, maxLimit) => {
    let parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      parsedValue = 2;
    } else if (parsedValue > maxLimit) {
      parsedValue = maxLimit;
    }
    handleSettingChange(key, parsedValue);
  };

  const handleTextChange = () => {
    const newTextBold = !settings.withoutImage;
    setSettings({ ...settings, withoutImage: newTextBold });
    patchSettingsToBackend2(
      {
        withoutImage: newTextBold,
      },
      menuItemData.SettingsJson,
      id
    );
  };

  const handleSaveChanges = (mediaID) => {
    const formattedExpiryDate = new Date(expiryDate).toISOString();

    const patchUrl = `${ApiUrls.componentMedia}(${mediaID})`;
    const patchData = {
      Expiry: formattedExpiryDate,
    };

    fetch(patchUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(patchData),
    })
      .then((response) => {
        if (response.ok) {
          setIsEditing(false);
          setEditedEventIndex(null);
          setExpiryDate("");
          fetchData(
            `${ApiUrls.component}(${id})?$expand=media($expand=media)`,
            sessionToken
          )
            .then((data) => {
              const mediaList = data.Media || data.media;
              const currentDate = new Date();
              const filteredMediaList = mediaList.filter((mediaItem) => {
                if (mediaItem.Expiry) {
                  const expiryDate = new Date(mediaItem.Expiry);
                  return expiryDate > currentDate;
                }
                return true;
              });
              setImageMediaList(filteredMediaList);
            })
            .catch((error) => {
              console.error("Failed to fetch data:", error);
              notifyError("Failed to fetch data:", error);
            });
        } else {
          notifyError("Error updating media list");
        }
      })
      .catch((error) => {
        notifyError("Error updating media list", error);
      });
  };

  const startEditing = (index) => {
    setIsEditing(true);
    setEditedEventIndex(index);
  };

  const handleDateChange = (e) => {
    setExpiryDate(e.target.value);
  };

  return (
    <Draggable nodeRef={nodeRef} disabled={!isDraggable}>
      <div
        className="modal "
        id={`menuItemModal${id}`}
        tabIndex="-1"
        aria-labelledby={`menuItemModal${id}`}
        aria-hidden="true"
        data-bs-backdrop="static"
        onKeyDown={handleKeyDown}
        ref={nodeRef}
   
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <div className="row w-100">
                <div className="col-6">
                  <h4
                    className="modal-title fs-5 text-start"
                    id={`menuItemModal${id}`}
                  >
                    Properties for {componentName}
                  </h4>
                </div>
                <div className="col-6">
                  <h4
                    className="modal-title fs-5 text-start"
                    id={`menuItemModal${id}`}
                  >
                    Image list
                  </h4>
                </div>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              onMouseEnter={() => setIsDraggable(false)}
              onMouseLeave={() => setIsDraggable(true)}
            >
              <div className="row">
                <div className="col">
                  <>
                    <ol className="list-group list-group-flush text-start">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className=" me-auto">Opacity</div>
                        <span className="me-2">{settings.opacity}%</span>
                        <span className="">
                          <input
                            type="range"
                            className="form-range"
                            min="0"
                            max="100"
                            step="25"
                            id="customRange3"
                            value={settings.opacity || 0}
                            onChange={(e) =>
                              handleSettingChange(
                                "opacity",
                                parseInt(e.target.value)
                              )
                            }
                          />
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="row w-100">
                          <div className="col input-group">
                            <span
                              className="input-group-text border-end-0"
                              id="width"
                            >
                              width
                            </span>
                            <input
                              name="width"
                              type="number"
                              min={2}
                              max={24 - settings.xPosition || 0}
                              className="form-control"
                              aria-label="width"
                              aria-describedby="width"
                              onChange={(e) =>
                                handleInputChange(
                                  "width",
                                  e.target.value,
                                  24 - settings.xPosition
                                )
                              }
                              value={settings.width || ""}
                            />
                          </div>
                          <div className="col input-group">
                            <span className="input-group-text" id="height">
                              height
                            </span>
                            <input
                              name="height"
                              type="number"
                              min={2}
                              max={24 - settings.yPosition || 0}
                              className="form-control"
                              aria-label="Username"
                              aria-describedby="height"
                              onChange={(e) =>
                                handleInputChange(
                                  "height",
                                  e.target.value,
                                  24 - settings.yPosition
                                )
                              }
                              value={settings.height || ""}
                            />
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="row w-100">
                          <div className="col input-group">
                            <span className="input-group-text" id="X">
                              X
                            </span>
                            <input
                              name="X"
                              min={0}
                              max={24 - settings.width || 0}
                              type="number"
                              className="form-control"
                              aria-label="X"
                              aria-describedby="X"
                              onChange={(e) =>
                                handleInputChange(
                                  "xPosition",
                                  e.target.value,
                                  24 - settings.width
                                )
                              }
                              value={settings.xPosition || 0}
                            />
                          </div>
                          <div className="col input-group">
                            <span
                              className="input-group-text border-end-0"
                              id="Y"
                            >
                              Y
                            </span>
                            <input
                              name="Y"
                              type="number"
                              min={0}
                              max={24 - settings.height || 0}
                              className="form-control"
                              aria-label="Y"
                              aria-describedby="Y"
                              onChange={(e) =>
                                handleInputChange(
                                  "yPosition",
                                  e.target.value,
                                  24 - settings.height
                                )
                              }
                              value={settings.yPosition || 0}
                            />
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto">zIndex</div>
                        <input
                          name="textZindexbottom"
                          type="number"
                          className="form-control w-25 me-4"
                          value={settings.zIndex || 0}
                          onChange={(e) =>
                            handleSettingChange(
                              "zIndex",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto">Title text size</div>
                        <input
                          name="titletextsizebottom"
                          type="number"
                          className="form-control w-25 me-4"
                          value={settings.titleTextSize || ""}
                          onChange={(e) =>
                            handleSettingChange(
                              "titleTextSize",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto">
                          Description text size
                        </div>
                        <input
                          name="descriptionsizebottom"
                          type="number"
                          className="form-control w-25 me-4"
                          value={settings.descriptionTextSize || ""}
                          onChange={(e) =>
                            handleSettingChange(
                              "descriptionTextSize",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto">Price text size</div>
                        <input
                          name="pricetextsizebottom"
                          type="number"
                          className="form-control w-25 me-4"
                          value={settings.priceTextSize || ""}
                          onChange={(e) =>
                            handleSettingChange(
                              "priceTextSize",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto pe-1">Title</div>
                        <input
                          name="menuTitle"
                          type="text"
                          className="form-control w-100 me-4"
                          value={settings.menuTitle || ""}
                          onChange={(e) =>
                            handleSettingChange("menuTitle", e.target.value)
                          }
                        />
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto pe-1">Price</div>
                        <input
                          name="price"
                          type="text"
                          className="form-control w-100 me-4"
                          value={settings.price || ""}
                          onChange={(e) =>
                            handleSettingChange("price", e.target.value)
                          }
                        />
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className="form-floating me-4 w-100">
                          <textarea
                            className="form-control"
                            id={`floatingTextareaDescription ${id}`}
                            style={{ height: "100px" }}
                            value={settings.description}
                            onChange={(e) =>
                              handleSettingChange("description", e.target.value)
                            }
                          ></textarea>
                          <label htmlFor={`floatingTextareaDescription ${id}`}>
                            Description for menu item
                          </label>
                        </div>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                            htmlFor={`hideImage ${id}`}
                          >
                            {settings.withoutImage ? "With Image" : "Without Image"}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`hideImage ${id}`}
                            checked={settings.withoutImage || false}
                            onChange={handleTextChange}
                          />
                        </div>
                      </li>
                    </ol>
                  </>
                  <div
                    className="accordion accordion-flush"
                    id="accordionInfobar"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOneInfobar"
                          aria-expanded="true"
                          aria-controls="collapseOneInfobar"
                        >
                          Background Color
                        </button>
                      </h2>
                      <div
                        id="collapseOneInfobar"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionInfobar"
                      >
                        <div className="accordion-body">
                          <SketchPicker
                            color={settings.backgroundColor}
                            onChangeComplete={handleOnBackgroundChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTitle"
                          aria-expanded="true"
                          aria-controls="collapseTitle"
                        >
                          Title text Color
                        </button>
                      </h2>
                      <div
                        id="collapseTitle"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionInfobar"
                      >
                        <div className="accordion-body">
                          <SketchPicker
                            color={settings.titleTextColor}
                            onChangeComplete={handleOnTitleTextColorChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseDescription"
                          aria-expanded="true"
                          aria-controls="collapseDescription"
                        >
                          Description text Color
                        </button>
                      </h2>
                      <div
                        id="collapseDescription"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionInfobar"
                      >
                        <div className="accordion-body">
                          <SketchPicker
                            color={settings.descriptionTextColor}
                            onChangeComplete={
                              handleOnDescriptionTextColorChange
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsePrice"
                          aria-expanded="true"
                          aria-controls="collapsePrice"
                        >
                          Price text Color
                        </button>
                      </h2>
                      <div
                        id="collapsePrice"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionInfobar"
                      >
                        <div className="accordion-body">
                          <SketchPicker
                            color={settings.priceTextColor}
                            onChangeComplete={handleOnPriceTextColorChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Image list
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ol className="list-group list-group-flush text-start">
                            {imageMediaList &&
                              imageMediaList
                                .sort((a, b) => a.SortOrder - b.SortOrder)
                                .map((item, index) => (
                                  <li
                                    key={item.MediaID || item.mediaID}
                                    className="list-group-item d-flex justify-content-between align-items-start"
                                  >
                                    <div className="ms-2 me-auto w-100">
                                      <div>
                                        {findMediaName(
                                          item.MediaID || item.mediaID
                                        )}{" "}
                                      </div>
                                      {isEditing &&
                                      editedMediaIndex === index ? (
                                        <>
                                          <div>
                                            <input
                                              type="date"
                                              value={
                                                expiryDate
                                                  ? expiryDate.split("T")[0]
                                                  : ""
                                              }
                                              onChange={handleDateChange}
                                            />
                                            <button
                                              className="btn btn-success btn-sm ms-1 rounded-0"
                                              style={{ width: "32%" }}
                                              onClick={() =>
                                                handleSaveChanges(item.ID)
                                              }
                                            >
                                              Save changes
                                            </button>
                                            <button
                                              style={{ width: "32%" }}
                                              className="btn btn-danger ms-1 btn-sm rounded-0"
                                              onClick={() =>
                                                setIsEditing(false)
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        item.Expiry && (
                                          <small>
                                            - expire{" "}
                                            {
                                              new Date(item.Expiry)
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                          </small>
                                        )
                                      )}
                                    </div>
                                    {!isEditing && (
                                      <>
                                        <span
                                          className="badge btn-sm btn text-bg-danger text-white rounded-0"
                                          onClick={() => {
                                            handleDeleteFile(
                                              item.ID,
                                              sessionToken,
                                              id,
                                              setImageMediaList,
                                              setComponentMedia
                                            );
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="bi bi-trash3"
                                            style={{ fontSize: "14px" }}
                                          ></i>
                                        </span>
                                        <span
                                          className="badge btn-sm btn btn-secondary text-black rounded-0 ms-1"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            startEditing(index, item.Expiry)
                                          }
                                        >
                                          <i
                                            className="bi bi-pen"
                                            style={{ fontSize: "14px" }}
                                          ></i>
                                        </span>
                                      </>
                                    )}
                                    <small></small>
                                  </li>
                                ))}

                            {imageMediaList && imageMediaList.length === 0 && (
                              <div className="text-center">
                                NO FILE AVAILABLE
                              </div>
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <MediaList
                    componentType={"image"}
                    id={id}
                    setList={setImageMediaList}
                    list={imageMediaList}
                    isTrue={true}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {deleteConfirmation ? (
                <>
                  <div>Are you sure ?</div>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-0 "
                    onClick={deleteData}
                    data-bs-dismiss="modal"
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm rounded-0"
                    onClick={() => setDeleteConfirmation(false)}
                  >
                    NO
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-0 "
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    {" "}
                    REMOVE COMPONENT{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm rounded-0"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

import { useState, useRef, useEffect, useMemo } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { ApiUrls } from "../../Utils/API";
import {
  fetchData,
  patchData,
  notifyError,
} from "../../Utils/ReusableFunctions";
import { Cross } from "hamburger-react";
import { WebBrowserModal } from "./WebBrowserModal";
import photo from "../../Assets/decentra.PNG";

export const WebBrowserContainer = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [webData, setWebData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const nodeRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  // const [currentInfoIndex, setCurrentInfoIndex] = useState(0);
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [isZindex, setIsZindex] = useState(false);
  /* 
  useEffect(() => {
    setCurrentInfoIndex(0);
  }, [componentInfo]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentInfoIndex(
        (prevIndex) => (prevIndex + 1) % componentInfo?.urlList?.length
      );
    }, componentInfo.urlDelay);

    return () => clearInterval(interval);
  }, [componentInfo]);
*/




  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}/${id}`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        setComponentInfo(settingsArray);
        setWebData(data);
      })
      .catch((error) => {
        notifyError("Failed to fetch data:", error);
      });
  }, [id, sessionToken, selectedComponentId, guid]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });
      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setWebData(data);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );

  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    const newWidth = Math.round((size.width / window.innerWidth) * 24);
    const newHeight = Math.round((size.height / window.innerHeight) * 24);
    patchSettingsToBackend2(
      {
        width: newWidth,
        height: newHeight,
      },
      webData.SettingsJson,
      id
    );
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        webData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(
          data.SettingsJson || data.settingsJson
        );
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              className={`${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              }  `}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                backgroundColor: "",
                borderRadius: isRounded ? "10px" : "",
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
                overflowX:"hidden"
              }}
              ref={nodeRef}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
            >
              {!selectedComponentId && (
                <>
                  <div
                    className="hamburger"
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 20,
                      zIndex: 1,
                      color: "black",
                    }}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target={`#webModal${id}`}
                  >
                    <Cross size={25} toggled={false} />
                  </div>
                </>
              )}

              {!selectedComponentId &&
                ({
                  /* 
                <embed
                  type="text/html"
                  title="defaultWeb"
                  style={{
                    height: "100%",
                    width: "100%",
                    pointerEvents: "none",
                  }}
                  src="http://www.decentra.dk"
                />
*/
                },
                (
                  <img
                    src={photo}
                    alt="staticimage"
                    style={{
                      height: "100%",
                      width: "100%",
                      pointerEvents: "none",
                      borderRadius: isRounded ? "10px" : "",
                    }}
                  />
                ))}
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
      {!selectedComponentId && (
        <WebBrowserModal
          id={id}
          patchSettingsToBackend2={patchSettingsToBackend2}
          webData={webData}
          componentInfo={componentInfo}
          deleteData={deleteData}
          componentName={componentName}
        />
      )}
    </>
  );
};

import React, { createContext, useState, useContext, useEffect } from "react";
import { ApiUrls } from "../Utils/API";
import { notifyError } from "../Utils/ReusableFunctions";

const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [isRounded, setIsRounded] = useState(true);
  const [componentsZIndexes, setComponentsZIndexes] = useState({});
  const [mediaList, setMediaList] = useState([]);
  const [isLocked, setIsLocked] = useState(true);
  const [componentSize, setComponentSize] = useState({});
  const [positions, setPositions] = useState({});
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [fontSize, setFontSize] = useState({});
  const [currentPdfIndexes, setCurrentPdfIndexes] = useState({});
  const [pdfLists, setPdfLists] = useState({});
  const [deleteTriggers, setDeleteTriggers] = useState({});
  const [hideTriggers, setHideTriggers] = useState({});
  const [customTemplate, setCustomTemplate] = useState([]);
  const [componentMedia, setComponentMedia] = useState([]);
  const [jwtToken, setJwtToken ] = useState([])
  const [infoBoardList, setInfoBoardList] = useState([]);

  const addComponent = (component) => {
    setSelectedComponents([...selectedComponents, component]);
  };

  const bringComponentToTop = (componentId) => {
    setComponentsZIndexes((prevZIndexes) => ({
      ...prevZIndexes,
      [componentId]: 999,
    }));

    setTimeout(() => {
      setComponentsZIndexes((prevZIndexes) => ({
        ...prevZIndexes,
        [componentId]: 0,
      }));
    }, 5000);
  };

  const setSizeCustomComponent = (id, size) => {
    setComponentSize((prevSize) => ({
      ...prevSize,
      [id]: size,
    }));
  };

  const setPositionCustomComponent = (id, position) => {
    setPositions((prevPositions) => ({
      ...prevPositions,
      [id]: position,
    }));
  };

  const setFontSizeCustomComponent = (id, size) => {
    setFontSize((prevSizes) => ({
      ...prevSizes,
      [id]: size,
    }));
  };

  const setCurrentPdfIndexForComponent = (componentId, updateFn) => {
    setCurrentPdfIndexes((prevIndexes) => {
      const newIndex =
        typeof updateFn === "function"
          ? updateFn(prevIndexes[componentId] || 0)
          : updateFn;
      return {
        ...prevIndexes,
        [componentId]: newIndex,
      };
    });
  };

  const setPdfListForComponent = (componentId, list) => {
    setPdfLists((prevPdfLists) => ({
      ...prevPdfLists,
      [componentId]: list,
    }));
  };

  const setDeleteTriggerForComponent = (componentId, value) => {
    setDeleteTriggers((prevTriggers) => ({
      ...prevTriggers,
      [componentId]: value,
    }));
  };

  const setHideTriggerForComponent = (componentId, value) => {
    setHideTriggers((prevTriggers) => ({
      ...prevTriggers,
      [componentId]: value,
    }));
  };

  const deleteData = async (componentId) => {
    try {
      const response = await fetch(`${ApiUrls.component}/${componentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to delete data");
      }
      setSelectedComponents((prevSelectedComponents) =>
        prevSelectedComponents.filter(
          (component) => component.id !== componentId
        )
      );
    } catch (error) {
      notifyError("Error deleting data", error);
    }
  };

  useEffect(() => {
    Object.keys(deleteTriggers).forEach((componentId) => {
      if (deleteTriggers[componentId]) {
        setDeleteTriggerForComponent(componentId, false);
      }
    });
  }, [deleteTriggers]);

  const getContextValue = (componentId) => {
    return {
      infoBoardList,setInfoBoardList,
      componentMedia, setComponentMedia,
      jwtToken, setJwtToken,
      selectedComponents,
      addComponent,
      setSelectedComponents,
      setPositionCustomComponent,
      positions,
      setSizeCustomComponent,
      componentSize,
      isLocked,
      setIsLocked,
      mediaList,
      setMediaList,
      isRounded,
      setIsRounded,
      currentVideoIndex,
      setCurrentVideoIndex,
      fontSize,
      setFontSizeCustomComponent,
      currentPdfIndex: currentPdfIndexes[componentId] || 0,
      setCurrentPdfIndex: (index) =>
        setCurrentPdfIndexForComponent(componentId, index),
      zIndex: componentsZIndexes[componentId] || 0,
      bringComponentToTop: () => bringComponentToTop(componentId),
      pdfMediaList: pdfLists[componentId] || [],
      setPdfMediaList: (list) => setPdfListForComponent(componentId, list),
      deleteData,
      triggerDelete: () => setDeleteTriggerForComponent(componentId, true),
      deleteTrigger: deleteTriggers[componentId] || false,
      triggerHide: () => setHideTriggerForComponent(componentId, true),
      hideTrigger: hideTriggers[componentId] || false,
      customTemplate,
      setCustomTemplate,
    };
  };

  const contextValue = {
    getContextValue,
  };

  return (
    <SettingsContext.Provider value={contextValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsInfoBoard = (componentId) => {
  const { getContextValue } = useContext(SettingsContext);
  return getContextValue(componentId);
};

import React, { useState, useRef, useEffect, useMemo } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { Cross } from "hamburger-react";
import {
  fetchData,
  notifyError,
  patchData,
  isColorLight,
} from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { CalendarModal } from "./CalendarModal";
import { isToday, isTodayOrFuture } from "../Date/DateInfo";
import { formatDate } from "../Date/DateInfo";

export const Calendar = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const nodeRef = useRef(null);
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [calendarData, setCalendarData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isZindex, setIsZindex] = useState(false);
  const [showText, setShowText] = useState({});
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [calendarTitle, setCalendarTitle] = useState("");
  const [calendarTimeFormat, setCalendarTimeFormat] = useState("");

  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}/${id}`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        setComponentInfo(settingsArray);
        setCalendarData(data);
        setCalendarEvents(settingsArray.events);
        setCalendarTitle(settingsArray.title);
        setCalendarTimeFormat(settingsArray.dateFormat);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      });
  }, [id, sessionToken, selectedComponentId, guid]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });

      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setCalendarData(data);
          setCalendarEvents(settingsArray.events);
          setCalendarTitle(settingsArray.title);
          setCalendarTimeFormat(settingsArray.dateFormat);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data:", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    textSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.textSize
          : (componentInfo.textSize * initialSize.height) / window.innerHeight,
      [componentInfo.textSize, initialSize.height, selectedComponentId]
    ),
    eventTitleTextSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.eventTitleTextSize
          : (componentInfo.eventTitleTextSize * initialSize.height) /
            window.innerHeight,
      [
        componentInfo.eventTitleTextSize,
        initialSize.height,
        selectedComponentId,
      ]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );
  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    if (!selectedComponentId) {
      const newWidth = Math.round((size.width / window.innerWidth) * 24);
      const newHeight = Math.round((size.height / window.innerHeight) * 24);
      patchSettingsToBackend2(
        {
          width: newWidth,
          height: newHeight,
        },
        calendarData.SettingsJson,
        id
      );
    }
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        calendarData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(data.SettingsJson);
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.color);

  const styles = {
    hamburger: {
      position: "absolute",
      top: 1,
      right: 10,
      zIndex: 1,
      color: result ? "black" : "white",
    },
  };

  const toggleShowText = (index) => () => {
    setShowText((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    if (selectedComponentId) {
      const interval = setInterval(() => {
        let API;
        API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
        fetchData(API, sessionToken).then((data) => {
          const settingsArray = JSON.parse(
            data.settingsJson || data.SettingsJson
          );
          setCalendarEvents(settingsArray.events);
          setCalendarTitle(settingsArray.title);
          setCalendarTimeFormat(settingsArray.dateFormat);
        });
      }, 60000);
      return () => clearInterval(interval);
    }
  });

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              className={`${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              }   opacity-${componentInfo.opacity}`}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                backgroundColor: `${componentInfo.color}`,
                borderRadius: isRounded ? "10px" : "",
                fontSize: `${initialFontSize.textSize}vh`,
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
              }}
              ref={nodeRef}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
            >
              {!selectedComponentId && (
                <div
                  style={styles.hamburger}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#calendarModal${id}`}
                >
                  <Cross size={25} toggled={false} />
                </div>
              )}

              {componentInfo.title && (
                <div
                  className="text-center mt-3 ms-3 mb-2 fw-bold"
                  style={{
                    color: componentInfo.textColor,
                    fontSize: initialFontSize.eventTitleTextSize + "vh",
                  }}
                >
                  {selectedComponentId ? calendarTitle : componentInfo.title}
                </div>
              )}

              {componentInfo.events?.length > 0 ? (
                <div
                  className="list-group rounded-0 w-100 scroll "
                  style={{
                    maxHeight: selectedComponentId ? "90%" : "100%",
                    overflow: "scroll",
                    overflowX: "hidden",
                    height: selectedComponentId
                      ? null
                      : initialSize.height - 75,
                  }}
                >
                  <>
                    {(selectedComponentId
                      ? calendarEvents
                      : componentInfo.events
                    )
                      ?.filter((event) => isTodayOrFuture(event.date))
                      .sort((a, b) => new Date(a.date) - new Date(b.date))
                      .map((event, index) => {
                        const isTextShown = showText[index];
                        const isEventToday = isToday(event.date);
                        const eventStyle = {
                          borderLeft: `5px solid ${event.color}`,
                          backgroundColor: isEventToday
                            ? "rgba(245, 245, 245, 0.4)"
                            : componentInfo.color,
                          color: componentInfo.textColor,
                          borderBottom:
                            index < componentInfo.events.length - 1
                              ? "1px solid gray"
                              : "none",
                        };

                        return (
                          <div
                            className="list-group-item rounded-0 text-start"
                            aria-current="true"
                            style={eventStyle}
                            key={index}
                            onClick={toggleShowText(index)}
                          >
                            <div style={{ cursor: "pointer" }}>
                              <div className="d-flex w-100 justify-content-between mb-1">
                                <small>
                                  {formatDate(
                                    new Date(event.date),
                                    selectedComponentId
                                      ? calendarTimeFormat
                                      : componentInfo.dateFormat ||
                                          "dddd, MMMM Do YYYY"
                                  )}
                                </small>
                                <small>{event.time}</small>
                              </div>
                              <div className="fw-bolder">{event.title}</div>
                              <div
                                id={index}
                                className={`text-start text-container 
                               
                                `}
                                style={{
                                  maxWidth: "90%",
                                  overflow: isTextShown ? "visible" : "hidden",
                                  textOverflow: isTextShown
                                    ? "clip"
                                    : "ellipsis",
                                  whiteSpace: isTextShown ? "normal" : "nowrap",
                                }}
                              >
                                <small
                                  style={{
                                    overflowWrap: isTextShown
                                      ? "anywhere"
                                      : "normal",
                                  }}
                                >
                                  {event.subject}
                                </small>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </>
                </div>
              ) : (
                <div style={{ color: componentInfo.textColor }}>
                  NO EVENT AVAILABLE
                </div>
              )}
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
      {!selectedComponentId && (
        <CalendarModal
          id={id}
          patchSettingsToBackend2={patchSettingsToBackend2}
          calendarData={calendarData}
          componentInfo={componentInfo}
          positions={positions}
          deleteData={deleteData}
          componentName={componentName}
        />
      )}
    </>
  );
};

export const ApiUrls = {
  infobard: "https://infoboard.decentra.net/odata/board/",
  component: "https://infoboard.decentra.net/odata/component",
  media: "https://infoboard.decentra.net/odata/media",
  getMedia: "https://infoboard.decentra.net/odata/GetMedia",
  componentMedia: "https://infoboard.decentra.net/odata/componentMedia",
  getComponentByGuid: "https://infoboard.decentra.net/odata/GetComponentext",
  getMediaExt: "https://infoboard.decentra.net/odata/GetMediaExt",
  infobardExt: "https://infoboard.decentra.net/odata/GetBoardExt",
  deviceList: "https://infoboard.decentra.net/odata/device",


  //AUTH
  userList: "https://auth.decentra.net/odata/user"
};



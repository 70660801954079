import React, { useRef, useState, useEffect, useMemo } from "react";
import "./ClockComponent.css";
import { Cross } from "hamburger-react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import {
  fetchData,
  patchData,
  getTimeForTimezone,
  notifyError,
  isColorLight,
} from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import ClockModal from "./ClockModal";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";

function ClockContainer({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName
}) {
  const sessionToken = localStorage.getItem("bearerToken");
  const [clockData, setClockData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const nodeRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isZindex, setIsZindex] = useState(false);
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    if (componentInfo?.timeZones?.length > 0) {
      const timer = setInterval(() => {
        setTime(new Date());
      }, 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [componentInfo?.timeZones?.length]);

  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}/${id}`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        setComponentInfo(settingsArray);
        setClockData(data);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      });
  }, [id, sessionToken, selectedComponentId, guid]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });
      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setClockData(data);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    cityNameText: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.cityNameText
          : (componentInfo.cityNameText * initialSize.height) /
            window.innerHeight,
      [componentInfo.cityNameText, initialSize.height, selectedComponentId]
    ),
    cityTimeText: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.cityTimeText
          : (componentInfo.cityTimeText * initialSize.height) /
            window.innerHeight,
      [componentInfo.cityTimeText, initialSize.height, selectedComponentId]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );

  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    const newWidth = Math.round((size.width / window.innerWidth) * 24);
    const newHeight = Math.round((size.height / window.innerHeight) * 24);
    patchSettingsToBackend2(
      {
        width: newWidth,
        height: newHeight,
      },
      clockData.SettingsJson,
      id
    );
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        clockData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(data.SettingsJson);
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.clockBackgroundColor);

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              className={`${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              }   opacity-${componentInfo.opacity}`}
              ref={nodeRef}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
              style={{
                position: "fixed",
                top: 0,
                left: 0,

                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                backgroundColor: componentInfo.clockBackgroundColor,
                borderRadius: isRounded ? "10px" : "",
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
              }}
            >
              {!selectedComponentId && (
                <div
                  className="hamburger"
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    zIndex: 1,
                    color: result ? "black" : "white",
                  }}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#clockModal${id}`}
                >
                  <Cross size={25} toggled={false} />
                </div>
              )}
              <ul
                className="cities"
                style={{
                  height: "100%",
                }}
              >
                <></>
                {componentInfo &&
                componentInfo.timeZones &&
                componentInfo.timeZones.length > 0 ? (
                  componentInfo.timeZones.map((timezone, index) => (
                    <div
                      key={index}
                      className="city-zone"
                      style={{
                        backgroundColor: componentInfo.clockBackgroundColor,
                        color: componentInfo.clockTextColor,
                        borderRadius: `${componentInfo.clockRadius}%`,
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {componentInfo.label && (
                        <div
                          className="city-name"
                          style={{
                            fontSize: initialFontSize.cityNameText + "vh",
                          }}
                        >
                          {componentInfo.label}
                        </div>
                      )}

                      <div
                        className="city-time"
                        style={{
                          fontSize: initialFontSize.cityTimeText + "vh",
                        }}
                      >
                        {getTimeForTimezone(timezone, time)}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="city-zone"
                    style={{
                      backgroundColor: componentInfo.clockBackgroundColor,
                      color: componentInfo.clockTextColor,
                      borderRadius: `${componentInfo.clockRadius}%`,
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: initialFontSize.cityTimeText + "vh",
                      }}
                    >
                      ADD A CLOCK
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
          {!selectedComponentId && (
      <ClockModal
        id={id}
        patchSettingsToBackend2={patchSettingsToBackend2}
        clockData={clockData}
        componentInfo={componentInfo}
        positions={positions}
        deleteData={deleteData}
        componentName={componentName}
          
      />
          )}
    </>
  );
}

export default React.memo(ClockContainer);

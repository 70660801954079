import { toast } from "react-toastify";
import { ApiUrls } from "./API";




export const fetchData = async (api, sessionToken) => {
  try {
    const response = await fetch(api, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    });
    return await response.json();
  } catch (error) {
    notifyError("Error:", error)
  }
};

export const patchData = async (api, sessionToken, data) => {
  try {
    const response = await fetch(api, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Failed to patch data");
    }
  } catch (error) {
    notifyError("Error:", error)
    throw error;
  }
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const getTimeForTimezone = (timezone, time) => {
  return new Intl.DateTimeFormat("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: timezone.value,
  }).format(time);
};

export const notifyError = (message) =>
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  export const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

export const handleDeleteMedia = (
  mediaId,
  id,
  sessionToken,
  setList,
) => {
  fetch(`${ApiUrls.componentMedia}/${mediaId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to delete Media");
      }
      fetchData(`${ApiUrls.component}(${id})?$expand=media`, sessionToken).then(
        (data) => {
          setList(data.Media);
        }
      );

    })
    .catch((error) => {
      notifyError("Error deleting Media", error);
    });
};

export const saveSortOrderToBackend = async (pdf, setPdfMeidaList, id, sessionToken) => {
  try {
    const payload = pdf.map((item) => ({
      MediaID: item.ID,
      SortOrder: item.SortOrder,
    }));
    for (const item of payload) {
      const response = await fetch(
        `${ApiUrls.componentMedia}/${item.MediaID}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify({ SortOrder: item.SortOrder }),
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to update sort order for media ${item.ID}`);
      }
    }
    const updatedData = await fetchData(
      `${ApiUrls.component}(${id})?$expand=media`,
      sessionToken
    );
    const mediaList = updatedData.Media || updatedData.media;
    const currentDate = new Date();
    const filteredMediaList = mediaList.filter(mediaItem => {

      if (mediaItem.Expiry) {
        const expiryDate = new Date(mediaItem.Expiry);
        return expiryDate > currentDate;
      }
      return true;
    });
    setPdfMeidaList(filteredMediaList);
  } catch (error) {
    notifyError("Error updating sort order:", error);
  }
};

export const handleDeleteFile = async (mediaID, sessionToken, id, setPdfMeidaList, setComponentMedia) => {
  const API = `${ApiUrls.componentMedia}/${mediaID}`;
  try {
    const response = await fetch(API, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const updatedData = await fetchData(
        `${ApiUrls.component}(${id})?$expand=media`,
        sessionToken
      );
      const sortedFiles = updatedData.Media.map((file, index) => ({
        ...file,
        SortOrder: index + 1,
      }));
      fetchData(`${ApiUrls.componentMedia}`, sessionToken)
      .then((data) => {
        setComponentMedia(data.value);
      })
      .catch((error) => {
        notifyError("Failed to fetch data", error);
      });
      saveSortOrderToBackend(sortedFiles, setPdfMeidaList, id, sessionToken);
    } else {
      throw new Error("Failed to delete image");
    }
  } catch (error) {
    notifyError("Error deleting image:", error);
  }
};



export function hexToRgb(hex) {
  let r = 0, g = 0, b = 0;
  if (hex?.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  }
  else if (hex?.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return { r, g, b };
}

export function luminance(r, g, b) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function isColorLight(hex) {
  const { r, g, b } = hexToRgb(hex);
  const lum = luminance(r, g, b);
  return lum > 0.5;
}
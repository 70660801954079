import React, { useState, useRef, useEffect, useMemo } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import "./SlidingText.css";
import { Cross } from "hamburger-react";
import {
  fetchData,
  notifyError,
  patchData,
  isColorLight,
} from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { SlidingTextModal } from "./SlidingTextModal";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";

export const SlidingText = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const nodeRef = useRef(null);
  const [slidingTextData, setSlidingTextData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const [slidingAnimation, setSlidingAnimation] = useState(null)
  const [slidingText, setSlidingText] = useState("")
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [isDragging, setIsDragging] = useState(false);
  const [isZindex, setIsZindex] = useState(false);
  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}(${id})?$expand=media`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        setComponentInfo(settingsArray);
        setSlidingTextData(data);
        setSlidingAnimation(settingsArray.animationDuration)
        setSlidingText(settingsArray.slidingText)
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      });
  }, [id, sessionToken, selectedComponentId, guid]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });

      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setSlidingTextData(data);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data:", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    textSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.textSize
          : (componentInfo.textSize * initialSize.height) / window.innerHeight,
      [componentInfo.textSize, initialSize.height, selectedComponentId]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );
  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    const newWidth = Math.round((size.width / window.innerWidth) * 24);
    const newHeight = Math.round((size.height / window.innerHeight) * 24);
    patchSettingsToBackend2(
      { width: newWidth, height: newHeight },
      slidingTextData.SettingsJson,
      id
    );
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        slidingTextData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(data.SettingsJson);
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.color);

  const styles = {
    hamburger: {
      position: "absolute",
      top: initialSize.height < 50 ? null : 10,
      right: 10,
      zIndex: 1,
      color: result ? "black" : "white",
    },
  };

  useEffect(() => {
    if (selectedComponentId) {
      const interval = setInterval(() => {
        const API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
        fetchData(API, sessionToken).then((data) => {
          const settingsArray = JSON.parse(data.settingsJson || data.SettingsJson);
          setSlidingAnimation(settingsArray.animationDuration)
          setSlidingText(settingsArray.slidingText)
        }).catch((error) => {
          console.error("Failed to fetch data:", error);
        });
      }, componentInfo.animationDuration * 1000); 
  
      return () => clearInterval(interval); 
    }
  }, [componentInfo.animationDuration, selectedComponentId, guid, sessionToken]);

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              className={` ${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              }  d-flex justify-content-center align-items-center  opacity-${
                componentInfo.opacity
              }`}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                backgroundColor: `${componentInfo.color}`,
                borderRadius: isRounded ? "10px" : "",
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
              }}
              ref={nodeRef}
            >
              {!selectedComponentId && (
                <div
                  style={styles.hamburger}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#slidingTextModal${id}`}
                >
                  <Cross size={25} toggled={false} />
                </div>
              )}
              <div className="marquee">
                <span
                className={`${componentInfo.boldText && "fw-bolder"}`}
                  style={{
                    fontSize: `${initialFontSize.textSize}vh`,
                    color: `${componentInfo.textColor}`,
                    animationDuration: selectedComponentId ? `${slidingAnimation}s` : `${componentInfo.animationDuration}s`,
                  }}
                >
                  { selectedComponentId ? slidingText : componentInfo.slidingText} &nbsp;
                </span>
              </div>
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
          {!selectedComponentId && (
      <SlidingTextModal
        id={id}
        patchSettingsToBackend2={patchSettingsToBackend2}
        slidingTextData={slidingTextData}
        componentInfo={componentInfo}
        positions={positions}
        deleteData={deleteData}
        componentName={componentName}
      />
          )}
    </>
  );
};

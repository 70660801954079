import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";

export const AdminTopbar = ({
  patchSettingsToBackend,
  bgColor,
  setBgColor,
  infoBoardSettings,
  isVisible,
  deleteData,
  setIsVisible,
}) => {
  const [showColor, setShowColor] = useState(false);
  const { isRounded, setIsRounded, selectedComponents } =
    useSettingsInfoBoard();
  const nodeRef = useRef(null);
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [buttonWork, setButtonWork] = useState(true);
  const [selectedID, setSelectedID] = useState(null);

  const { bringComponentToTop } = useSettingsInfoBoard(selectedID);
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl, {
        delay: { show: 3000, hide: 100 },
      });
    });
  }, []);

  useEffect(() => {
    if (infoBoardSettings) {
      setIsRounded(infoBoardSettings.isRoundedComponents);
    }
  }, [infoBoardSettings, setIsRounded]);

  useEffect(() => {
    if (shouldNavigate) {
      navigate("/dashboards");
    }
  }, [isVisible, shouldNavigate, navigate]);

  useEffect(() => {
    if (selectedID !== null) {
      bringComponentToTop(selectedID);
      setSelectedID(null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedID]);

  const handleOnBackgroundChange = (color) => {
    const newColor = color.hex;
    setBgColor(newColor);
    patchSettingsToBackend({ backgroundColor: newColor });
  };

  const handleToggleRounded = () => {
    setIsRounded((prevIsRounded) => !prevIsRounded);
    patchSettingsToBackend({ isRoundedComponents: !isRounded });
  };

  const goBack = () => {
    setIsVisible(false);
    setShouldNavigate(true);
  };

  const setID = (id) => {
    setSelectedID(id);
  };

  return (
    <Draggable axis="x" nodeRef={nodeRef}>
      <div
        className={`d-flex justify-content-end me-5 bg-secondary row `}
        style={{
          width: "150px",
          position: "absolute",
          top: isVisible ? 0 : "-90px",
          right: 50,
          zIndex: 101,
          borderBottomLeftRadius: isRounded ? "10px" : "",
          borderBottomRightRadius: isRounded ? "10px" : "",
          transition: "top 0.5s ease-in-out",
        }}
        ref={nodeRef}
      >
        <nav
          className="navbar navbar-expand-lg"
          onMouseDown={() => setButtonWork(false)}
          onMouseUp={() => setButtonWork(true)}
        >
          <div className="" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <span
                  className="nav-link "
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i
                    style={{ fontSize: "2rem" }}
                    className="bi bi-code-slash text-white text-white nav-item dropdown"
                  ></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-start "
                  style={{ borderRadius: isRounded ? "10px" : "0" }}
                >
                  {selectedComponents.length === 0 ? (
                    <li>
                      <span className="dropdown-item list-group-item">
                        The List is empty
                      </span>
                    </li>
                  ) : (
                    selectedComponents.map((component, index) => (
                      <li
                        key={index}
                        className="list-group-item d-flex  align-items-center"
                        style={{cursor:"pointer"}}
                      >
                        <span className="dropdown-item">{component.name}</span>
                        <span
                          className="badge text-bg-danger p-1 rounded-0 text-white m-2"
                          onClick={() => deleteData(component.id)}
                        >
                          <i className="bi bi-trash3"></i>
                        </span>
                        <span
                          className="badge text-bg-secondary me-1 p-1 rounded-0 text-white"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Click to get focus on component"
                          onClick={() => setID(component.id)}
                        >
                          <i className="bi bi-binoculars"></i>
                        </span>
                      </li>
                    ))
                  )}
                </ul>
              </li>

              <li className="nav-item dropdown ">
                <span
                  className="nav-link"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#newComponent"
                >
                  <i
                    style={{ fontSize: "2rem" }}
                    className="bi bi-plus-circle-dotted text-white nav-item dropdown"
                  ></i>
                </span>
              </li>

              <li className="nav-item dropdown"   style={{cursor:"pointer"}}>
                <span
                  className="nav-link"
                  role="button"
                  data-bs-toggle={buttonWork && "dropdown"}
                  aria-expanded="false"
                >
                  <i
                    style={{ fontSize: "2rem" }}
                    className="bi bi-gear text-white nav-item dropdown"
                  ></i>
                </span>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  style={{ borderRadius: isRounded ? "10px" : "0" }}
                >
                  <li onClick={handleToggleRounded}>
                    <span className="dropdown-item">
                      {isRounded
                        ? "Components without radius"
                        : "Components with radius"}
                    </span>
                  </li>
                  <li onClick={(event) => event.stopPropagation()}>
                    {showColor ? (
                      <span className="dropdown-item">
                        <SketchPicker
                          color={bgColor}
                          onChangeComplete={handleOnBackgroundChange}
                        />
                        <button
                          className="btn btn-info btn-sm w-100"
                          onClick={() => setShowColor(false)}
                        >
                          CLOSE
                        </button>
                      </span>
                    ) : (
                      <span
                        className="dropdown-item"
                        onClick={() => setShowColor((prev) => !prev)}
                      >
                        Background Color
                      </span>
                    )}
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#imageModal"
                    >
                      Set background image
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#mediaModal"
                    >
                      Media List
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#saveTemplate"
                    >
                      Save as custom template
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item" onClick={goBack}>
                      Back to menu
                    </span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        <div className="d-flex justify-content-center align-items-center">
          <i className="bi bi-arrow-bar-down"></i>
        </div>
      </div>
    </Draggable>
  );
};

import { DragAndDrop } from "../DragAndDrop";

export const BackgroundImageModal = ({
  patchSettingsToBackend,
  infoBoardSettings,
  setBackgroundImage,
  showbackgroundImage,
  setShowbackgroundImage,
}) => {
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleDropFiles = async (acceptedFiles) => {
    const newMediaItems = await Promise.all(
      acceptedFiles.map(async (file) => {
        const base64 = await fileToBase64(file);
        const byteString = atob(base64);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          intArray[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: file.type });
        const imageUrl = URL.createObjectURL(blob);
        setBackgroundImage(imageUrl);

        return {
          Name: file.name,
          Type: file.type,
          Base64: base64,
        };
      })
    );
    patchSettingsToBackend({ newMediaItems });
  };

  const accept = {
    "image/png": [".png"],
    "image/jpeg": [".jpeg", ".jpg"],
  };

  const deleteImage = (index) => {
    const updatedList = [...infoBoardSettings.newMediaItems];
    updatedList.splice(index, 1);
    patchSettingsToBackend({ newMediaItems: updatedList });
    setBackgroundImage("");
  };

  const toggleActive = () => {
    setShowbackgroundImage((prev) => !prev);
    patchSettingsToBackend({ showImage: !showbackgroundImage });
  };

  return (
    <div>
      <div
        className="modal fade"
        data-bs-backdrop="static"
        id="imageModal"
        tabIndex="-1"
        aria-labelledby="imageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="imageModalLabel">
                Set background image
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ol
                className="list-group rounded-0 scroll mb-2"
                style={{
                  overflowY:
                    infoBoardSettings.newMediaItems?.length >= 8
                      ? "scroll"
                      : "",
                  height:
                    infoBoardSettings.newMediaItems?.length >= 8 ? "600px" : "",
                }}
              >
                {infoBoardSettings?.newMediaItems &&
                  infoBoardSettings?.newMediaItems.map((item, index) => (
                    <div
                      key={index}
                      className="list-group-item "
                      aria-current="true"
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <div className="mb-1">{item.Name}</div>
                        <small>
                          {" "}
                          <span
                            className="badge text-bg-danger p-2 rounded-0 text-white mt-2 ms-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteImage(index)}
                          >
                           <i className="bi bi-trash3" style={{ fontSize: "14px" }}></i>
                          </span>
                        </small>
                      </div>
                      <div className="mb-1 text-start">
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                            htmlFor={`toggleActive${index}`}
                          >
                            {showbackgroundImage ? "Hide image" : "Show image"}
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`toggleActive${index}`}
                            checked={showbackgroundImage || false}
                            onChange={() => toggleActive()}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                {!infoBoardSettings.newMediaItems ||
                !infoBoardSettings.newMediaItems.length ? (
                  <div className="text-start ms-3">No background image</div>
                ) : null}
              </ol>
              <span className="mt-2">
                <DragAndDrop onDropFiles={handleDropFiles} accept={accept} />
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState, useRef, useEffect, useMemo } from "react";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { Cross } from "hamburger-react";
import {
  fetchData,
  notifyError,
  patchData,
  isColorLight,
} from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { ChangeInfoBoardModal } from "./ChangeInfoBoardModal";

export const ChangeInfoBoard = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const nodeRef = useRef(null);
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [changeData, setChangeData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isZindex, setIsZindex] = useState(false);

  const [selectedValue, setSelectedValue] = useState(null);
  const [deviceList, setDeviceList] = useState([]);

  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}/${id}`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        setComponentInfo(settingsArray);
        setChangeData(data);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      });
  }, [id, sessionToken, selectedComponentId, guid]);

  useEffect(() => {
    fetchData(ApiUrls.deviceList, sessionToken)
      .then((data) => {
        setDeviceList(data.value);
      })
      .catch((error) => {
        notifyError("Error fetching infoboard list:", error);
      });
  }, [sessionToken, setDeviceList]);

  
  const idForChange = deviceList
    .filter((item) => item.SubscriptionBoardID === changeData.BoardID)
    .map((item) => item.ID
    );

  const updateDevice = async () => {
    const patchUrl = `${ApiUrls.deviceList}/${idForChange}`;
    const patchData = {
      SubscriptionBoardID: componentInfo.idForInfoboard,
    };
    const response = await fetch(patchUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(patchData),
    });
    if (!response.ok) {
      notifyError("Error updating device");
    }
  };

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });

      console.log(existingSettings);
      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setChangeData(data);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data:", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    textSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.textSize
          : (componentInfo.textSize * initialSize.height) / window.innerHeight,
      [componentInfo.textSize, initialSize.height, selectedComponentId]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );
  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    if (!selectedComponentId) {
      const newWidth = Math.round((size.width / window.innerWidth) * 24);
      const newHeight = Math.round((size.height / window.innerHeight) * 24);
      patchSettingsToBackend2(
        {
          width: newWidth,
          height: newHeight,
        },
        changeData.SettingsJson,
        id
      );
    }
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        changeData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(data.SettingsJson);
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.color);

  const styles = {
    hamburger: {
      position: "absolute",
      top: initialSize.height < 50 ? null : 10,
      right: 10,
      zIndex: 1,
      color: result ? "black" : "white",
    },
  };

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              className={`${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              } d-flex justify-content-center align-items-center opacity-${
                componentInfo.opacity
              }`}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                backgroundColor: `${componentInfo.color}`,
                borderRadius: isRounded ? "10px" : "",
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
                fontSize: `${initialFontSize.textSize}vh`,
              }}
              ref={nodeRef}
            >
              {!selectedComponentId && (
                <div
                  style={styles.hamburger}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#changeInfo${id}`}
                >
                  <Cross size={25} toggled={false} />
                </div>
              )}
              {componentInfo.idForInfoboard ? (
                <button
                  className="btn btn-sm shadow-0"
                  style={{ color: componentInfo.textColor }}
                  onClick={updateDevice}
                >
                  Click to Redirect to infoboard: {componentInfo.nameForInfoboard}
                </button>
              ) : (
                <div
                  className="btn btn-sm shadow-0"
                  style={{ color: componentInfo.textColor }}
                >
                  Choose infoboard to redirect to
                </div>
              )}
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
      {!selectedComponentId && (
        <ChangeInfoBoardModal
          id={id}
          patchSettingsToBackend2={patchSettingsToBackend2}
          changeData={changeData}
          componentInfo={componentInfo}
          positions={positions}
          deleteData={deleteData}
          componentName={componentName}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
        />
      )}
    </>
  );
};

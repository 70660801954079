const AuthDataService = {
  getSessionToken: () => localStorage.getItem("bearerToken"),

  bytesToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
  },

  async passwordHash(input) {
    const _salt = new TextEncoder().encode("¨c*03+Jbj&s4s");
    const _password = new TextEncoder().encode(input);
    let _buffer = new Uint8Array(_salt.length + _password.length);
    _buffer.set(_salt);
    _buffer.set(_password, _salt.length);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", _buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((item) => item.toString(16).toUpperCase().padStart(2, "0"))
      .join("");
    return hash;
  },

  async generateHash(_nonce, _date, _password) {
    let _buffer = new Uint8Array(
      _nonce.length + _date.length + _password.length
    );
    _buffer.set(_nonce);
    _buffer.set(_date, _nonce.length);
    _buffer.set(_password, _nonce.length + _date.length);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", _buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return this.bytesToBase64(hashArray);
  },

  async signIn(username, password) {
    const _nonce = new Uint8Array(16);
    crypto.getRandomValues(_nonce);
    const date = new Date();
    const _date = new TextEncoder().encode(date.toISOString());
    const _passwordHash = await this.passwordHash(password);
    const _password = new TextEncoder().encode(_passwordHash);

    try {
      const passwordDigest = await this.generateHash(_nonce, _date, _password);
      const userData = {
        Username: username,
        Nonce: this.bytesToBase64(_nonce),
        TimeStamp: date.toISOString(),
        PasswordDigest: passwordDigest,
      };

      const response = await fetch("https://auth.decentra.net/identity", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        const sessionToken = responseData.accessToken;
        const refreshToken = responseData.refreshToken
        localStorage.setItem("bearerToken", sessionToken);
        localStorage.setItem("refreshToken", refreshToken)
       // console.log(refreshToken)
        console.log(sessionToken)
        return sessionToken;
      } else {
        throw new Error("Login failed");
      }
    } catch (error) {
      throw new Error("Error logging in");
    }
  },
};

export default AuthDataService;

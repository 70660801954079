import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { useState, useEffect } from "react";
import { fetchData } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";

export const MediaLogModal = () => {
  const [userList, setUserList] = useState([]);
  const { mediaList } = useSettingsInfoBoard();
  const sessionToken = localStorage.getItem("bearerToken");

  useEffect(() => {
    fetchData(ApiUrls.userList, sessionToken).then((data) => {
      setUserList(data.value);
    });
  }, [sessionToken]);

  const getUserName = (userID) => {
    const user = userList.find((user) => user.ID === userID);
    return user ? user.Name : "Unknown User";
  };

  const formatDate = (isoDate) => {
    const dateObj = new Date(isoDate);
    return `${dateObj.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    })} ${dateObj.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    })}`;
  };



  return (
    <div
      className="modal fade"
      id="mediaLogModal"
      tabIndex="-1"
      aria-labelledby="mediaLogModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog rounded-0">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h5 className="modal-title" id="mediaLogModalLabel">
              Media log list
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div
              className="list-group list-group-flush text-start"
              style={{ fontSize: "0.8rem" }}
            >
              {mediaList &&
                mediaList.map((media) => (
                  <div
                    key={media.ID}
                    className="list-group-item li"
                    aria-current="true"
                  >
                    <div>
                      <span className="fw-bolder">Name:</span> {media.Name}
                    </div>
                    <div>
                      <span className="fw-bolder">Type:</span> {media.Type}
                    </div>
                    <div>
                      <span className="fw-bolder">Added by:</span>{" "}
                      {getUserName(media.CreatedUserID)}
                    </div>
                    <div>
                      <span className="fw-bolder">Added on:</span>{" "}
                      {formatDate(media.Created)}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm rounded-0"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import { ApiUrls } from "../../Utils/API";
import { fetchData, notifyError } from "../../Utils/ReusableFunctions";
import { useNavigate } from "react-router-dom";
import { DeleteInfoboardModal } from "../ModalComponents/DeleteInfoboardModal";
import { NewInfoBoardModal } from "../ModalComponents/NewInfoboardModal";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { notifySuccess } from "../../Utils/ReusableFunctions";

export const DashboardsList = ({
  setInfoBoardList,
  isLoading,
  infoBoardList,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { setSelectedComponents } = useSettingsInfoBoard();
  const [selectedInfoBoard, setSelectedInfoBoard] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const navigate = useNavigate();

  const handleDashboardClick = (dashboardId) => {
    navigate(`/dashboard/${dashboardId}`);
  };

  const openDeleteModal = (infoBoard) => {
    setSelectedInfoBoard(infoBoard);
    setDeleteConfirmation("");
  };

  const handleDeleteInfoBoard = () => {
    if (!selectedInfoBoard) return;

    fetch(`${ApiUrls.infobard}${selectedInfoBoard.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete InfoBoard");
        }

        fetchData(ApiUrls.infobard, sessionToken).then((data) => {
          setInfoBoardList(data.value);
        });

        fetchData(ApiUrls.component, sessionToken).then((data) => {
          const filteredComponents = data.value.filter(
            (component) =>
              String(component.BoardID) === String(selectedInfoBoard.id)
          );
          setSelectedComponents(
            filteredComponents.map((component) => ({
              id: component.ID,
              type: JSON.parse(component.SettingsJson).type,
              settings: component.SettingsJson,
            }))
          );
        });
      })
      .catch((error) => {
        notifyError("Failed to delete infoBoard", error);
      });
  };

  const refreshInfoboard = async (itemID, versionId) => {
    const patchUrl = `${ApiUrls.infobard}/${itemID}`;
    const patchData = {
      Version: versionId === null ?  1 : versionId + 1
    };
    console.log(patchData)
    const response = await fetch(patchUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(patchData),
    });
    if (!response.ok) {
      notifyError("Error updating device");
    }

    fetchData(ApiUrls.infobard, sessionToken).then((data) => {
      setInfoBoardList(data.value)
      
    });
    notifySuccess("Infoboard refreshed successfully");
  };

  return (
    <>
      {isLoading ? (
        <>Loading...</>
      ) : infoBoardList.length === 0 ? (
        <div className="text-center">
          <p className="lead">NO INFOBOARD ASSOCIATED WITH THIS USER</p>
          <button
            className="btn btn-secondary w-100 btn-lg"
            data-bs-toggle="modal"
            data-bs-target="#newInfoBoard"
          >
            ADD NEW INFOBOARD
          </button>
          <NewInfoBoardModal setInfoBoardList={setInfoBoardList} />
        </div>
      ) : (
        <div className="bg-transparent " >
          <h5 className="text-start fw-bold text-white"> Infoboards </h5>

          <ol className="list-group list-group-numbered rounded-0 w-100 bg-transparent " >
            {infoBoardList.map((item, index) => (
              <li
                className="list-group-item d-flex justify-content-between align-items-start list-group-item-action text-black"
                key={index}
            
              >
                <div
                  className="ms-2 text-start w-100"
                  onClick={() => handleDashboardClick(item.ID)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="fw-bold text-truncate" style={{width:"90%"}}>{item.Name}</div>
                </div>
                <span
                  className="badge bg-info me-1 rounded-0"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    refreshInfoboard(item.ID, item.Version)
                  }
                >
                  <i
                    className="bi bi-arrow-clockwise"
                    style={{ fontSize: "14px" }}
                  ></i>
                </span>
                <span
                  className="badge bg-danger rounded-0"
                  data-bs-toggle="modal"
                  data-bs-target="#removeDashboard"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    openDeleteModal({ id: item.ID, name: item.Name })
                  }
                >
                  <i className="bi bi-trash3" style={{ fontSize: "14px" }}></i>
                </span>
              </li>
            ))}
          </ol>
          <button
            className="btn btn-secondary w-100 mt-2 rounded-0"
            data-bs-toggle="modal"
            data-bs-target="#newInfoBoard"
          >
            ADD NEW INFOBOARD
          </button>
          <DeleteInfoboardModal
            selectedInfoBoard={selectedInfoBoard}
            deleteConfirmation={deleteConfirmation}
            setDeleteConfirmation={setDeleteConfirmation}
            handleDeleteInfoBoard={handleDeleteInfoBoard}
          />
          <NewInfoBoardModal setInfoBoardList={setInfoBoardList} />
        </div>
      )}
    </>
  );
};

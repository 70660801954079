import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";
import { SketchPicker } from "react-color";
import Calendar from "react-calendar";
import { menuFormatDate } from "../Date/DateInfo";
import "react-calendar/dist/Calendar.css";

export const OrderMenuModal = ({
  id,
  deleteData,
  componentInfo,
  patchSettingsToBackend2,
  orderMenuData,
  componentName,
  menu,
  setMenu,
}) => {
  const [isDraggable, setIsDraggable] = useState(true);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const nodeRef = useRef(null);
  const [settings, setSettings] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [menuTitle, setMenuTitle] = useState("");
  const [menuDescription, setMenuDescription] = useState("");
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectRange, setSelectRange] = useState(false);
  const [allDatesInRange, setAllDatesInRange] = useState([]);

  const [timeouts, setTimeouts] = useState({
    slidingText: null,
    textSize: null,
    height: null,
    width: null,
    xPosition: null,
    yPosition: null,
    opacity: null,
    info: null,
    zIndex: null,
  });

  useEffect(() => {
    if (componentInfo) {
      setSettings({
        color: componentInfo.color,
        textColor: componentInfo.textColor,
        opacity: componentInfo.opacity,
        width: componentInfo.width,
        height: componentInfo.height,
        textSize: componentInfo.textSize,
        xPosition: componentInfo.xPosition,
        yPosition: componentInfo.yPosition,
        zIndex: componentInfo.zIndex,
        eventTitleTextSize: componentInfo.eventTitleTextSize,
      });
    }
  }, [componentInfo]);

  const updateTimeout = (key, value) => {
    setTimeouts((prevTimeouts) => ({
      ...prevTimeouts,
      [key]: value,
    }));
  };

  useEffect(() => {
    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [timeouts]);

  const handleOnBackgroundChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, color: newColor });
    patchSettingsToBackend2(
      {
        color: newColor,
      },
      orderMenuData.SettingsJson,
      id
    );
  };

  const handleOnTextColorChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, textColor: newColor });
    patchSettingsToBackend2(
      {
        textColor: newColor,
      },
      orderMenuData.SettingsJson,
      id
    );
  };

  const handleSettingChange = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    clearTimeout(timeouts[key]);
    updateTimeout(
      key,
      setTimeout(() => {
        patchSettingsToBackend2(
          { [key]: value },
          orderMenuData.SettingsJson,
          id
        );
      }, 500)
    );
  };

  const handleInputChange = (key, value, maxLimit) => {
    let parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      parsedValue = 2;
    } else if (parsedValue > maxLimit) {
      parsedValue = maxLimit;
    }
    handleSettingChange(key, parsedValue);
  };

  const menuDate = (date) => {
    setSelectedDate(date);
  };

  const menuTitleUpdate = (event) => {
    setMenuTitle(event.target.value);
  };

  const menuDescriptionUpdate = (event) => {
    setMenuDescription(event.target.value);
  };

  const createMenuItem = () => {
    if ((selectedDate || allDatesInRange.length > 0) && menuTitle) {
      const newMenu = [...(componentInfo.menu || [])];
      const addMenuItemForDate = (date) => {
        const formattedSelectedDate = new Date(date)
          .toISOString()
          .split("T")[0];
        const existingMenuIndex = newMenu.findIndex(
          (menu) =>
            new Date(menu.date).toISOString().split("T")[0] ===
            formattedSelectedDate
        );

        if (existingMenuIndex !== -1) {
          newMenu[existingMenuIndex].items.push({
            id: Math.random() + 2,
            title: menuTitle,
            description: menuDescription,
          });
        } else {
          newMenu.push({
            date: new Date(date).toISOString(),
            id: Math.random(),
            items: [
              {
                id: Math.random() + 1,
                title: menuTitle,
                description: menuDescription,
              },
            ],
          });
        }
      };

      if (allDatesInRange.length > 0) {
        allDatesInRange.forEach((date) => addMenuItemForDate(date));
      } else if (selectedDate) {
        addMenuItemForDate(selectedDate);
      }

      patchSettingsToBackend2(
        {
          menu: newMenu,
        },
        orderMenuData.SettingsJson,
        id
      );

      setSelectedDate(null);
      setAllDatesInRange([]);
      setMenuTitle("");
      setMenuDescription("");
    }
  };

  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  const deleteDateMenu = (index) => {
    const updatedList = [...componentInfo.menu];
    updatedList.splice(index, 1);
    patchSettingsToBackend2(
      {
        menu: updatedList,
      },
      orderMenuData.SettingsJson,
      id
    );
  };

  const selectedMenu = menu.find((m) => m.date === selectedDay)?.items || [];

  const handleDateChange = (value) => {
    if (Array.isArray(value)) {
      const [start, end] = value;
      const startDate = new Date(start.setHours(0, 0, 0, 0));
      const endDate = new Date(end.setHours(0, 0, 0, 0));

      const datesInRange = getDatesInRange(startDate, endDate);
      setAllDatesInRange(datesInRange);
      console.log(datesInRange);
    } else {
      setSelectedDate(new Date(value.setHours(0, 0, 0, 0)));
    }
  };
  const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dates;
  };

  return (
    <Draggable nodeRef={nodeRef} disabled={!isDraggable}>
      <div
        className="modal"
        id={`orderMenuModal${id}`}
        tabIndex="-1"
        aria-labelledby={`orderMenuModal${id}`}
        aria-hidden="true"
        data-bs-backdrop="static"
        ref={nodeRef}
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h4 className="modal-title fs-5" id={`orderMenuModal${id}`}>
                Properties for {componentName}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              onMouseEnter={() => setIsDraggable(false)}
              onMouseLeave={() => setIsDraggable(true)}
            >
              <ol className="list-group list-group-flush text-start">
                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className=" me-auto">Opacity</div>
                  <span className="me-2">{settings.opacity}%</span>
                  <span>
                    <input
                      type="range"
                      className="form-range"
                      min="0"
                      max="100"
                      step="25"
                      id="dateRange3"
                      value={settings.opacity || 0}
                      onChange={(e) =>
                        handleSettingChange("opacity", parseInt(e.target.value))
                      }
                    />
                  </span>
                </li>

                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="row w-100">
                    <div className="col input-group">
                      <span
                        className="input-group-text border-end-0"
                        id="widthDate"
                      >
                        width
                      </span>
                      <input
                        name="width"
                        type="number"
                        max={24 - settings.xPosition || 0}
                        className="form-control"
                        aria-label="width"
                        aria-describedby="width"
                        onChange={(e) =>
                          handleInputChange(
                            "width",
                            e.target.value,
                            24 - settings.xPosition
                          )
                        }
                        value={settings.width || ""}
                      />
                    </div>
                    <div className="col input-group">
                      <span className="input-group-text" id="heightDate">
                        height
                      </span>
                      <input
                        name="height"
                        type="number"
                        min={2}
                        className="form-control"
                        max={24 - settings.yPosition || 0}
                        aria-label="height"
                        aria-describedby="height"
                        onChange={(e) =>
                          handleInputChange(
                            "height",
                            e.target.value,
                            24 - settings.yPosition
                          )
                        }
                        value={settings.height || ""}
                      />
                    </div>
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-start">
                  <div className="row w-100">
                    <div className="col input-group">
                      <span className="input-group-text" id="X">
                        X
                      </span>
                      <input
                        name="X"
                        min={0}
                        max={24 - settings.width || 0}
                        type="number"
                        className="form-control"
                        aria-label="X"
                        aria-describedby="X"
                        onChange={(e) =>
                          handleInputChange(
                            "xPosition",
                            e.target.value,
                            24 - settings.width
                          )
                        }
                        value={settings.xPosition || 0}
                      />
                    </div>
                    <div className="col input-group">
                      <span className="input-group-text border-end-0" id="Y">
                        Y
                      </span>
                      <input
                        name="Y"
                        type="number"
                        className="form-control"
                        aria-label="Y"
                        min={0}
                        max={24 - settings.height || 0}
                        aria-describedby="Y"
                        onChange={(e) =>
                          handleInputChange(
                            "yPosition",
                            e.target.value,
                            24 - settings.height
                          )
                        }
                        value={settings.yPosition || 0}
                      />
                    </div>
                  </div>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className=" pt-1 me-auto">Text size</div>
                  <input
                    name="textsizedate"
                    type="number"
                    className="form-control w-25 me-4"
                    value={settings.textSize || ""}
                    onChange={(e) =>
                      handleSettingChange("textSize", parseInt(e.target.value))
                    }
                  />
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <div className=" pt-1 me-auto">zIndex</div>
                  <input
                    name="textsizedate"
                    type="number"
                    className="form-control w-25 me-4"
                    value={settings.zIndex || 0}
                    onChange={(e) =>
                      handleSettingChange("zIndex", parseInt(e.target.value))
                    }
                  />
                </li>
              </ol>
              <div className="accordion accordion-flush" id="accordionMenu">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseMenu"
                      aria-expanded="true"
                      aria-controls="collapseMenu"
                    >
                      Background Color
                    </button>
                  </h2>
                  <div
                    id="collapseMenu"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionMenu"
                  >
                    <div className="accordion-body">
                      <SketchPicker
                        color={settings.color}
                        onChangeComplete={handleOnBackgroundChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourMenu"
                      aria-expanded="true"
                      aria-controls="collapseFourMenu"
                    >
                      Text Color
                    </button>
                  </h2>
                  <div
                    id="collapseFourMenu"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionMenu"
                  >
                    <div className="accordion-body">
                      <SketchPicker
                        color={settings.textColor}
                        onChangeComplete={handleOnTextColorChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiveMenu"
                      aria-expanded="true"
                      aria-controls="collapseFiveMenu"
                    >
                      Add menu item
                    </button>
                  </h2>
                  <div
                    id="collapseFiveMenu"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionMenu"
                  >
                    <div className="accordion-body">
                      <div className="d-flex justify-content-center">
                        <div>
                          <div className="w-100">
                            <button
                              className="btn btn-sm btn-secondary rounded-0 mb-1 "
                              onClick={() => setSelectRange(false)}
                            >
                              Add for one day
                            </button>{" "}
                            <button
                              className="btn btn-sm btn-info rounded-0 mb-1"
                              onClick={() => setSelectRange(true)}
                            >
                              {" "}
                              Add for range of days
                            </button>
                          </div>
                          <Calendar
                            minDate={new Date()}
                            {...(!selectRange && { value: selectedDate })}
                            onChange={handleDateChange}
                            onClickDay={menuDate}
                            selectRange={selectRange}
                            tileClassName={({ date }) =>
                              selectedDate &&
                              date.toDateString() ===
                                selectedDate?.toDateString()
                                ? "selected"
                                : componentInfo.menu?.some(
                                    (event) =>
                                      new Date(event.date).toDateString() ===
                                      date.toDateString()
                                  )
                                ? "event-marked"
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="input-group mb-1 mt-1">
                        <input
                          name="newMessage"
                          type="text"
                          className="form-control w-100"
                          placeholder="Menu item title"
                          value={menuTitle}
                          onChange={menuTitleUpdate}
                          aria-label="Recipient's username"
                        />
                      </div>
                      <div className="input-group">
                        <textarea
                          style={{
                            height: "55px",
                          }}
                          className="form-control"
                          placeholder="Menu item description"
                          id="floatingTextarea"
                          value={menuDescription}
                          onChange={menuDescriptionUpdate}
                        ></textarea>
                      </div>
                      <button
                        className="input-group-text btn btn-success bg-success btn-sm rounded-0 w-100 mt-1"
                        disabled={
                          menuTitle.length <= 3 || menuDescription.length <= 5
                        }
                        onClick={createMenuItem}
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSixMenu"
                      aria-expanded="true"
                      aria-controls="collapseSixMenu"
                    >
                      Menu overview
                    </button>
                  </h2>
                  <div
                    id="collapseSixMenu"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionMenu"
                  >
                    <div className="accordion-body">
                      {menu
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map((day, index) => (
                          <div
                            className="list-group list-group-flush"
                            key={index}
                          >
                            <div
                              style={{ cursor: "pointer" }}
                              className={`text-truncate list-group-item text-start d-flex justify-content-between align-items-center fw-bolder`}
                              onClick={() => handleDayClick(day.date)}
                            >
                              {menuFormatDate(new Date(day.date))}
                              <span
                                className="badge rounded-0 btn-sm text-white btn btn-danger me-1"
                                onClick={() => deleteDateMenu(index)}
                              >
                                <i className="bi bi-trash3 "></i>
                              </span>
                            </div>

                            {selectedDay === day.date && (
                              <div className="list-group list-group-flush ">
                                {selectedMenu.length > 0 ? (
                                  selectedMenu.map((item, index) => (
                                    <div
                                      style={{
                                        backgroundColor: `${componentInfo.color}`,
                                        cursor: "pointer",
                                      }}
                                      key={index}
                                      className={`text-truncate list-group-item text-start`}
                                      // onClick={() => handleItemClick(item)}
                                    >
                                      {item.title}
                                    </div>
                                  ))
                                ) : (
                                  <div className="list-group-item">
                                    No items available
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              {deleteConfirmation ? (
                <>
                  <div>Are you sure ?</div>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-0 "
                    onClick={deleteData}
                    data-bs-dismiss="modal"
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm rounded-0"
                    onClick={() => setDeleteConfirmation(false)}
                  >
                    NO
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-0 "
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    {" "}
                    REMOVE COMPONENT{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm rounded-0"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

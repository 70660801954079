import { CustomTemplateModal } from "./CustomTemplateModal";
import { CustomTemplateModalFive } from "./CustomTemplateModalFive";
import { CustomTemplateModalFour } from "./CustomTemplateModalFour";
import { CustomTemplateModalThree } from "./CustomTemplateModalThree";

export const AddCustomTemplates = ({ setInfoBoardList }) => {
  return (
    <>
      <li className="list-group-item d-flex justify-content-between align-items-start list-group-item-action text-black">
        <div
          className="ms-2 me-auto "
          style={{ cursor: "pointer" }}
          data-bs-toggle="modal"
          data-bs-target="#CustomTemplate"
        >
          <div className="fw-bold">Custom template #1</div>
        </div>
      </li>
      <li className="list-group-item d-flex justify-content-between align-items-start list-group-item-action text-black">
        <div
          className="ms-2 me-auto "
          style={{ cursor: "pointer" }}
          data-bs-toggle="modal"
          data-bs-target="#CustomTemplate3"
        >
          <div className="fw-bold">Custom template #2</div>
        </div>
      </li>
      <li className="list-group-item d-flex justify-content-between align-items-start list-group-item-action text-black">
        <div
          className="ms-2 me-auto "
          style={{ cursor: "pointer" }}
          data-bs-toggle="modal"
          data-bs-target="#CustomTemplate4"
        >
          <div className="fw-bold">Custom template #3</div>
        </div>
      </li>
      <li className="list-group-item d-flex justify-content-between align-items-start list-group-item-action text-black">
        <div
          className="ms-2 me-auto "
          style={{ cursor: "pointer" }}
          data-bs-toggle="modal"
          data-bs-target="#CustomTemplate5"
        >
          <div className="fw-bold">Custom template #4</div>
        </div>
      </li>
      <CustomTemplateModal setInfoBoardList={setInfoBoardList} />
      <CustomTemplateModalThree setInfoBoardList={setInfoBoardList} />
      <CustomTemplateModalFour setInfoBoardList={setInfoBoardList} />
      <CustomTemplateModalFive setInfoBoardList={setInfoBoardList} />
    </>
  );
};

import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { ApiUrls } from "../../Utils/API";
import {
  fetchData,
  notifyError,
  notifySuccess,
} from "../../Utils/ReusableFunctions";

export const DeviceInfoModal = ({
  selectedDevice,
  setSelectedDevice,
  setDeviceList,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { infoBoardList } = useSettingsInfoBoard();

  const matchedInfoboard = infoBoardList.find(
    (info) => info.ID === parseInt(selectedDevice?.SubscriptionBoardID)
  );

  const updateDevice = async () => {
    if (!selectedDevice) {
      throw new Error("No device selected");
    }
    const patchUrl = `${ApiUrls.deviceList}/${selectedDevice.ID}`;
    const patchData = {
      Name: selectedDevice.Name,
      SubscriptionBoardID: selectedDevice.SubscriptionBoardID,
    };
    const response = await fetch(patchUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(patchData),
    });
    if (!response.ok) {
      notifyError("Error updating device");
    }

    fetchData(ApiUrls.deviceList, sessionToken).then((data) => {
      setDeviceList(data.value);
    });
    notifySuccess("Device updated succesfully");
  };

  const handleInputChange = (fieldName, value) => {
    setSelectedDevice((prevDevice) => ({
      ...prevDevice,
      [fieldName]: value,
    }));
  };

  const handleSelectChange = (e) => {
    handleInputChange("SubscriptionBoardID", e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector("#deviceInfo #infoButton");
      closeButton.click();
    }
  };

  return (
    <div
      className="modal fade"
      id="deviceInfo"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="deviceInfoLabel"
      aria-hidden="true"
      onKeyDown={handleKeyDown}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" style={{color:"#858585"}} id="deviceInfoLabel">
              Device information
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-start">
            {matchedInfoboard ? (
              <p>This device is subscribed to {matchedInfoboard?.Name} </p>
            ) : (
              <p> This device is not subscribed to a Infoboard </p>
            )}

            <div className="mb-3">
              <label htmlFor="deviceName" className="form-label">
                Edit device name
              </label>
              <input
                type="text"
                className="form-control"
                id="deviceName"
                value={selectedDevice?.Name || ""}
                onChange={(e) => handleInputChange("Name", e.target.value)}
              />
            </div>
            <div className="mb-2" style={{ textAlign: "left" }}></div>
            <select
              id="selectInfoboard"
              className="form-select"
              aria-label="Default select example"
              onChange={handleSelectChange}
            >
              <option>Choose new infoboard</option>
              {infoBoardList.map((role) => (
                <option key={role.ID} value={role.ID}>
                  {role.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary rounded-0 btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              id="infoButton"
              type="button"
              className="btn btn-primary rounded-0 btn-sm"
              onClick={updateDevice}
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

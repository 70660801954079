import React, { useState, useEffect, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { AdminTopbar } from "./Admin/AdminTopbar";
import { fetchData, notifyError, patchData } from "../Utils/ReusableFunctions";
import { NewComponentModal } from "./ModalComponents/NewComponentModal";
import { ApiUrls } from "../Utils/API";
import ClockContainer from "./Clock/ClockContainer";
import { PdfViewer } from "./PdfViewer/PdfViewer";
import { VideoViewer } from "./VideoViewer/VideoViewer";
import { WeatherComponent } from "./Weather/WeatherComponent";
import { InfoTop } from "./Topbar/InfoTop";
import { SlidingText } from "./SlidingText/SlidingText";
import { InfoBottom } from "./Infobottom/InfoBottom";
import { useSettingsInfoBoard } from "../Context/InfoboardSettingsContext";
import { MediaComponentModal } from "./ModalComponents/MediaComponentModal";
import { DateComponent } from "./Date/Date";
import { WebBrowserContainer } from "./WebBrowser/WebBrowserContainer";
import { SaveCustomTemplate } from "./CustomTemplates/SaveCustomTemplate";
import { Oval } from "react-loader-spinner";
import { Calendar } from "./Calendar/Calendar";
import { BackgroundImageModal } from "./InfoboardSettings/BackgroundImageModal";
import { Images } from "./Images/Images";
import { MenuWithImages } from "./Menu/MenuWithImages";
import { OrderMenu } from "./Menu/OrderMenu";
import { ChangeInfoBoard } from "./Redirect/ChangeInfoBoard";

const COMPONENT_MAP = {
  ClockComponent: ClockContainer,
  PdfViewerComponent: PdfViewer,
  VideoViewerComponent: VideoViewer,
  WeatherComponent: WeatherComponent,
  TopbarComponent: InfoTop,
  SlidingInfoComponent: SlidingText,
  InfobarComponent: InfoBottom,
  DateComponent: DateComponent,
  WebBrowserComponent: WebBrowserContainer,
  CalendarComponent: Calendar,
  ImageComponent: Images,
  MenuItemWithImages: MenuWithImages,
  MenuOrderComponent: OrderMenu,
  ChangeInfoboardComponent: ChangeInfoBoard
};

export const Dashboard = () => {
  const { id, selectedComponentId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const guid = queryParams.get("guid") || queryParams.get("Guid");
  const { selectedComponents, setSelectedComponents } = useSettingsInfoBoard();
  const sessionToken = localStorage.getItem("bearerToken");
  const [infoBoard, setInfoBoard] = useState(null);
  const [infoBoardSettings, setInfoBoardSettings] = useState({});
  const [bgColor, setBgColor] = useState("");
  const [componentByGuid, setComponentByGuid] = useState({});
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [showbackgroundImage, setShowbackgroundImage] = useState(true);

  const fetchInfoboard = useCallback(async () => {
    let API = `${ApiUrls.infobardExt}(id=${id},Guid=${guid})`;
    try {
      if (guid) {
        const data = await fetchData(API);
        setInfoBoard(data);
        setInfoBoardSettings(JSON.parse(data.settingsJson));
      } else {
        const data = await fetchData(`${ApiUrls.infobard}${id}`, sessionToken);
        setInfoBoard(data);
        setInfoBoardSettings(JSON.parse(data.SettingsJson));
      }
    } catch (error) {
      notifyError("Error fetching infoboard list:");
    } finally {
      setLoading(false);
    }
  }, [id, sessionToken, guid]);

  useEffect(() => {
    fetchInfoboard();
  }, [fetchInfoboard]);

  useEffect(() => {
    if (infoBoardSettings.newMediaItems?.length >= 1) {
      const byteString = atob(infoBoardSettings.newMediaItems[0].Base64);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([arrayBuffer], {
        type: infoBoardSettings.newMediaItems[0].Type,
      });
      const imageUrl = URL.createObjectURL(blob);
      setBackgroundImage(imageUrl);
    }
    setShowbackgroundImage(infoBoardSettings.showImage);
  }, [infoBoardSettings]);

  const fetchComponents = useCallback(async () => {
    try {
      const data = await fetchData(ApiUrls.component, sessionToken);
      const filteredComponents = data.value.filter(
        (component) => String(component.BoardID) === String(id)
      );
      setSelectedComponents(
        filteredComponents.map((component) => ({
          id: component.ID,
          type: JSON.parse(component.SettingsJson).type,
          settings: component.SettingsJson,
          name: component.Name,
        }))
      );
    } catch (error) {
      notifyError("Error fetching component list:", error);
    } finally {
      setLoading(false);
    }
  }, [setSelectedComponents, sessionToken, id]);

  useEffect(() => {
    if (sessionToken) {
      fetchComponents();
    }
  }, [fetchComponents, sessionToken]);

  const fetchComponentByGuid = useCallback(async () => {
    if (selectedComponentId && guid) {
      try {
        const data = await fetchData(
          `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`
        );
        setComponentByGuid({
          id: data.id,
          type: JSON.parse(data.settingsJson).type,
          settings: data.settingsJson,
        });
      } catch (error) {
        notifyError("Error fetching component by GUID:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [selectedComponentId, guid]);

  useEffect(() => {
    fetchComponentByGuid();
  }, [fetchComponentByGuid]);

  const patchSettingsToBackend = async (updatedSettings) => {
    try {
      const existingSettings = JSON.parse(infoBoard.SettingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });
      await patchData(`${ApiUrls.infobard}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchInfoboard();
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  useEffect(() => {
    if (infoBoardSettings) {
      setBgColor(infoBoardSettings.backgroundColor);
    }
  }, [infoBoardSettings]);

  const deleteData = async (componentId) => {
    try {
      const response = await fetch(`${ApiUrls.component}/${componentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Failed to delete data");
      }
      setSelectedComponents((prevSelectedComponents) =>
        prevSelectedComponents.filter(
          (component) => component.id !== componentId
        )
      );
    } catch (error) {
      notifyError("Error deleting data", error);
    }
  };

  const renderComponent = (componentObject) => {
    const Component = COMPONENT_MAP[componentObject.type];
    return Component ? (
      <Component
        key={componentObject.id}
        id={componentObject.id}
        deleteData={() => deleteData(componentObject.id)}
        selectedComponentId={selectedComponentId}
        guid={guid}
        isRounded={infoBoardSettings.isRoundedComponents}
        componentName={componentObject.name}
        infoBoard={infoBoard}
      />
    ) : null;
  };

  return (
    <div
      id="componentToCapture"
      style={{
        backgroundColor: bgColor,
        minHeight: "100vh",
        position: "relative",
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundImage: showbackgroundImage && `url(${backgroundImage})`,
      }}
    >
      {!selectedComponentId && (
        <div
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() => setIsVisible(false)}
        >
          <AdminTopbar
            infoBoardSettings={infoBoardSettings}
            patchSettingsToBackend={patchSettingsToBackend}
            bgColor={bgColor}
            setBgColor={setBgColor}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            deleteData={deleteData}
            id={id}
          />
        </div>
      )}

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: bgColor,
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : selectedComponentId && guid ? (
        renderComponent(componentByGuid)
      ) : (
        selectedComponents?.map((componentObject) =>
          renderComponent(componentObject)
        )
      )}

      {!selectedComponentId && (
        <>
          <NewComponentModal id={id} />
          <MediaComponentModal />
          <SaveCustomTemplate bgColor={bgColor} />
          <BackgroundImageModal
            id={id}
            patchSettingsToBackend={patchSettingsToBackend}
            infoBoardSettings={infoBoardSettings}
            setBackgroundImage={setBackgroundImage}
            showbackgroundImage={showbackgroundImage}
            setShowbackgroundImage={setShowbackgroundImage}
          />
        </>
      )}
    </div>
  );
};

import { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import React from "react";
import Draggable from "react-draggable";
import { MediaList } from "../Media/MediaList";
import {
  saveSortOrderToBackend,
  handleDeleteFile,
} from "../../Utils/ReusableFunctions";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { ApiUrls } from "../../Utils/API";
import { fetchData } from "../../Utils/ReusableFunctions";
import { notifyError } from "../../Utils/ReusableFunctions";

export const ImageModal = ({
  id,
  deleteData,
  componentInfo,
  patchSettingsToBackend2,
  imageData,
  setCurrentImageIndex,
  imageMediaList,
  setImageMediaList,
  currentImageIndex,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { mediaList, setComponentMedia } = useSettingsInfoBoard();
  const [settings, setSettings] = useState({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isDraggable, setIsDraggable] = useState(true);
  const nodeRef = useRef(null);
  const [expiryDate, setExpiryDate] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedMediaIndex, setEditedEventIndex] = useState(null);
  const [timeouts, setTimeouts] = useState({
    slidingText: null,
    textSize: null,
    animationDuration: null,
    height: null,
    width: null,
    xPosition: null,
    yPosition: null,
    opacity: null,
    info: null,
    zIndex: null,
    imageSpeed: null,
  });

  useEffect(() => {
    if (componentInfo) {
      setSettings({
        backgroundColor: componentInfo.color,
        textColor: componentInfo.textColor,
        opacity: componentInfo.opacity,
        width: componentInfo.width,
        height: componentInfo.height,
        textSize: componentInfo.textSize,
        xPosition: componentInfo.xPosition,
        yPosition: componentInfo.yPosition,
        zIndex: componentInfo.zIndex,
        imageSpeed: componentInfo.imageSpeed,
      });
    }
  }, [componentInfo]);

  const findMediaName = (mediaId) => {
    const media = mediaList.find((media) => media.ID === mediaId);
    return media ? media.Name : "Unknown";
  };

  const updateTimeout = (key, value) => {
    setTimeouts((prevTimeouts) => ({
      ...prevTimeouts,
      [key]: value,
    }));
  };

  useEffect(() => {
    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [timeouts]);

  const handleOnBackgroundChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, backgroundColor: newColor });
    patchSettingsToBackend2(
      {
        color: newColor,
      },
      imageData.SettingsJson,
      id
    );
  };

  const handleOnTextColorChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, textColor: newColor });
    patchSettingsToBackend2(
      {
        textColor: newColor,
      },
      imageData.SettingsJson,
      id
    );
  };

  const handleSettingChange = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    clearTimeout(timeouts[key]);
    updateTimeout(
      key,
      setTimeout(() => {
        patchSettingsToBackend2({ [key]: value }, imageData.SettingsJson, id);
      }, 500)
    );
  };

  const handleInputChange = (key, value, maxLimit) => {
    let parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      parsedValue = 2;
    } else if (parsedValue > maxLimit) {
      parsedValue = maxLimit;
    }
    handleSettingChange(key, parsedValue);
  };

  const reorderFiles = (startIndex, endIndex) => {
    if (startIndex === null || endIndex === null) return;
    setImageMediaList((files) => {
      const file = [...files];
      const [removed] = file.splice(startIndex, 1);
      file.splice(endIndex, 0, removed);

      const updatedFiles = file.map((item, index) => ({
        ...item,
        SortOrder: index + 1,
      }));
      saveSortOrderToBackend(updatedFiles, setImageMediaList, id, sessionToken);
      return updatedFiles;
    });
  };

  const draggingPos = useRef(null);
  const dragOverPos = useRef(null);

  const handleDragStart = (position) => {
    draggingPos.current = position;
  };

  const handleDragEnter = (position) => {
    dragOverPos.current = position;
  };

  const handleOnDrop = () => {
    if (draggingPos.current !== null && dragOverPos.current !== null) {
      reorderFiles(draggingPos.current, dragOverPos.current);
    }
    draggingPos.current = null;
    dragOverPos.current = null;
  };

  const handleSaveChanges = (mediaID) => {
    const formattedExpiryDate = new Date(expiryDate).toISOString();

    const patchUrl = `${ApiUrls.componentMedia}(${mediaID})`;
    const patchData = {
      Expiry: formattedExpiryDate,
    };

    fetch(patchUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(patchData),
    })
      .then((response) => {
        if (response.ok) {
          setIsEditing(false);
          setEditedEventIndex(null);
          setExpiryDate("");
          fetchData(
            `${ApiUrls.component}(${id})?$expand=media($expand=media)`,
            sessionToken
          )
            .then((data) => {
              const mediaList = data.Media || data.media;
              const currentDate = new Date();
              const filteredMediaList = mediaList.filter((mediaItem) => {
                if (mediaItem.Expiry) {
                  const expiryDate = new Date(mediaItem.Expiry);
                  return expiryDate > currentDate;
                }
                return true;
              });
              setImageMediaList(filteredMediaList);
            })
            .catch((error) => {
              console.error("Failed to fetch data:", error);
              notifyError("Failed to fetch data:", error);
            });
        } else {
          notifyError("Error updating media list");
        }
      })
      .catch((error) => {
        notifyError("Error updating media list", error);
      });
  };

  const startEditing = (index) => {
    setIsEditing(true);
    setEditedEventIndex(index);
  };

  const handleDateChange = (e) => {
    setExpiryDate(e.target.value);
  };

  return (
    <Draggable nodeRef={nodeRef} disabled={!isDraggable}>
      <div
        className="modal"
        id={`imageModal${id}`}
        tabIndex="-1"
        aria-labelledby={`imageModal${id}`}
        aria-hidden="true"
        data-bs-backdrop="static"
        ref={nodeRef}
      >
        <div className="modal-dialog modal-xl ">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <div className="row w-100">
                <div className="col-6">
                  <h4
                    className="modal-title fs-5 text-start"
                    id={`imageModal${id}`}
                  >
                    Properties for {componentName}
                  </h4>
                </div>
                <div className="col-6">
                  <h4
                    className="modal-title fs-5 text-start"
                    id={`imageModal${id}`}
                  >
                    Image list
                  </h4>
                </div>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              onMouseEnter={() => setIsDraggable(false)}
              onMouseLeave={() => setIsDraggable(true)}
            >
              <div className="row">
                <div className="col">
                  <>
                    <ol className="list-group list-group-flush text-start">
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className=" me-auto">Opacity</div>
                        <span className="me-2">{settings.opacity}%</span>
                        <span>
                          <input
                            type="range"
                            className="form-range"
                            min="0"
                            max="100"
                            step="25"
                            id="dateRange3"
                            value={settings.opacity || 0}
                            onChange={(e) =>
                              handleSettingChange(
                                "opacity",
                                parseInt(e.target.value)
                              )
                            }
                          />
                        </span>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="row w-100">
                          <div className="col input-group">
                            <span
                              className="input-group-text border-end-0"
                              id="widthDate"
                            >
                              width
                            </span>
                            <input
                              name="width"
                              type="number"
                              max={24 - settings.xPosition || 0}
                              className="form-control"
                              aria-label="width"
                              aria-describedby="width"
                              onChange={(e) =>
                                handleInputChange(
                                  "width",
                                  e.target.value,
                                  24 - settings.xPosition
                                )
                              }
                              value={settings.width || ""}
                            />
                          </div>
                          <div className="col input-group">
                            <span className="input-group-text" id="heightDate">
                              height
                            </span>
                            <input
                              name="height"
                              type="number"
                              min={2}
                              className="form-control"
                              max={24 - settings.yPosition || 0}
                              aria-label="height"
                              aria-describedby="height"
                              onChange={(e) =>
                                handleInputChange(
                                  "height",
                                  e.target.value,
                                  24 - settings.yPosition
                                )
                              }
                              value={settings.height || ""}
                            />
                          </div>
                        </div>
                      </li>

                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="row w-100">
                          <div className="col input-group">
                            <span className="input-group-text" id="X">
                              X
                            </span>
                            <input
                              name="X"
                              min={0}
                              max={24 - settings.width || 0}
                              type="number"
                              className="form-control"
                              aria-label="X"
                              aria-describedby="X"
                              onChange={(e) =>
                                handleInputChange(
                                  "xPosition",
                                  e.target.value,
                                  24 - settings.width
                                )
                              }
                              value={settings.xPosition || 0}
                            />
                          </div>
                          <div className="col input-group">
                            <span
                              className="input-group-text border-end-0"
                              id="Y"
                            >
                              Y
                            </span>
                            <input
                              name="Y"
                              type="number"
                              className="form-control"
                              aria-label="Y"
                              min={0}
                              max={24 - settings.height || 0}
                              aria-describedby="Y"
                              onChange={(e) =>
                                handleInputChange(
                                  "yPosition",
                                  e.target.value,
                                  24 - settings.height
                                )
                              }
                              value={settings.yPosition || 0}
                            />
                          </div>
                        </div>
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto">Text size</div>
                        <input
                          name="textsizedate"
                          type="number"
                          className="form-control w-25 me-4"
                          value={settings.textSize || ""}
                          onChange={(e) =>
                            handleSettingChange(
                              "textSize",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </li>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto ">zIndex</div>
                        <input
                          name="textsizedate"
                          type="number"
                          className="form-control w-25 me-4"
                          value={settings.zIndex || 0}
                          onChange={(e) =>
                            handleSettingChange(
                              "zIndex",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </li>
                    </ol>
                  </>
                  <div
                    className="accordion accordion-flush"
                    id="accordionImage"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOneImage"
                          aria-expanded="true"
                          aria-controls="collapseOneImage"
                        >
                          Background Color
                        </button>
                      </h2>
                      <div
                        id="collapseOneImage"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionImage"
                      >
                        <div className="accordion-body">
                          <SketchPicker
                            color={settings.backgroundColor}
                            onChangeComplete={handleOnBackgroundChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFourImage"
                          aria-expanded="true"
                          aria-controls="collapseFourImage"
                        >
                          Text Color
                        </button>
                      </h2>
                      <div
                        id="collapseFourImage"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionImage"
                      >
                        <div className="accordion-body">
                          <SketchPicker
                            color={settings.textColor}
                            onChangeComplete={handleOnTextColorChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Image list
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <ol className="list-group list-group-flush text-start">
                            {imageMediaList &&
                              imageMediaList
                                .sort((a, b) => a.SortOrder - b.SortOrder)
                                .map((item, index) => (
                                  <li
                                    key={item.MediaID || item.mediaID}
                                    className="list-group-item d-flex justify-content-between align-items-start"
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragEnter={() => handleDragEnter(index)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragEnd={handleOnDrop}
                                  >
                                    <div
                                      className="ms-2 me-auto w-100"
                                      onClick={() => {
                                        setCurrentImageIndex(index);
                                      }}
                                    >
                                      <div>
                                      {findMediaName(
                                        item.MediaID || item.mediaID
                                      )}{" "}
                                      </div>
                                      {isEditing &&
                                      editedMediaIndex === index ? (
                                        <>
                                          <div>
                                            <input
                                              type="date"
                                              value={
                                                expiryDate
                                                  ? expiryDate.split("T")[0]
                                                  : ""
                                              }
                                              onChange={handleDateChange}
                                            />
                                            <button
                                              className="btn btn-success btn-sm ms-1 rounded-0"
                                              style={{ width: "32%" }}
                                              onClick={() =>
                                                handleSaveChanges(item.ID)
                                              }
                                            >
                                              Save changes
                                            </button>
                                            <button
                                              style={{ width: "32%" }}
                                              className="btn btn-danger ms-1 btn-sm rounded-0"
                                              onClick={() =>
                                                setIsEditing(false)
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </>
                                      ) : (
                                        item.Expiry && (
                                          <small>
                                            - expire{" "}
                                            {
                                              new Date(item.Expiry)
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                          </small>
                                        )
                                      )}
                                    </div>
                                    {!isEditing && (
                                      <>
                                        <span
                                          className="badge btn-sm btn text-bg-danger text-white rounded-0"
                                          onClick={() => {
                                            handleDeleteFile(
                                              item.ID,
                                              sessionToken,
                                              id,
                                              setImageMediaList,
                                              setComponentMedia
                                            );
                                            if (
                                              imageMediaList.length ===
                                              currentImageIndex + 1
                                            ) {
                                              setCurrentImageIndex(0);
                                            }
                                          }}
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="bi bi-trash3"
                                            style={{ fontSize: "14px" }}
                                          ></i>
                                        </span>
                                        <span
                                          className="badge btn-sm btn btn-secondary text-black rounded-0 ms-1"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            startEditing(index, item.Expiry)
                                          }
                                        >
                                          <i
                                            className="bi bi-pen"
                                            style={{ fontSize: "14px" }}
                                          ></i>
                                        </span>
                                      </>
                                    )}
                                    <small></small>
                                  </li>
                                ))}

                            {imageMediaList && imageMediaList.length === 0 && (
                              <div className="text-center">
                                NO FILE AVAILABLE
                              </div>
                            )}
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ol className="list-group list-group-flush text-start">
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className=" pt-1 me-auto">Image interval</div>
                        <input
                          name="imageSpeed"
                          type="number"
                          className="form-control w-25"
                          value={settings.imageSpeed || 0}
                          onChange={(e) =>
                            handleSettingChange(
                              "imageSpeed",
                              parseInt(e.target.value)
                            )
                          }
                        />
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="col">
                  <MediaList
                    componentType={"image"}
                    id={id}
                    setList={setImageMediaList}
                    list={imageMediaList}
                  />
                </div>
              </div>

              <div className="modal-footer">
                {deleteConfirmation ? (
                  <>
                    <div>Are you sure ?</div>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm rounded-0 "
                      onClick={deleteData}
                      data-bs-dismiss="modal"
                    >
                      YES
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm rounded-0"
                      onClick={() => setDeleteConfirmation(false)}
                    >
                      NO
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm rounded-0 "
                      onClick={() => setDeleteConfirmation(true)}
                    >
                      {" "}
                      REMOVE COMPONENT{" "}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm rounded-0"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

import { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";

export const WebBrowserModal = ({
  id,
  deleteData,
  componentInfo,
  patchSettingsToBackend2,
  webData,
  componentName
}) => {
  const [newUrl, setNewUrl] = useState("");
  const [urlDelay, setUrlDelay] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [settings, setSettings] = useState({});
  const [isDraggable, setIsDraggable] = useState(true);
  const [https, setHttps] = useState("https://");
  const [timeouts, setTimeouts] = useState({
    height: null,
    width: null,
    xPosition: null,
    yPosition: null,
    urlDelay: null,
    zIndex: null,
  });
  const nodeRef = useRef(null);
  useEffect(() => {
    if (componentInfo) {
      setSettings({
        width: componentInfo.width,
        height: componentInfo.height,
        xPosition: componentInfo.xPosition,
        yPosition: componentInfo.yPosition,
        urlDelay: componentInfo.urlDelay,
        zIndex: componentInfo.zIndex,
      });
    }
  }, [componentInfo]);

  const updateTimeout = (key, value) => {
    setTimeouts((prevTimeouts) => ({
      ...prevTimeouts,
      [key]: value,
    }));
  };

  const addUrl = () => {
    const updatedUrlList = [
      ...(componentInfo.urlList || []),
      {
        url: https + newUrl,
        urlDelay: parseInt(urlDelay),
      },
    ];
    patchSettingsToBackend2(
      {
        urlList: updatedUrlList,
      },
      webData.SettingsJson,
      id
    );
    setNewUrl("");
    setUrlDelay("");
  };

  const removeFile = (index) => {
    const updatedList = [...componentInfo.urlList];
    updatedList.splice(index, 1);
    patchSettingsToBackend2(
      {
        urlList: updatedList,
      },
      webData.SettingsJson,
      id
    );
  };

  const handleUrlDelayChange = (index, value) => {
    const updatedList = componentInfo.urlList.map((item, i) =>
      i === index ? { ...item, urlDelay: parseInt(value) } : item
    );
    patchSettingsToBackend2(
      {
        urlList: updatedList,
      },
      webData.SettingsJson,
      id
    );
  };

  useEffect(() => {
    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [timeouts]);

  const handleSettingChange = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    clearTimeout(timeouts[key]);
    updateTimeout(
      key,
      setTimeout(() => {
        patchSettingsToBackend2({ [key]: value }, webData.SettingsJson, id);
      }, 200)
    );
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector(
        `#webModal${id}   #addUrlButton`
      );
      closeButton.click();
    }
  };

  const handleInputChange = (key, value, maxLimit) => {
    let parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      parsedValue = 2;
    } else if (parsedValue > maxLimit) {
      parsedValue = maxLimit;
    }
    handleSettingChange(key, parsedValue);
  };

  return (
    <Draggable nodeRef={nodeRef} disabled={!isDraggable}>
      <div
        className="modal "
        id={`webModal${id}`}
        tabIndex="-1"
        aria-labelledby={`webModal${id}`}
        aria-hidden="true"
        data-bs-backdrop="static"
        onKeyDown={handleKeyDown}
        ref={nodeRef}
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h4 className="modal-title fs-5" id={`webModal${id}`}>
                Properties for {componentName}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              onMouseEnter={() => setIsDraggable(false)}
              onMouseLeave={() => setIsDraggable(true)}
            >
              <>
                <ol className="list-group list-group-flush text-start">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="row w-100">
                      <div className="col input-group">
                        <span
                          className="input-group-text border-end-0"
                          id="widthDate"
                        >
                          width
                        </span>
                        <input
                          name="width"
                          type="number"
                          min={2}
                          max={24 - settings.xPosition || 0}
                          className="form-control"
                          aria-label="width"
                          aria-describedby="width"
                          onChange={(e) =>
                            handleInputChange(
                              "width",
                              e.target.value,
                              24 - settings.xPosition
                            )
                          }
                          value={settings.width || ""}
                        />
                      </div>
                      <div className="col input-group">
                        <span className="input-group-text" id="heightDate">
                          height
                        </span>
                        <input
                          name="height"
                          type="number"
                          min={2}
                          max={24 - settings.yPosition || 0}
                          className="form-control"
                          aria-label="height"
                          aria-describedby="height"
                          onChange={(e) =>
                            handleInputChange(
                              "height",
                              e.target.value,
                              24 - settings.yPosition
                            )
                          }
                          value={settings.height || ""}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="row w-100">
                      <div className="col input-group">
                        <span className="input-group-text" id="X">
                          X
                        </span>
                        <input
                          name="X"
                          type="number"
                          min={0}
                          max={24 - settings.width || 0}
                          className="form-control"
                          aria-label="X"
                          aria-describedby="X"
                          onChange={(e) =>
                            handleInputChange(
                              "xPosition",
                              e.target.value,
                              24 - settings.width
                            )
                          }
                          value={settings.xPosition || 0}
                        />
                      </div>
                      <div className="col input-group">
                        <span className="input-group-text border-end-0" id="Y">
                          Y
                        </span>
                        <input
                          name="Y"
                          min={0}
                          max={24 - settings.height || 0}
                          type="number"
                          className="form-control"
                          aria-label="Y"
                          aria-describedby="Y"
                          onChange={(e) =>
                            handleInputChange(
                              "yPosition",
                              e.target.value,
                              24 - settings.height
                            )
                          }
                          value={settings.yPosition || 0}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div className=" pt-1 me-auto">zIndex</div>
                    <input
                      name="textsizetop"
                      type="number"
                      className="form-control w-25 me-4"
                      value={settings.zIndex || 0}
                      onChange={(e) =>
                        handleSettingChange("zIndex", parseInt(e.target.value))
                      }
                    />
                  </li>
                </ol>
              </>
              <div className="accordion accordion-flush" id="accordionWeb">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiveWeb"
                      aria-expanded="true"
                      aria-controls="collapseFiveWeb"
                    >
                      Url list
                    </button>
                  </h2>
                  <div
                    id="collapseFiveWeb"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionInfobar"
                  >
                    <div className="accordion-body">
                      <div className="input-group mb-1">
                        <input
                          value={https}
                          className="form-control w-25"
                          onChange={(e) => setHttps(e.target.value)}
                          name="newHttps"
                          type="text"
                          style={{ outline: "none", outlineWidth: 0 }}
                        />

                        <input
                          name="newUrl"
                          type="text"
                          className="form-control w-100"
                          placeholder="Url"
                          value={newUrl}
                          onChange={(e) => setNewUrl(e.target.value)}
                          aria-describedby="basic-addon2"
                        />
                      </div>
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        <div className="ms-1">Website Delay</div>
                        <input
                          name="messagedelaybottom"
                          placeholder="Seconds"
                          className="form-control w-25"
                          value={urlDelay}
                          onChange={(e) => setUrlDelay(e.target.value)}
                        />
                      </li>
                      <button
                        id="addUrlButton"
                        className="btn btn-success btn-sm m-1 w-100"
                        onClick={addUrl}
                        disabled={!urlDelay || !newUrl}
                      >
                        {" "}
                        Add Url
                      </button>
                      <ol className="list-group list-group-flush text-start">
                        {componentInfo.urlList &&
                        componentInfo.urlList.length > 0 ? (
                          componentInfo.urlList.map((item, index) => (
                            <div key={index}>
                              {" "}
                              <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                  <div>{item.url}</div>
                                </div>
                                <span
                                  className="badge text-bg-danger rounded-0 text-white"
                                  onClick={() => removeFile(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    className="bi bi-trash3"
                                    style={{ fontSize: "14px" }}
                                  ></i>
                                </span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                <div className=" ms-2 me-auto"> Delay</div>
                                <input
                                  style={{ width: "15%" }}
                                  name="editUrlDelay"
                                  className="form-control"
                                  value={item.urlDelay || ""}
                                  onChange={(e) =>
                                    handleUrlDelayChange(index, e.target.value)
                                  }
                                />
                              </li>
                            </div>
                          ))
                        ) : (
                          <div className="mt-3 text-center">
                            No Url available
                          </div>
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {deleteConfirmation ? (
                  <>
                    <div>Are you sure ?</div>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm rounded-0 "
                      onClick={deleteData}
                      data-bs-dismiss="modal"
                    >
                      YES
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm rounded-0"
                      onClick={() => setDeleteConfirmation(false)}
                    >
                      NO
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm rounded-0 "
                      onClick={() => setDeleteConfirmation(true)}
                    >
                      {" "}
                      REMOVE COMPONENT{" "}
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm rounded-0"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

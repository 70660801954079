import React, { useState, useEffect, useRef, useMemo } from "react";
import ReactPlayer from "react-player";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { Cross } from "hamburger-react";
import {
  fetchData,
  notifyError,
  patchData,
  isColorLight,
} from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { VideoViewerModal } from "./VideoViewerModal";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";

export const VideoViewer = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const nodeRef = useRef(null);
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [videoData, setVideoData] = useState([]);
  const [componentInfo, setComponentInfo] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [videoMediaList, setVideoMediaList] = useState([]);
  const [blobList, setBlobList] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isZindex, setIsZindex] = useState(false);

  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}(${id})?$expand=media`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        const mediaList = data.Media || data.media;
        const currentDate = new Date();
        const filteredMediaList = mediaList.filter((mediaItem) => {
          if (mediaItem.Expiry) {
            const expiryDate = new Date(mediaItem.Expiry);
            return expiryDate > currentDate;
          }
          return true;
        });
        setComponentInfo(settingsArray);
        setVideoData(data);
        setVideoMediaList(filteredMediaList);
      })
      .catch((error) => {
        //console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      });
  }, [id, sessionToken, selectedComponentId, guid]);

  useEffect(() => {
    if (videoMediaList && videoMediaList.length > 0) {
      const fetchMedia = async () => {
        const mediaArray = [];
        for (const media of videoMediaList) {
          let API;
          if (sessionToken) {
            API = `${ApiUrls.getMedia}(id=${media.MediaID || media.mediaID})`;
          } else {
            API = `${ApiUrls.getMediaExt}(id=${media.mediaID},Guid=${guid})`;
          }
          try {
            const imageUrlResponse = await fetch(API, {
              headers: {
                Authorization: `Bearer ${sessionToken}`,
              },
            });
            if (!imageUrlResponse.ok) {
              throw new Error(
                `Failed to fetch media for ID ${media.ID}: ${imageUrlResponse.statusText}`
              );
            }
            const blob = await imageUrlResponse.blob();
            const imageUrl = URL.createObjectURL(blob);
            const id = media.MediaID;
            mediaArray.push({ id, imageUrl });
          } catch (error) {
            notifyError(`Error fetching media for ID ${media.ID}:`, error);
          }
        }
        setBlobList(mediaArray);
      };

      fetchMedia();
    }
  }, [videoMediaList, sessionToken, guid]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });

      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setVideoData(data);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          notifyError("Failed to fetch data:", error);
        });
    } catch (error) {
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    errorText: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.errorText
          : (componentInfo.errorText * initialSize.height) / window.innerHeight,
      [componentInfo.errorText, initialSize.height, selectedComponentId]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );
  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const fetchAdditionalData = async () => {
    let API;
    API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    console.log(API);
    fetchData(API, sessionToken).then((data) => {
      setVideoMediaList(data.Media || data.media);
      console.log(data);
    });
  };

  const handleVideoEnded = () => {
    if (selectedComponentId) {
      fetchAdditionalData();
    }
    const nextIndex = (currentVideoIndex + 1) % blobList.length;
    setCurrentVideoIndex(nextIndex);
  };

  const handleResize = (event, { size }) => {
    if (!selectedComponentId) {
      const newWidth = Math.round((size.width / window.innerWidth) * 24);
      const newHeight = Math.round((size.height / window.innerHeight) * 24);
      patchSettingsToBackend2(
        {
          width: newWidth,
          height: newHeight,
        },
        videoData.SettingsJson,
        id
      );
    }
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        videoData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(
          data.SettingsJson || data.settingsJson
        );
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.backgroundColor);

  const styles = {
    hamburger: {
      position: "absolute",
      top: 0,
      right: 10,
      zIndex: 1,
      color: result ? "black" : "white",
    },
  };

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
              ref={nodeRef}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
              }}
            >
              {!selectedComponentId && (
                <div
                  style={styles.hamburger}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#videoViewerModal${id}`}
                >
                  <Cross size={25} toggled={false} />
                </div>
              )}
              {blobList && blobList.length > 0 ? (
                <div
                  className={`${
                    selectedComponentId
                      ? ""
                      : zIndex
                      ? "hoverEffectzIndex"
                      : "hoverEffect"
                  } opacity-${
                    componentInfo.opacity
                  } d-flex justify-content-center align-items-center `}
                  style={{
                    backgroundColor: componentInfo.backgroundColor,
                    borderRadius: isRounded ? "10px" : "",
                    height: selectedComponentId ? "100%" : initialSize.height,
                    width: selectedComponentId ? "100%" : initialSize.width,
                  }}
                >
                  <ReactPlayer
                    style={{
                      top: 0,
                      left: 0,
                      borderRadius: isRounded ? "10px" : "",
                      overflow: "hidden",
                    }}
                    //loop={blobList.length === 1 ? true : false}
                    playing={true}
                    controls={true}
                    width="100%"
                    height="100%"
                    url={blobList && blobList[currentVideoIndex]?.imageUrl}
                    onEnded={handleVideoEnded}
                    volume={1}
                    muted={true}
                  />
                </div>
              ) : (
                <div
                  className={`${
                    selectedComponentId
                      ? ""
                      : zIndex
                      ? "hoverEffectzIndex"
                      : "hoverEffect"
                  } opacity-${
                    componentInfo.opacity
                  } d-flex justify-content-center align-items-center `}
                  style={{
                    top: 0,
                    left: 0,
                    borderRadius: isRounded ? "10px" : "",
                    overflow: "hidden",
                    backgroundColor: componentInfo.backgroundColor,
                    color: componentInfo.textColor,
                    height: selectedComponentId ? "100%" : initialSize.height,
                    width: selectedComponentId ? "100%" : initialSize.width,
                    fontSize: `${initialFontSize.errorText}vh`,
                  }}
                >
                  No Video Available
                </div>
              )}
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
      {!selectedComponentId && (
        <VideoViewerModal
          id={id}
          deleteData={deleteData}
          componentInfo={componentInfo}
          videoData={videoData}
          videoMediaList={videoMediaList}
          setVideoMediaList={setVideoMediaList}
          currentVideoIndex={currentVideoIndex}
          patchSettingsToBackend2={patchSettingsToBackend2}
          setCurrentVideoIndex={setCurrentVideoIndex}
          componentName={componentName}
        />
      )}
    </>
  );
};

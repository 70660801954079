import { useRef, useEffect } from "react";

export const DeleteDeviceModal = ({
  selectedDevice,
  deleteConfirmation,
  setDeleteConfirmation,
  handleDeleteInfoBoard,
}) => {
  const inputRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector("#removeDevice #removeAgree");
      closeButton.click();
    }
  };

  useEffect(() => {
    const modalElement = document.getElementById("removeDevice");
    const handleModalShown = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };
    modalElement.addEventListener("shown.bs.modal", handleModalShown);
    return () => {
      modalElement.removeEventListener("shown.bs.modal", handleModalShown);
    };
  }, []);

  return (
    <div
      className="modal fade "
      data-bs-backdrop="static"
      id="removeDevice"
      tabIndex="-1"
      aria-labelledby="removeDeviceLabel"
      aria-hidden="true"
      onKeyDown={handleKeyDown}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="removeDeviceLabel">
              Delete infoboard confirmation
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-start">
            <div>
              {" "}
              {selectedDevice
                ? `Enter delete if you want to delete the device "${selectedDevice.name}"?`
                : "Are you sure you want to delete this device?"}
            </div>
            <input
              ref={inputRef}
              name="ComponentName"
              type="text"
              className="form-control w-50 "
              value={deleteConfirmation}
              onChange={(e) => setDeleteConfirmation(e.target.value)}
            />
          </div>

          <div className="modal-footer">
            <button
              type="button"
              id="removeAgree"
              className="btn btn-danger rounded-0 text-white"
              data-bs-dismiss="modal"
              onClick={handleDeleteInfoBoard}
              disabled={deleteConfirmation.toLowerCase() !== "delete"}
            >
              YES
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-0"
              data-bs-dismiss="modal"
            >
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

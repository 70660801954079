import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import "./WeatherComponent.css";
import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { Cross } from "hamburger-react";
import {
  fetchData,
  patchData,
  notifyError,
  isColorLight,
} from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { WeatherModal } from "./WeatherModal";

export const WeatherComponent = ({
  id,
  deleteData,
  selectedComponentId,
  guid,
  isRounded,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const nodeRef = useRef(null);
  const [city, setCity] = useState("");
  const [weatherList, setWeatherList] = useState("");
  const [error, setError] = useState("");
  const api_key = "58f9526b5a40e4af4998bcd0db49fc81";
  const {
    positions,
    setPositionCustomComponent,
    setSizeCustomComponent,
    componentSize,
    zIndex,
  } = useSettingsInfoBoard(id);
  const [componentInfo, setComponentInfo] = useState([]);
  const [weatherData, setWeatherData] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isZindex, setIsZindex] = useState(false);

  const fetchWeather = useCallback(
  
    async (cityName) => {
      console.log("CALLED")
      const basicUrl = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${api_key}`;
      try {
        const basicResponse = await fetch(basicUrl);
        const basicData = await basicResponse.json();
        if (basicResponse.ok) {
          const { lat, lon } = basicData.coord;
          const detailedUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${api_key}`;
          const detailedResponse = await fetch(detailedUrl);
          const detailedData = await detailedResponse.json();
          if (detailedResponse.ok) {
            setWeatherList(detailedData);
            setError(null);
            setCity("");
          } else {
            setError(
              detailedData.message || "Unable to retrieve detailed weather data"
            );
            notifyError("Unable to retrieve detailed weather data");
          }
        } else {
          setError(basicData.message || "City not found");
          notifyError("City not found");
        }
      } catch (error) {
        setError("Error fetching weather data");
        //console.error("Error fetching weather data:", error);
        notifyError("Error fetching weather data:", error);
      }
    },
    [api_key]
  );

  useEffect(() => {
    let API;
    if (sessionToken) {
      API = `${ApiUrls.component}/${id}`;
    } else {
      API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
    }
    fetchData(API, sessionToken)
      .then((data) => {
        const settingsArray = JSON.parse(
          data.settingsJson || data.SettingsJson
        );
        setComponentInfo(settingsArray);
        setCity(settingsArray.weatherCity);
        setWeatherData(data);
        fetchWeather(settingsArray.weatherCity);
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        notifyError("Failed to fetch data:", error);
      }); // eslint-disable-next-line
  }, [id, sessionToken, selectedComponentId, guid]);

  const patchSettingsToBackend2 = async (updatedSettings, settingsJson, id) => {
    try {
      const existingSettings = JSON.parse(settingsJson);
      const updatedSettingsJson = JSON.stringify({
        ...existingSettings,
        ...updatedSettings,
      });

      await patchData(`${ApiUrls.component}/${id}`, sessionToken, {
        SettingsJson: updatedSettingsJson,
      });
      fetchData(`${ApiUrls.component}/${id}`, sessionToken)
        .then((data) => {
          const settingsArray = JSON.parse(data.SettingsJson);
          setComponentInfo(settingsArray);
          setWeatherData(data);
          setSizeCustomComponent(id, {
            width: (settingsArray.width * window.innerWidth) / 24,
            height: (settingsArray.height * window.innerHeight) / 24,
          });
          setPositionCustomComponent(id, {
            x: (settingsArray.xPosition * window.innerWidth) / 24,
            y: (settingsArray.yPosition * window.innerHeight) / 24,
          });
        })
        .catch((error) => {
          //  console.error("Failed to fetch data:", error);
          notifyError("Failed to fetch data");
        });

      //console.log("Successfully patched settings to the backend");
    } catch (error) {
      //  console.error("Error patching settings to the backend:", error);
      notifyError("Error patching settings to the backend:", error);
    }
  };

  const initialSize = useMemo(
    () =>
      componentSize[id] || {
        height: (componentInfo?.height * window.innerHeight) / 24,
        width: (componentInfo?.width * window.innerWidth) / 24,
      },
    [id, componentSize, componentInfo]
  );

  const initialFontSize = {
    weatherTextSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.weatherTextSize
          : (componentInfo.weatherTextSize * initialSize.height) /
            window.innerHeight,
      [componentInfo.weatherTextSize, initialSize.height, selectedComponentId]
    ),
    cityTextSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.cityTextSize
          : (componentInfo.cityTextSize * initialSize.height) /
            window.innerHeight,
      [componentInfo.cityTextSize, initialSize.height, selectedComponentId]
    ),
    dataTextSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.dataTextSize
          : (componentInfo.dataTextSize * initialSize.height) /
            window.innerHeight,
      [componentInfo.dataTextSize, initialSize.height, selectedComponentId]
    ),
    dataSize: useMemo(
      () =>
        selectedComponentId
          ? componentInfo.dataSize
          : (componentInfo.dataSize * initialSize.height) / window.innerHeight,
      [componentInfo.dataSize, initialSize.height, selectedComponentId]
    ),
  };

  const initialPosition = useMemo(
    () =>
      positions[id] || {
        x: (componentInfo.xPosition * window.innerWidth) / 24,
        y: (componentInfo.yPosition * window.innerHeight) / 24,
      },
    [id, positions, componentInfo]
  );
  const tempPosition = useRef(initialPosition);

  const handleDrag = (e, ui) => {
    const { x, y } = tempPosition.current;
    tempPosition.current = { x: x + ui.deltaX, y: y + ui.deltaY };
    setIsDragging(true);
  };

  useEffect(() => {
    tempPosition.current = initialPosition;
  }, [initialPosition]);

  const handleResize = (event, { size }) => {
    if (!selectedComponentId) {
      const newWidth = Math.round((size.width / window.innerWidth) * 24);
      const newHeight = Math.round((size.height / window.innerHeight) * 24);
      patchSettingsToBackend2(
        {
          width: newWidth,
          height: newHeight,
        },
        weatherData.SettingsJson,
        id
      );
    }
  };

  const handleDragStop = () => {
    if (isDragging) {
      const newX = Math.round(
        (tempPosition.current.x / window.innerWidth) * 24
      );
      const newY = Math.round(
        (tempPosition.current.y / window.innerHeight) * 24
      );
      patchSettingsToBackend2(
        {
          xPosition: newX,
          yPosition: newY,
        },
        weatherData.SettingsJson,
        id
      );
      setPositionCustomComponent(id, tempPosition.current);
      setIsDragging(false);
    }
  };

  const widthStep = window.innerWidth / 24;
  const heightStep = window.innerHeight / 24;

  useEffect(() => {
    const handleWindowResize = () => {
      fetchData(`${ApiUrls.component}/${id}`, sessionToken).then((data) => {
        const settingsArray = JSON.parse(data.SettingsJson);
        setSizeCustomComponent(id, {
          width: (settingsArray.width * window.innerWidth) / 24,
          height: (settingsArray.height * window.innerHeight) / 24,
        });
        setPositionCustomComponent(id, {
          x: (settingsArray.xPosition * window.innerWidth) / 24,
          y: (settingsArray.yPosition * window.innerHeight) / 24,
        });
      });
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [
    componentInfo,
    id,
    sessionToken,
    setSizeCustomComponent,
    setPositionCustomComponent,
  ]);

  const result = isColorLight(componentInfo?.color);


  useEffect(() => {
    if (selectedComponentId) {
      const updateComponentData = () => {
        let API = `${ApiUrls.getComponentByGuid}(id=${selectedComponentId},Guid=${guid})`;
  
        fetchData(API, sessionToken).then((data) => {
          const settingsArray = JSON.parse(data.settingsJson || data.SettingsJson);
          setComponentInfo(settingsArray);
          setCity(settingsArray.weatherCity);
          setWeatherData(data);
          fetchWeather(settingsArray.weatherCity);
        });
      };
      updateComponentData();
      const interval = setInterval(() => {
        updateComponentData();
      }, 3600000); 
  
      return () => clearInterval(interval); 
    }
  }, [selectedComponentId, sessionToken, fetchWeather, guid]);
  


  const styles = {
    hamburger: {
      position: "absolute",
      top: 10,
      right: 10,
      zIndex: 1,
      color: result ? "black" : "white",
    },
  };

  return (
    <>
      {initialSize.width && initialSize.height ? (
        <Draggable
          onDrag={handleDrag}
          onStop={handleDragStop}
          position={!selectedComponentId ? initialPosition : { x: 0, y: 0 }}
          cancel=".react-resizable-handle"
          nodeRef={nodeRef}
          grid={[widthStep, heightStep]}
          bounds="parent"
          disabled={selectedComponentId != null}
        >
          <Resizable
            width={initialSize.width}
            height={initialSize.height}
            onResize={(event, { size }) =>
              !selectedComponentId && setSizeCustomComponent(id, size)
            }
            onResizeStop={handleResize}
            minConstraints={[widthStep, heightStep]}
            maxConstraints={[widthStep * 24, heightStep * 24]}
            draggableOpts={{ grid: [widthStep, heightStep] }}
            handle={selectedComponentId && <></>}
          >
            <div
              className={`${
                selectedComponentId
                  ? ""
                  : zIndex
                  ? "hoverEffectzIndex"
                  : "hoverEffect"
              }  opacity-${componentInfo.opacity} `}
              onMouseEnter={() => {
                setIsZindex(true);
              }}
              onMouseLeave={() => {
                setIsZindex(false);
              }}
              ref={nodeRef}
              style={{
                position: "fixed",
                height: selectedComponentId ? "100%" : initialSize.height,
                width: selectedComponentId ? "100%" : initialSize.width,
                borderRadius: isRounded ? "10px" : "",
                backgroundColor: componentInfo.color,
                zIndex: isZindex ? 100 : zIndex ? zIndex : componentInfo.zIndex,
              }}
            >
              {!selectedComponentId && (
                <div
                  style={styles.hamburger}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={`#weatherModal${id}`}
                >
                  <Cross size={25} toggled={false} />
                </div>
              )}

              {weatherList && (
                <>
                  <div
                    className="weather-temp mt-4"
                    style={{
                      color: componentInfo.weatherTextColor,
                      fontSize: `${initialFontSize.weatherTextSize}vh`,
                    }}
                  >
                    {Math.floor(weatherList.main.temp)}°C
                  </div>
                  <div
                    className="weather-location"
                    style={{
                      color: componentInfo.weatherTextColor,
                      fontSize: `${initialFontSize.cityTextSize}vh`,
                    }}
                  >
                    {weatherList.name === "Inner City"
                      ? "Copenhagen"
                      : weatherList.name}
                  </div>
                  <div className="data-container">
                    <div
                      className="element"
                      style={{ color: componentInfo.weatherTextColor }}
                    >
                      <div className="icon">
                        <i
                          className="bi bi-water"
                          style={{ fontSize: `${initialFontSize.dataSize}vh` }}
                        ></i>
                      </div>

                      <div className="data">
                        <div
                          className="humidity-percent"
                          style={{ fontSize: `${initialFontSize.dataSize}vh` }}
                        >
                          {weatherList.main.humidity}%
                        </div>
                        <div
                          className="text"
                          style={{
                            fontSize: `${initialFontSize.dataTextSize}vh`,
                          }}
                        >
                          {" "}
                          Humidity
                        </div>
                      </div>
                    </div>
                    <div
                      className="element"
                      style={{ color: componentInfo.weatherTextColor }}
                    >
                      <div className="icon">
                        <i
                          className="bi bi-wind"
                          style={{ fontSize: `${initialFontSize.dataSize}vh` }}
                        ></i>
                      </div>
                      <div className="data">
                        <div
                          className="wind-rate"
                          style={{ fontSize: `${initialFontSize.dataSize}vh` }}
                        >
                          {" "}
                          {Math.floor(weatherList.wind.speed)} km/h
                        </div>
                        <div
                          className="text"
                          style={{
                            fontSize: `${initialFontSize.dataTextSize}vh`,
                          }}
                        >
                          {" "}
                          Wind Speed
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {error && <div className="error-message">{error}</div>}
            </div>
          </Resizable>
        </Draggable>
      ) : (
        <div></div>
      )}
      {!selectedComponentId && (
        <WeatherModal
          id={id}
          patchSettingsToBackend2={patchSettingsToBackend2}
          weatherData={weatherData}
          componentInfo={componentInfo}
          positions={positions}
          deleteData={deleteData}
          city={city}
          setCity={setCity}
          fetchWeather={fetchWeather}
          componentName={componentName}
        />
      )}
    </>
  );
};

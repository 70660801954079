import "./App.css";
import { RouteComponent } from "./Components/Navigation/RouteComponent";
import { SettingsProvider } from "./Context/InfoboardSettingsContext";
import { LanguageProvider } from "./Context/LanguageContext";

function App() {

  return (
    <LanguageProvider>
      <SettingsProvider>
        <RouteComponent />
      </SettingsProvider>
    </LanguageProvider>
  );
}

export default App;

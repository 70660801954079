import { useState, useEffect, useRef } from "react";
import { fetchData, notifyError } from "../../Utils/ReusableFunctions";
import { ApiUrls } from "../../Utils/API";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";

export const CustomTemplateModalTwo = ({
  setInfoBoardList,
  selectedTemplate,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const { setSelectedComponents } = useSettingsInfoBoard();
  const [addInfoboard, setAddInfoboard] = useState({
    name: "",
    settingsJson: "{}",
  });

  useEffect(() => {
    if (selectedTemplate) {
      setAddInfoboard({
        name: "",
        settingsJson: `{"backgroundColor":"${selectedTemplate.backgroundColor}","isRoundedComponents":${selectedTemplate.isRoundedComponents}}`,
      });
    }
  }, [selectedTemplate]);

  const handleNameChange = (event) => {
    setAddInfoboard({ ...addInfoboard, name: event.target.value });
  };

  const handleSaveChanges = () => {
    if (addInfoboard.name.trim().length < 3) {
      notifyError("InfoBoard name must be at least 3 characters long");
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(addInfoboard),
    };

    fetch(ApiUrls.infobard, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to add InfoBoard");
        }
        return response.json();
      })
      .then((data) => {
        const infoBoardId = data.ID;

        saveComponents(infoBoardId);
        return fetchData(ApiUrls.infobard, sessionToken);
      })
      .then((data) => {
        setInfoBoardList(data.value);
        setAddInfoboard({ ...addInfoboard, name: "" });
      })
      .catch((error) => {
        console.log(error);
        notifyError("Error adding InfoBoard:", error.message);
      });
  };

  const saveComponents = (infoBoardId) => {
    const requestOptions = (component) => ({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({
        name: component.name,
        boardID: infoBoardId,
        settingsJson: JSON.stringify(component.settingsJson),
      }),
    });

    const componentPromises = selectedTemplate?.settingsJson.map(
      (component) => {
        return fetch(ApiUrls.component, requestOptions(component));
      }
    );

    Promise.all(componentPromises)
      .then((responses) => {
        responses.forEach((response) => {
          if (!response.ok) {
            throw new Error("Failed to add one or more components");
          }
        });
        return fetchData(ApiUrls.component, sessionToken);
      })
      .then((data) => {
        const filteredComponents = data.value.filter(
          (component) => String(component.BoardID) === String(infoBoardId)
        );
        setSelectedComponents(
          filteredComponents.map((component) => ({
            id: component.ID,
            type: JSON.parse(component.SettingsJson).type,
            settings: component.SettingsJson,
          }))
        );
      })
      .catch((error) => {
        notifyError("Error adding components:", error.message);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector("#CustomTemplate2 #newCustom");
      closeButton.click();
    }
  };
  const inputRef = useRef(null);
  
  useEffect(() => {
    const modalElement = document.getElementById("CustomTemplate2");
    const handleModalShown = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };
    modalElement.addEventListener("shown.bs.modal", handleModalShown);
    return () => {
      modalElement.removeEventListener("shown.bs.modal", handleModalShown);
    };
  }, []);

  return (
    <div
      className="modal fade"
      id="CustomTemplate2"
      tabIndex="-1"
      aria-labelledby="CustomTemplate2"
      aria-hidden="true"
      data-bs-backdrop="static"
      onKeyDown={handleKeyDown}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content rounded-0">
          <div className="modal-header">
            <h4 className="modal-title fs-5" id="CustomTemplate2">
             {selectedTemplate?.templateName}
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <input
             ref={inputRef}
              name="infoboardname"
              type="text"
              className="form-control"
              placeholder="InfoBoard Name"
              value={addInfoboard.name}
              onChange={handleNameChange}
            />
            <div className="mt-3 align-content-start">
              <h5 className="text-start">Components in Infoboard</h5>
              <div className="d-flex flex-wrap">
                {selectedTemplate?.settingsJson.map((setting, idx) => (
                  <div className="m-1" key={idx}>
                    <div
                      className="btn btn-info p-4"
                      style={{ pointerEvents: "none" }}
                    >
                      {setting.name}
                    </div>
                  </div>
                ))}
              </div>
              
              <div>
                No component assigned to this infoboard
              </div>

              <h5 className="text-start mt-2">Preview of Infoboard</h5>
              <img
                src={selectedTemplate?.imageBlobUrl}
                alt="customTemplateImage"
                height={375}
              />
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger rounded-0"
              data-bs-dismiss="modal"
            >
              CLOSE
            </button>
            <button
              type="button"
              id="newCustom"
              className="btn btn-primary rounded-0"
              data-bs-dismiss="modal"
              onClick={handleSaveChanges}
              disabled={addInfoboard.name.length < 3}
            >
              Add custom template
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

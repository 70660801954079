import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import AuthDataService from "./AuthFunctions";
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { notifyError } from "../../Utils/ReusableFunctions";
import logo from "../../Assets/logotrans.png";
import "./AdminLogin.css";

export const AdminLogin = () => {
  const navigate = useNavigate();
  const initState = {
    userID: "",
    password: "",
  };

  const [state, setState] = useState(initState);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const isFormValid = state.userID && state.password;
  async function SignIn() {
    setLoading(true);
    try {
      const sessionToken = await AuthDataService.signIn(
        state.userID,
        state.password
      );
      if (sessionToken) {
        navigate("/dashboards");
      } else {
        notifyError("Error logging in");
      }
    } catch (error) {
      notifyError("Error logging in");
    } finally {
      setLoading(false);
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && isFormValid) {
      SignIn();
    }
  };


  return (
    <div className="loginContainer">
      <MDBContainer fluid>
        <MDBRow className="d-flex justify-content-center align-items-center ">
          <MDBCol col="12">
            <MDBCard
              className="text-white my-5 mx-auto"
              style={{
                borderRadius: "1rem",
                maxWidth: "26rem",
                backgroundColor: "rgba(0, 0, 0, 0.7)",
              }}
            >
              <img
                src={logo}
                alt="LoginImage"
                style={{ marginBottom: "-30px" }}
              />
              <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
                <div className="w-100 align-items-center">
                  <h3
                    className="fw-bold mb-2 text-uppercase"
                    style={{ flex: 1, textAlign: "left" }}
                  >
                    Login
                  </h3>
                </div>
                <hr></hr>
                <MDBInput
                  name="userID"
                  id="userID"
                  wrapperClass="mb-3 mx-5 w-100"
                  className="bg-transparent text-white"
                  labelClass="text-white"
                  value={state.userID}
                  onChange={handleChange}
                  label="Email address"
                  type="email"
                  size="md"
                />

                <MDBInput
                  id="password"
                  name="password"
                  wrapperClass="mb-4 mx-5 w-100"
                  className="bg-transparent text-white"
                  labelClass="text-white"
                  value={state.password}
                  onChange={handleChange}
                  label="Password"
                  type="password"
                  size="md"
                  onKeyDown={handleKeyDown}
                />
                <MDBBtn
                  outline
                  className="mx-2 mt-3 mb-5 px-5 w-100 border-white text-white d-flex justify-content-center align-items-center"
                  color="white"
                  size="md"
                  style={{ height: "2.5rem" }}
                  type="submit"
                  onClick={SignIn}
                  disabled={loading || !isFormValid}
                  onKeyDown={handleKeyDown}
                >
                  {loading ? (
                    <Oval
                      secondaryColor="white"
                      visible={true}
                      height="24"
                      color="white"
                      ariaLabel="oval-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Sign In"
                  )}
                </MDBBtn>
                {/*                 <p className="small mb-3 pb-lg-2 mt-5">
                  <a className="text-white-50" href="#!">
                    Forgot password?
                  </a>
                </p>
                */}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

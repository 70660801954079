import { SketchPicker } from "react-color";
import { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";

export const InfoBarModal = ({
  id,
  deleteData,
  componentInfo,
  patchSettingsToBackend2,
  infoBarData,
  componentName
}) => {
  const [newMessage, setNewMessage] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [settings, setSettings] = useState({});
  const [isDraggable, setIsDraggable] = useState(true);
  const [timeouts, setTimeouts] = useState({
    textSize: null,
    height: null,
    width: null,
    xPosition: null,
    yPosition: null,
    messageDelay: null,
    opacity: null,
    zIndex: null,
  });
  const nodeRef = useRef(null);
  useEffect(() => {
    if (componentInfo) {
      setSettings({
        backgroundColor: componentInfo.color,
        textColor: componentInfo.textColor,
        opacity: componentInfo.opacity,
        width: componentInfo.width,
        height: componentInfo.height,
        textSize: componentInfo.textSize,
        xPosition: componentInfo.xPosition,
        yPosition: componentInfo.yPosition,
        messageDelay: componentInfo.messageDelay,
        zIndex: componentInfo.zIndex,
        boldText: componentInfo.boldText,
        infoList: componentInfo.infoList,
      });
    }
  }, [componentInfo]);

  const updateTimeout = (key, value) => {
    setTimeouts((prevTimeouts) => ({
      ...prevTimeouts,
      [key]: value,
    }));
  };

  useEffect(() => {
    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [timeouts]);

  const handleOnBackgroundChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, backgroundColor: newColor });
    patchSettingsToBackend2(
      {
        color: newColor,
      },
      infoBarData.SettingsJson,
      id
    );
  };

  const handleOnTextColorChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, textColor: newColor });
    patchSettingsToBackend2(
      {
        textColor: newColor,
      },
      infoBarData.SettingsJson,
      id
    );
  };

  const addMessage = (message) => {
    const updatedList = [
      ...(componentInfo.infoList || []),
      {
        message: message,
        date: new Date().toISOString(),
      },
    ];
    setSettings({...settings, infoList: updatedList})
    patchSettingsToBackend2(
      {
        infoList: updatedList,
      },
      infoBarData.SettingsJson,
      id
    );
  };

  const removeFile = (index) => {
    const updatedList = [...componentInfo.infoList];
    updatedList.splice(index, 1);
    patchSettingsToBackend2(
      {
        infoList: updatedList,
      },
      infoBarData.SettingsJson,
      id
    );
  };

  const handleSettingChange = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    clearTimeout(timeouts[key]);
    updateTimeout(
      key,
      setTimeout(() => {
        patchSettingsToBackend2({ [key]: value }, infoBarData.SettingsJson, id);
      }, 500)
    );
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      const closeButton = document.querySelector(
        `#infobarModal${id} #basic-addon2`
      );
      closeButton.click();
    }
  };

  const handleInputChange = (key, value, maxLimit) => {
    let parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      parsedValue = 2;
    } else if (parsedValue > maxLimit) {
      parsedValue = maxLimit;
    }
    handleSettingChange(key, parsedValue);
  };

  const handleTextChange = () => {
    const newTextBold = !settings.boldText;
    setSettings({ ...settings, boldText: newTextBold });
    patchSettingsToBackend2(
      {
        boldText: newTextBold,
      },
      infoBarData.SettingsJson,
      id
    );
  };

  return (
    <Draggable nodeRef={nodeRef} disabled={!isDraggable}>
      <div
        className="modal "
        id={`infobarModal${id}`}
        tabIndex="-1"
        aria-labelledby={`infobarModal${id}`}
        aria-hidden="true"
        data-bs-backdrop="static"
        onKeyDown={handleKeyDown}
        ref={nodeRef}
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h4 className="modal-title fs-5" id={`infobarModal${id}`}>
                Properties for {componentName}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div
              className="modal-body"
              onMouseEnter={() => setIsDraggable(false)}
              onMouseLeave={() => setIsDraggable(true)}
            >
              <>
                <ol className="list-group list-group-flush text-start">
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className=" me-auto">Opacity</div>
                    <span className="me-2">{settings.opacity}%</span>
                    <span className="">
                      <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="100"
                        step="25"
                        id="customRange3"
                        value={settings.opacity || 0}
                        onChange={(e) =>
                          handleSettingChange(
                            "opacity",
                            parseInt(e.target.value)
                          )
                        }
                      />
                    </span>
                  </li>

                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="row w-100">
                      <div className="col input-group">
                        <span
                          className="input-group-text border-end-0"
                          id="width"
                        >
                          width
                        </span>
                        <input
                          name="width"
                          type="number"
                          min={2}
                          max={24 - settings.xPosition || 0}
                          className="form-control"
                          aria-label="width"
                          aria-describedby="width"
                          onChange={(e) =>
                            handleInputChange(
                              "width",
                              e.target.value,
                              24 - settings.xPosition
                            )
                          }
                          value={settings.width || ""}
                        />
                      </div>
                      <div className="col input-group">
                        <span className="input-group-text" id="height">
                          height
                        </span>
                        <input
                          name="height"
                          type="number"
                          min={2}
                          max={24 - settings.yPosition || 0}
                          className="form-control"
                          aria-label="Username"
                          aria-describedby="height"
                          onChange={(e) =>
                            handleInputChange(
                              "height",
                              e.target.value,
                              24 - settings.yPosition
                            )
                          }
                          value={settings.height || ""}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="row w-100">
                      <div className="col input-group">
                        <span className="input-group-text" id="X">
                          X
                        </span>
                        <input
                          name="X"
                          min={0}
                          max={24 - settings.width || 0}
                          type="number"
                          className="form-control"
                          aria-label="X"
                          aria-describedby="X"
                          onChange={(e) =>
                            handleInputChange(
                              "xPosition",
                              e.target.value,
                              24 - settings.width
                            )
                          }
                          value={settings.xPosition || 0}
                        />
                      </div>
                      <div className="col input-group">
                        <span className="input-group-text border-end-0" id="Y">
                          Y
                        </span>
                        <input
                          name="Y"
                          type="number"
                          min={0}
                          max={24 - settings.height || 0}
                          className="form-control"
                          aria-label="Y"
                          aria-describedby="Y"
                          onChange={(e) =>
                            handleInputChange(
                              "yPosition",
                              e.target.value,
                              24 - settings.height
                            )
                          }
                          value={settings.yPosition || 0}
                        />
                      </div>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div className=" pt-1 me-auto">zIndex</div>
                    <input
                      name="textsizebottom"
                      type="number"
                      className="form-control w-25 me-4"
                      value={settings.zIndex || 0}
                      onChange={(e) =>
                        handleSettingChange("zIndex", parseInt(e.target.value))
                      }
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div className=" pt-1 me-auto">Text size</div>
                    <input
                      name="textsizebottom"
                      type="number"
                      className="form-control w-25 me-4"
                      value={settings.textSize || ""}
                      onChange={(e) =>
                        handleSettingChange(
                          "textSize",
                          parseInt(e.target.value)
                        )
                      }
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div className=" pt-1 me-auto">Message Delay</div>
                    <input
                      name="messagedelaybottom"
                      type="number"
                      className="form-control w-25 me-4"
                      value={settings.messageDelay || ""}
                      onChange={(e) =>
                        handleSettingChange(
                          "messageDelay",
                          parseInt(e.target.value)
                        )
                      }
                    />
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center">
                    <div className="form-check form-switch">
                      <label
                        className="form-check-label"
                        htmlFor={`listTextBold ${id}`}
                      >
                        {settings.boldText
                          ? "Bold text"
                          : "Normal text"}
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={`listTextBold ${id}`}
                        checked={settings.boldText || false}
                        onChange={handleTextChange}
                      />
                    </div>
                  </li>
                </ol>
              </>
              <div className="accordion accordion-flush" id="accordionInfobar">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneInfobar"
                      aria-expanded="true"
                      aria-controls="collapseOneInfobar"
                    >
                      Background Color
                    </button>
                  </h2>
                  <div
                    id="collapseOneInfobar"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionInfobar"
                  >
                    <div className="accordion-body">
                      <SketchPicker
                        color={settings.backgroundColor}
                        onChangeComplete={handleOnBackgroundChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourInfobar"
                      aria-expanded="true"
                      aria-controls="collapseFourInfobar"
                    >
                      Text Color
                    </button>
                  </h2>
                  <div
                    id="collapseFourInfobar"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionInfobar"
                  >
                    <div className="accordion-body">
                      <SketchPicker
                        color={settings.textColor}
                        onChangeComplete={handleOnTextColorChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiveInfobar"
                      aria-expanded="true"
                      aria-controls="collapseFiveInfobar"
                    >
                      Information list
                    </button>
                  </h2>
                  <div
                    id="collapseFiveInfobar"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionInfobar"
                  >
                    <div className="accordion-body">
                      <div className="input-group mb-3">
                        <input
                          name="newMessage"
                          type="text"
                          className="form-control"
                          placeholder="Add message"
                          value={newMessage}
                          onChange={(e) => setNewMessage(e.target.value)}
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                        />
                        <button
                          className="input-group-text btn btn-success bg-success"
                          id="basic-addon2"
                          disabled={newMessage.length <= 3}
                          onClick={() => {
                            addMessage(newMessage);
                            setNewMessage("");
                          }}
                        >
                          ADD
                        </button>
                      </div>
                      <ol className="list-group list-group-flush text-start">
                        {componentInfo &&
                        componentInfo.infoList &&
                        componentInfo.infoList.length > 0 ? (
                          componentInfo.infoList.map((item, index) => (
                            <li
                              key={index}
                              className="list-group-item d-flex justify-content-between align-items-start"
                            >
                              <div className="ms-2 me-auto">{item.message}</div>
                              <span
                                className="badge text-bg-danger rounded-0 text-white"
                                onClick={() => removeFile(index)}
                                style={{ cursor: "pointer" }}
                              >
                                    <i className="bi bi-trash3" style={{fontSize:"14px"}}></i>
                              </span>
                            </li>
                          ))
                        ) : (
                          <div>No message available</div>
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {deleteConfirmation ? (
                <>
                  <div>Are you sure ?</div>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-0 "
                    onClick={deleteData}
                    data-bs-dismiss="modal"
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm rounded-0"
                    onClick={() => setDeleteConfirmation(false)}
                  >
                    NO
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm rounded-0 "
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    {" "}
                    REMOVE COMPONENT{" "}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm rounded-0"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import { MediaList } from "../Media/MediaList";
import {
  saveSortOrderToBackend,
  handleDeleteFile,
} from "../../Utils/ReusableFunctions";
import Draggable from "react-draggable";
import { useSettingsInfoBoard } from "../../Context/InfoboardSettingsContext";
import { ApiUrls } from "../../Utils/API";
import { fetchData } from "../../Utils/ReusableFunctions";
import { notifyError } from "../../Utils/ReusableFunctions";

export const PdfViewerModal = ({
  id,
  patchSettingsToBackend2,
  pdfData,
  componentInfo,
  pdfMediaList,
  setPdfMeidaList,
  setCurrentPdfIndex,
  currentPdfIndex,
  deleteData,
  mediaList,
  componentName,
}) => {
  const sessionToken = localStorage.getItem("bearerToken");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [settings, setSettings] = useState({});
  const [isDraggable, setIsDraggable] = useState(true);
  const nodeRef = useRef(null);
  const { setComponentMedia } = useSettingsInfoBoard();
  const [expiryDate, setExpiryDate] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedMediaIndex, setEditedEventIndex] = useState(null);
  const [timeouts, setTimeouts] = useState({
    height: null,
    width: null,
    xPosition: null,
    yPosition: null,
    opacity: null,
    pdfPageSpeed: null,
    pdfListText: null,
    zIndex: null,
  });

  useEffect(() => {
    if (componentInfo) {
      setSettings({
        backgroundColor: componentInfo.backgroundColor,
        textColor: componentInfo.textColor,
        opacity: componentInfo.opacity,
        width: componentInfo.width,
        height: componentInfo.height,
        xPosition: componentInfo.xPosition,
        yPosition: componentInfo.yPosition,
        pdfPageSpeed: componentInfo.pdfPageSpeed,
        hiddenList: componentInfo.showList,
        pdfListText: componentInfo.pdfListText,
        positionRight: componentInfo.positionRight,
        zIndex: componentInfo.zIndex,
      });
    }
  }, [componentInfo]);

  const findMediaName = (mediaId) => {
    const media = mediaList.find((media) => media.ID === mediaId);
    return media ? media.Name : "Unknown";
  };

  const updateTimeout = (key, value) => {
    setTimeouts((prevTimeouts) => ({
      ...prevTimeouts,
      [key]: value,
    }));
  };

  useEffect(() => {
    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [timeouts]);

  const handleOnBackgroundChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, backgroundColor: newColor });
    patchSettingsToBackend2(
      {
        backgroundColor: newColor,
      },
      pdfData.SettingsJson,
      id
    );
  };

  const handleOnTextColorChange = (color) => {
    const newColor = color.hex;
    setSettings({ ...settings, textColor: newColor });
    patchSettingsToBackend2(
      {
        textColor: newColor,
      },
      pdfData.SettingsJson,
      id
    );
  };

  const handleSettingChange = (key, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    clearTimeout(timeouts[key]);
    updateTimeout(
      key,
      setTimeout(() => {
        patchSettingsToBackend2({ [key]: value }, pdfData.SettingsJson, id);
      }, 500)
    );
  };

  const handleShowListChange = () => {
    const newShowList = !settings.hiddenList;
    setSettings({ ...settings, hiddenList: newShowList });
    patchSettingsToBackend2(
      {
        showList: newShowList,
      },
      pdfData.SettingsJson,
      id
    );
  };

  const handlePositionChange = () => {
    const newShowList = !settings.positionRight;
    setSettings({ ...settings, positionRight: newShowList });
    patchSettingsToBackend2(
      {
        positionRight: newShowList,
      },
      pdfData.SettingsJson,
      id
    );
  };

  const reorderFiles = (startIndex, endIndex) => {
    if (startIndex === null || endIndex === null) return;
    setPdfMeidaList((files) => {
      const file = [...files];
      const [removed] = file.splice(startIndex, 1);
      file.splice(endIndex, 0, removed);

      const updatedFiles = file.map((item, index) => ({
        ...item,
        SortOrder: index + 1,
      }));
      saveSortOrderToBackend(updatedFiles, setPdfMeidaList, id, sessionToken);
      return updatedFiles;
    });
  };

  const draggingPos = useRef(null);
  const dragOverPos = useRef(null);

  const handleDragStart = (position) => {
    draggingPos.current = position;
  };

  const handleDragEnter = (position) => {
    dragOverPos.current = position;
  };

  const handleOnDrop = () => {
    if (draggingPos.current !== null && dragOverPos.current !== null) {
      reorderFiles(draggingPos.current, dragOverPos.current);
    }
    draggingPos.current = null;
    dragOverPos.current = null;
  };

  const handleInputChange = (key, value, maxLimit) => {
    let parsedValue = parseInt(value, 10);
    if (isNaN(parsedValue)) {
      parsedValue = 2;
    } else if (parsedValue > maxLimit) {
      parsedValue = maxLimit;
    }
    handleSettingChange(key, parsedValue);
  };

  const handleSaveChanges = (mediaID) => {
    const formattedExpiryDate = new Date(expiryDate).toISOString();

    const patchUrl = `${ApiUrls.componentMedia}(${mediaID})`;
    const patchData = {
      Expiry: formattedExpiryDate,
    };

    fetch(patchUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify(patchData),
    })
      .then((response) => {
        if (response.ok) {
          setIsEditing(false);
          setEditedEventIndex(null);
          setExpiryDate("");
          fetchData(
            `${ApiUrls.component}(${id})?$expand=media($expand=media)`,
            sessionToken
          )
            .then((data) => {
              const mediaList = data.Media || data.media;
              const currentDate = new Date();
              const filteredMediaList = mediaList.filter((mediaItem) => {
                if (mediaItem.Expiry) {
                  const expiryDate = new Date(mediaItem.Expiry);
                  return expiryDate > currentDate;
                }

                return true;
              });
              setPdfMeidaList(filteredMediaList);
            })
            .catch((error) => {
              console.error("Failed to fetch data:", error);
              notifyError("Failed to fetch data:", error);
            });
        } else {
          notifyError("Error updating media list");
        }
      })
      .catch((error) => {
        notifyError("Error updating media list", error);
      });
  };

  const startEditing = (index) => {
    setIsEditing(true);
    setEditedEventIndex(index);
  };

  const handleDateChange = (e) => {
    setExpiryDate(e.target.value);
  };

  const [sortByExpiry, setSortByExpiry] = useState(false);

  const toggleSortByExpiry = () => {
    setSortByExpiry(!sortByExpiry);
  };

  return (
    <>
      {settings && (
        <Draggable nodeRef={nodeRef} disabled={!isDraggable}>
          <div
            className="modal"
            id={`pdfViewerModal${id}`}
            tabIndex="-1"
            aria-labelledby={`pdfViewerModal${id}`}
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            ref={nodeRef}
          >
            <div className="modal-dialog modal-xl">
              <div className="modal-content rounded-0">
                <div className="modal-header">
                  <div className="row w-100">
                    <div className="col-6 ">
                      <h4
                        className="modal-title fs-5 2 w-100 text-start"
                        id={`pdfViewerModal${id}`}
                      >
                        Properties for {componentName}
                      </h4>
                    </div>
                    <div className="col-6 ">
                      <h4
                        className="modal-title fs-5 text-start ms-4"
                        id={`pdfViewerModal${id}`}
                      >
                        PDF Files
                      </h4>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div
                  className="modal-body"
                  onMouseEnter={() => setIsDraggable(false)}
                  onMouseLeave={() => setIsDraggable(true)}
                >
                  <div className="row">
                    <div className="col">
                      <>
                        <ol className="list-group list-group-flush text-start">
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className=" me-auto">Opacity</div>
                            <span className="me-2">{settings.opacity}%</span>
                            <span className="">
                              <input
                                type="range"
                                className="form-range"
                                min="0"
                                max="100"
                                step="25"
                                id="customRange3"
                                value={settings.opacity || 0}
                                onChange={(e) =>
                                  handleSettingChange(
                                    "opacity",
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                            </span>
                          </li>

                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="row w-100">
                              <div className="col input-group">
                                <span
                                  className="input-group-text border-end-0"
                                  id="width"
                                >
                                  width
                                </span>
                                <input
                                  name="width"
                                  type="number"
                                  min={4}
                                  max={24 - settings.xPosition || 0}
                                  className="form-control"
                                  aria-label="width"
                                  aria-describedby="width"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "width",
                                      e.target.value,
                                      24 - settings.xPosition
                                    )
                                  }
                                  value={settings.width || ""}
                                />
                              </div>
                              <div className="col input-group">
                                <span className="input-group-text" id="height">
                                  height
                                </span>
                                <input
                                  name="height"
                                  type="number"
                                  min={4}
                                  max={24 - settings.yPosition || 0}
                                  className="form-control"
                                  aria-label="height"
                                  aria-describedby="height"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "height",
                                      e.target.value,
                                      24 - settings.yPosition
                                    )
                                  }
                                  value={settings.height || ""}
                                />
                              </div>
                            </div>
                          </li>

                          <li className="list-group-item  d-flex justify-content-between align-items-start">
                            <div className="row w-100">
                              <div className="col input-group">
                                <span className="input-group-text" id="X">
                                  X
                                </span>
                                <input
                                  name="X"
                                  type="number"
                                  min={0}
                                  max={24 - settings.width || 0}
                                  className="form-control"
                                  aria-label="X"
                                  aria-describedby="X"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "xPosition",
                                      e.target.value,
                                      24 - settings.width
                                    )
                                  }
                                  value={settings.xPosition || 0}
                                />
                              </div>
                              <div className="col input-group">
                                <span
                                  className="input-group-text border-end-0"
                                  id="Y"
                                >
                                  Y
                                </span>
                                <input
                                  name="Y"
                                  type="number"
                                  className="form-control"
                                  aria-label="Y"
                                  min={0}
                                  max={24 - settings.height || 0}
                                  aria-describedby="Y"
                                  onChange={(e) =>
                                    handleInputChange(
                                      "yPosition",
                                      e.target.value,
                                      24 - settings.height
                                    )
                                  }
                                  value={settings.yPosition || 0}
                                />
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <div className=" pt-1 me-auto">zIndex</div>
                            <input
                              name="textsizebottom"
                              type="number"
                              className="form-control w-25 me-4"
                              value={settings.zIndex || 0}
                              onChange={(e) =>
                                handleSettingChange(
                                  "zIndex",
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </li>
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            <div className=" pt-1 me-auto">Text size</div>
                            <input
                              name="textsizebottom"
                              type="number"
                              className="form-control w-25 me-4"
                              value={settings.pdfListText || ""}
                              onChange={(e) =>
                                handleSettingChange(
                                  "pdfListText",
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          </li>
                        </ol>
                      </>
                      <div
                        className="accordion accordion-flush"
                        id="accordionPdf"
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOnePdf"
                              aria-expanded="true"
                              aria-controls="collapseOnePdf"
                            >
                              Background Color
                            </button>
                          </h2>
                          <div
                            id="collapseOnePdf"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionPdf"
                          >
                            <div className="accordion-body">
                              <SketchPicker
                                color={settings.backgroundColor}
                                onChangeComplete={handleOnBackgroundChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFourPdf"
                              aria-expanded="true"
                              aria-controls="collapseFourPdf"
                            >
                              Text Color
                            </button>
                          </h2>
                          <div
                            id="collapseFourPdf"
                            className="accordion-collapse collapse"
                            data-bs-parent="#accordionPdf"
                          >
                            <div className="accordion-body">
                              <SketchPicker
                                color={settings.textColor}
                                onChangeComplete={handleOnTextColorChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThreePdf"
                              aria-expanded="false"
                              aria-controls="collapseThreePdf"
                            >
                              Pdf list
                            </button>
                          </h2>
                          <div
                            id="collapseThreePdf"
                            className="accordion-collapse collapse show"
                            data-bs-parent="#accordionPdf"
                          >
                            <div className="accordion-body">
                              <div className="d-flex justify-content-end me-4">
                                {pdfMediaList && pdfMediaList.length > 0 && (
                                  <button
                                    onClick={toggleSortByExpiry}
                                    className="btn btn-secondary btn-sm rounded-0"
                                  >
                                    {sortByExpiry
                                      ? "Sort by sort order"
                                      : "Sort by Expiry"}{" "}
                                    {sortByExpiry ? "▲" : "▼"}
                                  </button>
                                )}
                              </div>

                              <ol className="list-group list-group-flush text-start">
                                {pdfMediaList &&
                                  pdfMediaList
                                    .sort((a, b) => {
                                      if (sortByExpiry) {
                                        return (
                                          new Date(a.Expiry) -
                                          new Date(b.Expiry)
                                        );
                                      } else {
                                        return a.SortOrder - b.SortOrder;
                                      }
                                    })
                                    .map((item, index) => (
                                      <li
                                        key={item.MediaID || item.mediaID}
                                        className="list-group-item d-flex justify-content-between align-items-start"
                                        draggable
                                        onDragStart={() =>
                                          handleDragStart(index)
                                        }
                                        onDragEnter={() =>
                                          handleDragEnter(index)
                                        }
                                        onDragOver={(e) => e.preventDefault()}
                                        onDragEnd={handleOnDrop}
                                      >
                                        <div
                                          className="ms-2 me-auto w-100"
                                          onClick={() =>
                                            !sortByExpiry &&
                                            setCurrentPdfIndex(index)
                                          }
                                          style={{
                                            cursor: sortByExpiry
                                              ? "not-allowed"
                                              : "pointer",
                                          }}
                                        >
                                          {findMediaName(
                                            item.MediaID || item.mediaID
                                          )}{" "}
                                          {isEditing &&
                                          editedMediaIndex === index ? (
                                            <>
                                              <div>
                                                <input
                                                  type="date"
                                                  value={
                                                    expiryDate
                                                      ? expiryDate.split("T")[0]
                                                      : ""
                                                  }
                                                  onChange={handleDateChange}
                                                />

                                                <button
                                                  className="btn btn-success btn-sm ms-1 rounded-0"
                                                  style={{width:"32%"}}
                                                  onClick={() =>
                                                    handleSaveChanges(item.ID)
                                                  }
                                                >
                                                  Save changes
                                                </button>
                                                <button
                                                   style={{width:"32%"}}
                                                  className="btn btn-danger ms-1 btn-sm rounded-0"
                                                  onClick={() =>
                                                    setIsEditing(false)
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            item.Expiry && (
                                              <small>
                                                - expire{" "}
                                                {
                                                  new Date(item.Expiry)
                                                    .toISOString()
                                                    .split("T")[0]
                                                }
                                              </small>
                                            )
                                          )}
                                        </div>
                                        {!isEditing && (
                                          <>
                                            <span
                                              className="badge btn-sm btn text-bg-danger text-white rounded-0"
                                              onClick={() => {
                                                handleDeleteFile(
                                                  item.ID,
                                                  sessionToken,
                                                  id,
                                                  setPdfMeidaList,
                                                  setComponentMedia
                                                );
                                                if (
                                                  pdfMediaList.length ===
                                                  currentPdfIndex + 1
                                                ) {
                                                  setCurrentPdfIndex(0);
                                                }
                                              }}
                                              style={{
                                                cursor: "pointer",
                                              }}
                                            >
                                              <i
                                                className="bi bi-trash3"
                                                style={{ fontSize: "14px" }}
                                              ></i>
                                            </span>
                                            <span
                                              className="badge btn-sm btn btn-secondary text-black rounded-0 ms-1"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                startEditing(index, item.Expiry)
                                              }
                                            >
                                              <i
                                                className="bi bi-pen"
                                                style={{ fontSize: "14px" }}
                                              ></i>
                                            </span>
                                          </>
                                        )}
                                        <small></small>
                                      </li>
                                    ))}

                                {pdfMediaList && pdfMediaList.length === 0 && (
                                  <div className="text-center">
                                    NO FILE AVAILABLE
                                  </div>
                                )}
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div>
                          <ol className="list-group list-group-flush text-start">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <div className=" pt-1 me-auto">
                                PDF Page speed
                              </div>
                              <input
                                name="pdfPageSpeed"
                                type="number"
                                className="form-control w-25"
                                value={settings.pdfPageSpeed || 0}
                                onChange={(e) =>
                                  handleSettingChange(
                                    "pdfPageSpeed",
                                    parseInt(e.target.value)
                                  )
                                }
                              />
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                  htmlFor={`pdfHide ${id}`}
                                >
                                  {settings.hiddenList
                                    ? "Hide List"
                                    : "Show List"}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`pdfHide ${id}`}
                                  checked={settings.hiddenList || false}
                                  onChange={handleShowListChange}
                                  disabled={pdfMediaList?.length < 1}
                                />
                              </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <div className="form-check form-switch">
                                <label
                                  className="form-check-label"
                                  htmlFor={`pdfPosition ${id}`}
                                >
                                  {settings.positionRight
                                    ? "List on right side"
                                    : "List on left side"}
                                </label>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id={`pdfPosition ${id}`}
                                  checked={settings.positionRight || false}
                                  onChange={handlePositionChange}
                                  disabled={pdfMediaList?.length < 1}
                                />
                              </div>
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <MediaList
                        id={id}
                        setList={setPdfMeidaList}
                        list={pdfMediaList}
                        componentType={"application/pdf"}
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {deleteConfirmation ? (
                    <>
                      <div>Are you sure ?</div>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm rounded-0 "
                        onClick={deleteData}
                        data-bs-dismiss="modal"
                      >
                        YES
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm rounded-0"
                        onClick={() => setDeleteConfirmation(false)}
                      >
                        NO
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm rounded-0 "
                        onClick={() => setDeleteConfirmation(true)}
                      >
                        {" "}
                        REMOVE COMPONENT{" "}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm rounded-0"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
};
